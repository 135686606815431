import * as yup from "yup";
import { countyNames } from "../reference/counties";
import {
  FreeformDateGroupSpec,
  SearchFilterFields,
  SearchSortFields,
} from "../search/searchutils";
import {
  CONFIDENTIALITY,
  Labels,
  eventSchema,
  stringElementSchema,
} from "./core";

export const deathRecordSchema = yup.object({
  id: yup.string().required(),
  certificateId: yup.string().nullable(),
  birthDate: yup.string().nullable(),
  birthPlace: yup.string().nullable(),
  firstName: yup.string().nullable(),
  middleName: yup.string().nullable(),
  familyName: yup.string().nullable(),
  suffix: yup.string().nullable(),
  mothersMaidenName: yup.string().nullable(),
  confidentiality: yup
    .string()
    .oneOf([...CONFIDENTIALITY])
    .required(),
  deathDateStart: yup.date().required(),
  deathDateEnd: yup.date().nullable(),
  county: yup.string().required(),
  imageFrontSHA256: yup.string().nullable(),
  imageBackSHA256: yup.string().nullable(),
  additionalImageSHA256s: yup.array(yup.string()).nullable(),
  alternateNames: yup.array().nullable().of(stringElementSchema),
  legacyId: yup.number().nullable(),
});

export type DeathRecord = yup.InferType<typeof deathRecordSchema>;

export const deathRecordInputSchema = deathRecordSchema.shape({
  id: yup.string().nullable(),
  certificateId: yup
    .string()
    .matches(
      /^\d{4}?-(?:MN|\d{2}?)-\d*/,
      "Certificate Number format must be YYYY-MN/00-00000",
    )
    .nullable(),
});

export type DeathRecordInput = yup.InferType<typeof deathRecordInputSchema>;

export const deathRecordEventSchema = eventSchema.shape({
  state: deathRecordSchema,
});

export const deathRecordEventSchemaList = yup
  .array()
  .of(deathRecordEventSchema)
  .required();

export const deathRecordLabels: Labels<DeathRecord> = {
  id: "UUID",
  certificateId: "Certificate Number",
  birthDate: "Birth Date",
  birthPlace: "Birth Place",
  firstName: "First Name",
  middleName: "Middle Name",
  familyName: "Family Name",
  suffix: "Suffix",
  mothersMaidenName: "Mother's Maiden Name",
  deathDateStart: "Exact Death Date or Range Start",
  deathDateEnd: "Death Date Range End",
  county: "County",
  confidentiality: "Confidentiality",
  imageFrontSHA256: "Image Front SHA256",
  imageBackSHA256: "Image Back SHA256",
  additionalImageSHA256s: "Additional Image SHA256s",
  alternateNames: "Alternate Names",
  legacyId: "Legacy Death Record ID",
};

export const deathRecordSorts: SearchSortFields<DeathRecord> = {
  certificateId: { label: deathRecordLabels["certificateId"] },
  firstName: { label: deathRecordLabels["firstName"] },
  middleName: { label: deathRecordLabels["middleName"] },
  familyName: { label: deathRecordLabels["familyName"] },
  suffix: { label: deathRecordLabels["suffix"] },
  mothersMaidenName: { label: deathRecordLabels["mothersMaidenName"] },
  deathDateStart: { label: "Death Date Start" },
  deathDateEnd: { label: "Death Date End" },
  county: { label: deathRecordLabels["county"] },
};

export const deathRecordFilters: SearchFilterFields = {
  certificateId: {
    label: deathRecordLabels["certificateId"],
    type: "string",
  },
  firstName: {
    label: deathRecordLabels["firstName"],
    type: "string",
    defaultOperator: "^",
    allowNullOps: true,
  },
  middleName: {
    label: deathRecordLabels["middleName"],
    type: "string",
    defaultOperator: "^",
    allowNullOps: true,
  },
  familyName: {
    label: deathRecordLabels["familyName"],
    type: "string",
    defaultOperator: "^",
    allowNullOps: true,
  },
  suffix: {
    label: deathRecordLabels["suffix"],
    type: "string",
  },
  mothersMaidenName: {
    label: deathRecordLabels["mothersMaidenName"],
    type: "string",
    defaultOperator: "^",
    allowNullOps: true,
  },
  deathDateIsInexact: {
    label: "Death Date is Inexact",
    type: "boolean",
  },
  confidentiality: {
    label: deathRecordLabels["confidentiality"],
    type: "string",
    validTerms: [...CONFIDENTIALITY],
  },
  county: {
    label: deathRecordLabels["county"],
    type: "string",
    validTerms: countyNames,
  },
  alternateNames: {
    label: deathRecordLabels["alternateNames"],
    type: "string",
  },
  legacyId: {
    label: deathRecordLabels["legacyId"],
    type: "number",
  },
  "comments.content": {
    label: "Comment Content",
    type: "string",
  },
  hasComments: {
    label: "Has Comment(s)",
    type: "boolean",
  },
};

export const deathRecordFreeformDateSpecs: FreeformDateGroupSpec[] = [
  {
    groupName: "Death Date",
    startDayName: "deathStartDay",
    startMonthName: "deathStartMonth",
    startYearName: "deathStartYear",
    endDayName: "deathEndDay",
    endMonthName: "deathEndMonth",
    endYearName: "deathEndYear",
    startDateName: "deathDateStart",
    endDateName: "deathDateEnd",
  },
];
