export function getVitalRecordsDisplayName<
  T extends {
    firstName?: string | null | undefined;
    middleName?: string | null | undefined;
    familyName?: string | null | undefined;
  }
>(record: T) {
  return `${record.firstName || ""} ${record.middleName || ""} ${
    record.familyName || ""
  }`;
}
