import {
  Button,
  Checkbox,
  Collapsible,
  Divider,
  Layout,
  Spinner,
  Tooltip,
} from "@shopify/polaris";
import { ReactNode, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

import * as searchutils from "./search/searchutils";
import { PersistentSearchState } from "./search/SearchState";
import SearchInput from "./search/SearchInput";
import SortParamPopover from "./search/SortParamPopover";
import FilterParamPopover from "./search/FilterParamPopover";
import SearchParamChit, { FilterParamChit } from "./search/SearchParamChit";
import {
  SearchResult,
  Labels,
  CurrentUserData,
  ContextualPrefs,
  PermissionId,
} from "./schemas/core";
import SearchResultsNav from "./search/SearchResultsNav";
import FreeformDateRange from "./search/FreeformDateRange";
import { SearchHitRenderSuite, SearchResults } from "./search/SearchResults";
import { Heading, PText, Subheading } from "../shared/TextComponents";
import Card from "../shared/Card";
import { hasPermissions } from "./auth/authutils";
import Stack from "../shared/Stack";
import {
  AddSearchParamBtn,
  ContentSensitiveClearBtn,
} from "./search/components";

export interface BaseSearchPageComponentProps<T extends object> {
  currentUserData: CurrentUserData;
  persistentState: PersistentSearchState<T>;
  contextualPrefs: ContextualPrefs;
}

export interface SearchProps<T extends { id: string }> {
  currentUserData: CurrentUserData;
  persistentState: PersistentSearchState<T>;
  labels: Labels<T>;
  renderSearchHit: ((record: T) => ReactNode) | SearchHitRenderSuite<T>;
  renderSearchNavDetail?: (
    record: T,
    onEditBtnClick: () => void,
  ) => JSX.Element;
  renderSearchNavForm?: (
    record: T,
    onSubmitSuccess: () => void,
    onDeleteSuccess: () => void,
  ) => JSX.Element;
  sortFields: searchutils.SearchSortFields<T>;
  filterFields: searchutils.SearchFilterFields;
  searchQuery: (
    props: searchutils.SearchQueryProps,
  ) => Promise<SearchResult<T>>;
  countQuery: (accessToken: string) => Promise<number>;
  // TODO: Make this dynamically determined from the class?? Might not be possible
  recordName: string;
  contextualPrefs: ContextualPrefs;
  allowEditMode?: boolean;
  bulkUpdateSpec?: { allowBulkUpdatePerm: PermissionId; bulkUpdateURL: string };
}

export default function Search<T extends { id: string }>(
  props: SearchProps<T>,
) {
  let navigate = useNavigate();
  const queryClient = useQueryClient();

  const [page, setPage] = useState(1);
  const [navIdx, setNavIdx] = useState(0);
  const [displayNav, setDisplayNav] = useState(false);

  // Advanced search option states:
  const [sortPopoverActive, setSortPopoverActive] = useState(false);
  const [filterPopoverActive, setFilterPopoverActive] = useState(false);

  const searchState = props.persistentState;

  const {
    data: searchRecords,
    isLoading,
    isFetching,
  } = useQuery(
    [
      `search${props.recordName.replace(/\s/g, "")}s`,
      props.currentUserData.accessToken,
      searchState.query,
      page,
      searchState.sorts,
      searchState.filters,
      searchState.freeformDates,
    ],
    async () =>
      props.searchQuery({
        accessToken: props.currentUserData.accessToken,
        ...searchState.genSearchQuery(),
        pageNum: page,
        pageSize: 50,
        // sortParams: searchState.sorts,
        // filterParams: searchState.filters,
        // freeformDates: searchState.freeformDates,
      }),
    { keepPreviousData: true },
  );
  const { data: totalRecords } = useQuery(
    [
      `total${props.recordName.replace(/\s/g, "")}sCount`,
      props.currentUserData.accessToken,
    ],
    async () => props.countQuery(props.currentUserData.accessToken),
    { keepPreviousData: true },
  );

  const queryInvalidation = () => {
    queryClient.invalidateQueries([
      `search${props.recordName.replace(/\s/g, "")}s`,
      props.currentUserData.accessToken,
      searchState.query,
      page,
      searchState.sorts,
      searchState.filters,
    ]);
  };

  const counter = totalRecords ? (
    <Subheading>{`${totalRecords} Total ${props.recordName}s`}</Subheading>
  ) : (
    <Spinner size="small" />
  );

  const editModeCheckbox = props.allowEditMode && (
    <Checkbox
      label={"Edit Mode"}
      checked={searchState.editMode}
      onChange={searchState.toggleEditMode}
    />
  );

  const navModeCheckbox = props.renderSearchNavForm &&
    props.renderSearchNavDetail && (
      <Checkbox
        label="Navigation Mode"
        checked={searchState.navMode}
        onChange={searchState.toggleNavMode}
      />
    );

  const newSearch = () => {
    searchState.clearFilters();
    searchState.clearSorts();
    searchState.setQuery("");
    searchState.clearAllFreeformDates();
  };

  // FreeformDateRanges
  const freeFormDateRanges = searchState.freeformDateSpecs && (
    <Stack>
      {searchState.freeformDateSpecs.map((spec) => {
        return (
          <FreeformDateRange
            key={spec.groupName}
            group={spec.groupName}
            initialValues={
              spec.groupName in searchState.freeformDates
                ? searchState.freeformDates[spec.groupName]
                : searchutils.emptyFreeformDates
            }
            onSubmit={searchState.addFreeformDate}
            onClear={searchState.clearAllFreeformDates}
          />
        );
      })}
    </Stack>
  );

  // Sort
  const toggleSortPopover = () => {
    setSortPopoverActive((sortPopoverActive) => !sortPopoverActive);
  };

  const addSortBtn = <AddSearchParamBtn onClick={toggleSortPopover} />;

  const sortPopover = (
    <SortParamPopover
      activator={addSortBtn}
      active={sortPopoverActive}
      onClose={toggleSortPopover}
      sortFields={props.sortFields}
      onSubmit={searchState.addSort}
    />
  );

  const sortChits = Object.entries(searchState.sorts).map(
    ([key, sortOrder]) => (
      <SearchParamChit
        key={key}
        displayText={`${props.labels[key as keyof T]}`}
        onRemove={() => searchState.removeSort(key as keyof T)}
        onClick={() => searchState.toggleSortOrder(key as keyof T)}
        disclosure={sortOrder.direction === "asc" ? "up" : "down"}
      />
    ),
  );

  const clearSortsBtn = (
    <ContentSensitiveClearBtn
      content={searchState.sorts}
      onClick={searchState.clearSorts}
    />
  );

  // Filter
  const toggleFilterPopover = () => {
    setFilterPopoverActive((filterPopoverActive) => !filterPopoverActive);
  };

  const addFilterBtn = <AddSearchParamBtn onClick={toggleFilterPopover} />;

  const filterPopover = (
    <FilterParamPopover
      activator={addFilterBtn}
      active={filterPopoverActive}
      onClose={toggleFilterPopover}
      onSubmit={searchState.addFilter}
      filterFields={props.filterFields}
    />
  );

  const filterChits = searchState.filters.map((f, idx) => (
    <FilterParamChit
      key={f.uuid}
      onChange={(f) => searchState.updateFilter(f, idx)}
      filterFields={props.filterFields}
      filter={f}
      onRemove={() => searchState.removeFilter(idx)}
    />
  ));

  const clearFiltersBtn = (
    <ContentSensitiveClearBtn
      content={searchState.filters}
      onClick={searchState.clearFilters}
    />
  );

  // Search results display
  const prevPage = () => setPage((old) => Math.max(old - 1, 1));

  const nextPage = () =>
    setPage((old) => (searchRecords?.hasNext ? old + 1 : old));

  const searchHitOnClick = (hit: T) => {
    if (searchState.navMode && searchRecords) {
      setDisplayNav(true);
      setNavIdx(searchRecords.hits.indexOf(hit));
    } else {
      navigate(`${hit.id}${searchState.editMode ? "/edit?editMode=true" : ""}`);
    }
  };

  const quickSettings = (editModeCheckbox || navModeCheckbox) && (
    <Stack direction="row" align="center">
      <PText weight="bold">Quick Settings</PText>
      {editModeCheckbox}
      {navModeCheckbox}
    </Stack>
  );

  // Pull this out of props so everything typechecks in the next block.
  const bulkUpdateSpec = props.bulkUpdateSpec;

  const bulkUpdateSection = searchRecords &&
    bulkUpdateSpec &&
    hasPermissions(
      props.currentUserData,
      bulkUpdateSpec.allowBulkUpdatePerm,
    ) && (
      <Tooltip
        content={
          searchRecords.totalHitCount > 100
            ? "Narrow search to 100 records or less to bulk edit"
            : "Send this search to the bulk editor"
        }
      >
        <Button
          disabled={searchRecords.totalHitCount > 100}
          onClick={() => navigate(bulkUpdateSpec.bulkUpdateURL)}
        >
          Send to Bulk Edit
        </Button>
      </Tooltip>
    );

  const searchResults = (
    <Card>
      <Stack spacing={4}>
        <Stack direction="row" justify="space-between">
          <Heading>{`Search ${props.recordName}s`}</Heading>
          {counter}
        </Stack>
        <Divider />
        {quickSettings}
        <Stack>
          <SearchInput
            onSubmit={(query) => {
              searchState.setQuery(query);
            }}
            query={searchState.query}
            setQuery={searchState.setQuery}
            queryLoading={isFetching}
            newSearch={newSearch}
          />
          <Divider />
          {props.contextualPrefs.useAdvSearchToggle && (
            <Button
              ariaControls="advanced-options"
              ariaExpanded={searchState.advOpen}
              onClick={searchState.toggleAdvOpen}
            >
              Advanced
            </Button>
          )}
        </Stack>
        <Collapsible id="advanced-options" open={searchState.advOpen}>
          <Stack spacing={2}>
            {freeFormDateRanges}
            <Divider />
            <Stack direction="row">
              <PText weight="bold">Sorting</PText>
              {sortChits}
              {sortPopover}
              {clearSortsBtn}
            </Stack>
            <Divider />
            <Stack direction="row">
              <PText weight="bold">Filtering</PText>
              {filterChits}
              {filterPopover}
              {clearFiltersBtn}
            </Stack>
            <Divider />
          </Stack>
        </Collapsible>
        <SearchResults
          result={searchRecords}
          isLoading={isLoading}
          renderHit={props.renderSearchHit}
          hitOnClick={searchHitOnClick}
          onPrevious={prevPage}
          onNext={nextPage}
        />
        {bulkUpdateSection}
      </Stack>
    </Card>
  );

  const renderSearchNavForm = props.renderSearchNavForm;
  const renderSearchNavDetail = props.renderSearchNavDetail;

  const searchNav = searchRecords &&
    renderSearchNavForm &&
    renderSearchNavDetail && (
      <Card>
        <SearchResultsNav
          initialIdx={navIdx}
          searchResult={searchRecords}
          renderDetail={(record, onEditBtnClick) =>
            renderSearchNavDetail(record, onEditBtnClick)
          }
          renderForm={(record, onSubmitSuccess, onDeleteSuccess) =>
            renderSearchNavForm(record, onSubmitSuccess, onDeleteSuccess)
          }
          onPrevious={prevPage}
          onNext={nextPage}
          toggleNavMode={() => setDisplayNav(!displayNav)}
          editModeActive={searchState.editMode}
          invalidateQuery={queryInvalidation}
        />
      </Card>
    );

  return (
    <Layout.Section>{displayNav ? searchNav : searchResults}</Layout.Section>
  );
}
