import * as yup from "yup";
import { countyNames } from "../reference/counties";
import {
  FreeformDateGroupSpec,
  SearchFilterFields,
  SearchSortFields,
} from "../search/searchutils";
import {
  CONFIDENTIALITY,
  Labels,
  eventSchema,
  stringElementSchema,
} from "./core";

export const SERIESCODE = ["DC", "IN", "PR", "SV"] as const;

// Birth record schema for records returned from one-mnhs api:
export const birthRecordSchema = yup.object({
  id: yup.string().required(),
  seriesCode: yup
    .string()
    .nullable()
    .oneOf([...SERIESCODE, null])
    .label("Series Code"),
  certificateNumber: yup.string().nullable().label("Certificate Number"),
  firstName: yup.string().nullable().label("First Name"),
  middleName: yup.string().nullable().label("Middle Name"),
  familyName: yup
    .string()
    .required("Family Name is a required field.")
    .label("Family Name"),
  suffix: yup.string().nullable().label("Suffix"),
  mothersMaidenName: yup.string().nullable().label("Mothers Maiden Name"),
  birthDateStart: yup
    .date()
    .required()
    .label("Exact Birth Date or Range Start"),
  birthDateEnd: yup.date().label("Birth Date Range End").nullable(),
  county: yup.string().required(),
  confidentiality: yup
    .string()
    .oneOf([...CONFIDENTIALITY])
    .required()
    .label("Confidentiality"),
  imageFrontSHA256: yup.string().nullable(),
  imageBackSHA256: yup.string().nullable(),
  additionalImageSHA256s: yup.array(yup.string()).nullable(),
  alternateNames: yup.array().nullable().of(stringElementSchema),
  legacyId: yup.number().nullable(),
  certificateId: yup.string().nullable(),
});

export type BirthRecord = yup.InferType<typeof birthRecordSchema>;

export const birthRecordInputSchema = birthRecordSchema.shape({
  id: yup.string().nullable(),
  certificateId: yup.string().nullable(),
  //   .when(
  //     "birthDateStart",
  //     (birthDateStart) =>
  //       birthDateStart &&
  //       yup.date().min(birthDateStart, "End date must be after start date")
  //   ),
});

export type BirthRecordInput = yup.InferType<typeof birthRecordInputSchema>;

export const birthRecordEventSchema = eventSchema.shape({
  state: birthRecordSchema,
});

export const birthRecordEventSchemaList = yup
  .array()
  .of(birthRecordEventSchema)
  .required(); // Schema used for client-side adding/editing of birth records:

export const birthRecordLabels: Labels<BirthRecord> = {
  id: "UUID",
  seriesCode: "Series Code",
  certificateNumber: "Certificate Number",
  firstName: "First Name",
  middleName: "Middle Name",
  familyName: "Family Name",
  suffix: "Suffix",
  mothersMaidenName: "Mother's Maiden Name",
  birthDateStart: "Exact Birth Date or Range Start",
  birthDateEnd: "Birth Date Range End",
  county: "County",
  confidentiality: "Confidentiality",
  imageFrontSHA256: "Image Front SHA256",
  imageBackSHA256: "Image Back SHA256",
  additionalImageSHA256s: "Additional Image SHA256s",
  alternateNames: "Alternate Names",
  legacyId: "Legacy Birth Record ID",
  certificateId: "Certificate Number",
};

export const birthRecordSorts: SearchSortFields<BirthRecord> = {
  seriesCode: { label: birthRecordLabels["seriesCode"] },
  certificateId: { label: "Certificate No" },
  firstName: { label: birthRecordLabels["firstName"] },
  middleName: { label: birthRecordLabels["middleName"] },
  familyName: { label: birthRecordLabels["familyName"] },
  suffix: { label: birthRecordLabels["suffix"] },
  mothersMaidenName: { label: birthRecordLabels["mothersMaidenName"] },
  birthDateStart: { label: "Birth Date Start" },
  birthDateEnd: { label: "Birth Date End" },
  county: { label: birthRecordLabels["county"] },
};
export const birthRecordFilters: SearchFilterFields = {
  seriesCode: {
    label: birthRecordLabels["seriesCode"],
    type: "string",
    validTerms: [...SERIESCODE],
  },
  certificateId: { label: "Certificate No", type: "string" },
  firstName: {
    label: birthRecordLabels["firstName"],
    type: "string",
    defaultOperator: "^",
    allowNullOps: true,
  },
  middleName: {
    label: birthRecordLabels["middleName"],
    type: "string",
    defaultOperator: "^",
    allowNullOps: true,
  },
  familyName: {
    label: birthRecordLabels["familyName"],
    type: "string",
    defaultOperator: "^",
  },
  suffix: {
    label: birthRecordLabels["suffix"],
    type: "string",
  },
  mothersMaidenName: {
    label: birthRecordLabels["mothersMaidenName"],
    type: "string",
    defaultOperator: "^",
    allowNullOps: true,
  },
  birthDateIsInexact: {
    label: "Birth Date is Inexact",
    type: "boolean",
  },
  confidentiality: {
    label: birthRecordLabels["confidentiality"],
    type: "string",
    validTerms: [...CONFIDENTIALITY],
  },
  county: {
    label: birthRecordLabels["county"],
    type: "string",
    validTerms: countyNames,
  },
  alternateNames: {
    label: birthRecordLabels["alternateNames"],
    type: "string",
  },
  legacyId: {
    label: birthRecordLabels["legacyId"],
    type: "number",
  },
  "comments.content": {
    label: "Comment Content",
    type: "string",
  },
  hasComments: {
    label: "Has Comment(s)",
    type: "boolean",
  },
};

export const birthRecordFreeformDateSpecs: FreeformDateGroupSpec[] = [
  {
    groupName: "Birth Date",
    startDayName: "birthStartDay",
    startMonthName: "birthStartMonth",
    startYearName: "birthStartYear",
    endDayName: "birthEndDay",
    endMonthName: "birthEndMonth",
    endYearName: "birthEndYear",
    startDateName: "birthDateStart",
    endDateName: "birthDateEnd",
  },
];
