import { useMutation, useQuery, useQueryClient } from "react-query";

import { RQUERY_STALE_TIME } from "../../env";
import * as api from "../api/genericEndpoints";
import { SearchResult, searchResultSchema } from "../schemas/core";
import { nearbyPagesSchema } from "../schemas/censusPage";
import { CensusPageInput } from "../schemas/censusPage";
import { CensusPage } from "../schemas/censusPage";
import { censusPageSchema } from "../schemas/censusPage";
import { censusEventSchemaList } from "../schemas/census";
import { CensusRecordInput } from "../schemas/census";
import { CensusRecord } from "../schemas/census";
import { censusSchema } from "../schemas/census";
import { axiosInstance, baseAxiosConfig } from "../api/shared";

export function useCensusRecord(accessToken: string, uuid?: string) {
  return useQuery(
    ["census", accessToken, uuid],
    async () =>
      accessToken && uuid
        ? await api.getRecord<CensusRecord>(
            accessToken,
            uuid,
            "census",
            censusSchema,
          )
        : null,
    { staleTime: RQUERY_STALE_TIME },
  );
}

export function useUpdateCensusRecord() {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      accessToken,
      inputData,
    }: {
      accessToken: string;
      inputData: CensusRecordInput;
    }) => {
      return api.updateRecord(accessToken, inputData, "census");
    },
    {
      onSuccess: () => {
        return queryClient.invalidateQueries(["census"]);
      },
    },
  );
}

export function useDeleteCensusRecord() {
  return useMutation(
    ({ accessToken, uuid }: { accessToken: string; uuid: string }) => {
      return api.deleteRecord(accessToken, uuid, "census");
    },
  );
}

export function useCensusEvents(accessToken: string, uuid?: string) {
  return useQuery(
    ["census", "events", uuid],
    async () =>
      uuid
        ? await api.getRMAEvents<CensusRecord>(
            accessToken,
            uuid,
            censusEventSchemaList,
            "census",
          )
        : null,
    { staleTime: RQUERY_STALE_TIME },
  );
}

export function useCensusPage(accessToken: string, uuid?: string | null) {
  return useQuery(
    ["censusPage", accessToken, uuid],
    async () =>
      accessToken && uuid
        ? await api.getRecord<CensusPage>(
            accessToken,
            uuid,
            "census/pages",
            censusPageSchema,
          )
        : null,
    { staleTime: RQUERY_STALE_TIME },
  );
}

export function useCensusPageAssocRecords(accessToken: string, uuid?: string) {
  const getAssocRecords = async (): Promise<SearchResult<CensusRecord>> => {
    const resp = await axiosInstance.get(
      `/census/pages/${uuid}/assoc-records`,
      baseAxiosConfig(accessToken),
    );
    const records = await searchResultSchema.validate(resp.data);
    return records;
  };
  return useQuery(
    ["censusPage", "assocRecords", accessToken, uuid],
    async () => (accessToken && uuid ? await getAssocRecords() : null),
    { staleTime: RQUERY_STALE_TIME },
  );
}

export function useCensusPageNearbyPages(accessToken: string, uuid?: string) {
  const getNearbyPages = async () => {
    const resp = await axiosInstance.get(
      `/census/pages/${uuid}/nearby-pages`,
      baseAxiosConfig(accessToken),
    );
    const result = await nearbyPagesSchema.validate(resp.data);
    return result;
  };
  return useQuery(
    ["censusPage", "nearbyPages", accessToken, uuid],
    async () => (accessToken && uuid ? await getNearbyPages() : null),
    { staleTime: RQUERY_STALE_TIME },
  );
}

export function useUpdateCensusPage() {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      accessToken,
      inputData,
    }: {
      accessToken: string;
      inputData: CensusPageInput;
    }) => {
      return api.updateRecord(accessToken, inputData, "census/pages");
    },
    {
      onSuccess: () => {
        return queryClient.invalidateQueries(["censusPage"]);
      },
    },
  );
}

export function useDeleteCensusPage() {
  return useMutation(
    ({ accessToken, uuid }: { accessToken: string; uuid: string }) => {
      return api.deleteRecord(accessToken, uuid, "census/pages");
    },
  );
}
