import {
  Button,
  Divider,
  Select,
  Spinner,
  TextField,
  Tooltip,
} from "@shopify/polaris";
import { EditIcon, PlusIcon, XSmallIcon } from "@shopify/polaris-icons";
import { ReactNode, useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuid4 } from "uuid";
import Card from "../../shared/Card";
import Stack from "../../shared/Stack";
import { Heading, PText, Subheading } from "../../shared/TextComponents";
import BreadcrumbPage from "../BreadcrumbPage";
import {
  useCollectionsImgResConfig,
  useCollectionsImgResConfigHistory,
  useUpdateCollectionsImgResRuleset,
} from "../hooks/catalogueItemHooks";
import { catalogueItemFilters } from "../schemas/catalogueItem";
import { ImgResRule } from "../schemas/core";
import {
  FilterBooleanTermInput,
  FilterDatePartTermInput,
  FilterFieldSelect,
  FilterOperatorSelect,
  FilterSelectTermInput,
  FilterTermInput,
  processDynamicFilterTermInputs,
} from "../search/FilterComponents";
import {
  FilterConjunct,
  FilterConjuncts,
  FilterFieldType,
  FilterParamOperator,
  ParamFilterLike,
  SearchFilterConjunctParam,
  SearchFilterFields,
  SearchFilterParam,
  parseDateStrToParts,
  parseSearchParams,
  updateSearchFilterParamTree,
} from "../search/searchutils";
import { DefaultPageProps } from "../utils/shared";
import { IndentedDiv } from "../utils/IndentedDiv";

const filterFields: SearchFilterFields = {
  ...catalogueItemFilters,
  creatorParties: { type: "string", label: "Creator Parties" },
  creationStartDate: { type: "date", label: "Creation Date" },
  creationStartYear: { type: "number", label: "Creation Year" },
  contentStartYear: { type: "number", label: "Content Year" },
  "notes.value": { type: "string", label: "Notes" },
};

interface EditableItemStackProps {
  heading: ReactNode;
  addTooltip?: string;
  onAddClick?: () => void;
  deleteTooltip?: string;
  onDeleteClick?: () => void;
  noChildrenLabel?: string;
  children?: ReactNode;
}

function EditableItemStack({
  heading,
  addTooltip,
  onAddClick,
  deleteTooltip,
  onDeleteClick,
  noChildrenLabel,
  children,
}: EditableItemStackProps) {
  let headingComp =
    typeof heading === "string" ? <Subheading>{heading}</Subheading> : heading;

  return (
    <Stack spacing={1}>
      <Stack direction="row" justify="space-between" align="center">
        {headingComp}
        <Stack direction="row" spacing={1}>
          {onAddClick && (
            <Tooltip content={addTooltip || "Add Item"} hoverDelay={500}>
              <Button icon={PlusIcon} size="slim" onClick={onAddClick} />
            </Tooltip>
          )}
          {onDeleteClick && (
            <Tooltip content={deleteTooltip || "Delete"} hoverDelay={500}>
              <Button
                icon={XSmallIcon}
                size="slim"
                tone="critical"
                onClick={onDeleteClick}
              />
            </Tooltip>
          )}
        </Stack>
      </Stack>
      <Divider />
      {children || <PText>{noChildrenLabel || "No items to display"}</PText>}
    </Stack>
  );
}

interface BasicInputProps<T> {
  onSubmit?: (value: T) => void;
  onCancel?: () => void;
}

interface RuleInputProps extends BasicInputProps<SearchFilterParam> {
  filterFields: SearchFilterFields;
  rule?: SearchFilterParam;
}

function RuleInput({ filterFields, rule, onSubmit, onCancel }: RuleInputProps) {
  const [selectedField, setSelectedField] = useState<string>(rule?.field || "");
  const [operator, setOperator] = useState<FilterParamOperator>(
    rule?.op || "=",
  );
  const [term, setTerm] = useState<string | undefined>(rule?.term);
  const [startDay, setStartDay] = useState<number | undefined>(
    parseDateStrToParts(rule?.term)[2],
  );
  const [startMonth, setStartMonth] = useState<number | undefined>(
    parseDateStrToParts(rule?.term)[1],
  );
  const [startYear, setStartYear] = useState<number | undefined>(
    parseDateStrToParts(rule?.term)[0],
  );
  const [fieldType, setFieldType] = useState<FilterFieldType | null>(
    rule ? filterFields[rule.field].type : null,
  );

  const onFieldSelect = (selected: string) => {
    setSelectedField(selected);
    setFieldType(filterFields[selected].type);
    setTerm(undefined);
    setStartDay(undefined);
    setStartMonth(undefined);
    setStartYear(undefined);
    let defaultOp = filterFields[selected].defaultOperator;
    setOperator(defaultOp || "=");
  };

  const termInput = (
    <FilterTermInput
      term={term}
      setTerm={setTerm}
      labelHidden
      label="Term value"
      disabled={!selectedField || !operator}
    />
  );

  const dateInput = (
    <FilterDatePartTermInput
      stackDirection="row"
      setDay={setStartDay}
      setMonth={setStartMonth}
      setYear={setStartYear}
      day={startDay}
      month={startMonth}
      year={startYear}
      yearMinimum={-5000}
    />
  );

  const booleanInput = (
    <FilterBooleanTermInput
      term={term}
      setTerm={setTerm}
      labelHidden
      disabled={!selectedField || !operator}
    />
  );

  const selectInput = selectedField && (
    <FilterSelectTermInput
      placeholder="select: "
      labelHidden
      term={term}
      validTerms={filterFields[selectedField].validTerms}
      setTerm={setTerm}
      disabled={!selectedField || !operator}
    />
  );

  const submit = () => {
    let result = processDynamicFilterTermInputs(
      operator,
      selectedField,
      filterFields,
      term,
      startDay,
      startMonth,
      startYear,
    );
    if (result) {
      if (rule) {
        result.uuid = rule.uuid;
      }
      if (onSubmit) {
        onSubmit(result);
      }
    }
  };

  return (
    <Stack direction="row">
      <FilterFieldSelect
        selectedField={selectedField}
        filterFields={filterFields}
        onFieldSelect={onFieldSelect}
        label="Available fields"
        labelHidden
      />
      <FilterOperatorSelect
        selectedField={selectedField}
        filterFields={filterFields}
        operator={operator}
        setOperator={setOperator}
        labelHidden
        disabled={
          !selectedField ||
          (selectedField !== undefined &&
            filterFields[selectedField].validTerms !== undefined)
        }
      />
      {["number", "string"].includes(fieldType || "string") &&
        !filterFields[selectedField]?.validTerms &&
        termInput}
      {filterFields[selectedField]?.validTerms && selectInput}
      {fieldType === "date" && dateInput}
      {fieldType === "boolean" && booleanInput}
      <Button
        onClick={submit}
        disabled={
          (fieldType !== "date" && !term) ||
          (fieldType === "date" && !startYear)
        }
      >
        Submit
      </Button>
      {onCancel && <Button onClick={onCancel} icon={XSmallIcon} />}
    </Stack>
  );
}

interface ClauseInputProps
  extends BasicInputProps<SearchFilterParam | SearchFilterConjunctParam> {
  clause?: SearchFilterConjunctParam;
}

function ClauseInput({ clause, onSubmit, onCancel }: ClauseInputProps) {
  const [conj, setConj] = useState<FilterConjunct>(
    clause?.conjunction || "and",
  );

  const submit = () => {
    let result: SearchFilterConjunctParam = {
      uuid: uuid4(),
      conjunction: conj,
      filters: [],
    };
    if (clause) {
      result.uuid = clause.uuid;
      result.filters = clause.filters;
    }
    if (onSubmit) {
      onSubmit(result);
    }
  };

  return (
    <Stack direction="row">
      <Select
        labelInline
        label="conjunction: "
        options={FilterConjuncts.map((conj) => ({ value: conj, label: conj }))}
        value={conj}
        onChange={(selected) => setConj(selected as FilterConjunct)}
      />
      <Button onClick={submit}>Submit</Button>
      {onCancel && <Button onClick={onCancel} icon={XSmallIcon} />}
    </Stack>
  );
}

interface EditableItemProps {
  editing?: boolean;
  prefix?: string;
  level?: number;
  onDelete?: (uuid: string) => void;
}

interface RuleItemProps extends RuleInputProps, EditableItemProps {}

function RuleItem({
  filterFields,
  rule,
  onSubmit,
  onCancel,
  onDelete,
  prefix,
  editing,
  level,
}: RuleItemProps) {
  const [editModeOn, setEditModeOn] = useState(editing || !rule);

  const ruleInput = (
    <RuleInput
      filterFields={filterFields}
      rule={rule}
      onSubmit={(param) => {
        if (onSubmit) {
          onSubmit(param);
        }
        setEditModeOn(false);
      }}
      onCancel={() => {
        if (onCancel) {
          onCancel();
        }
        setEditModeOn(false);
      }}
    />
  );

  const deleteRule = () => {
    if (onDelete && rule) {
      onDelete(rule.uuid);
    }
  };

  const ruleDisplay = rule && (
    <Stack direction="row" align="center">
      <PText>
        {prefix}
        {prefix && " "}
        {rule.label}
      </PText>
      {onSubmit && (
        <>
          <Tooltip content="Edit Rule" hoverDelay={500}>
            <Button
              icon={EditIcon}
              size="slim"
              onClick={() => setEditModeOn(true)}
            />
          </Tooltip>
          <Tooltip content="Delete Rule" hoverDelay={500}>
            <Button
              icon={XSmallIcon}
              size="slim"
              tone="critical"
              onClick={deleteRule}
            />
          </Tooltip>
        </>
      )}
    </Stack>
  );

  return (
    <IndentedDiv level={level}>
      {editModeOn ? ruleInput : ruleDisplay}
    </IndentedDiv>
  );
}

interface ClauseItemProps extends ClauseInputProps, EditableItemProps {
  clause: SearchFilterConjunctParam;
  filterFields: SearchFilterFields;
}

function ClauseItem({
  clause,
  filterFields,
  onSubmit,
  onCancel,
  onDelete,
  prefix,
  editing,
  level = 0,
}: ClauseItemProps) {
  const [editModeOn, setEditModeOn] = useState(editing || !clause);
  const [newMode, setNewMode] = useState(false);

  const clauseInput = (
    <ClauseInput
      clause={clause}
      onSubmit={(param) => {
        if (onSubmit) {
          onSubmit(param);
        }
        setEditModeOn(false);
      }}
      onCancel={() => {
        if (onCancel) {
          onCancel();
        }
        setEditModeOn(false);
      }}
    />
  );

  const labelMap = {
    and: "All of the following are true...",
    or: "At least one of the following is true...",
    not: "None of the following are true...",
  };

  const clauseDisplay = (
    <Stack direction="row" align="center">
      <PText>
        {prefix}
        {prefix && " "}
        {labelMap[clause.conjunction]}
      </PText>
      {onSubmit && (
        <Button
          icon={EditIcon}
          size="slim"
          onClick={() => setEditModeOn(true)}
        />
      )}
    </Stack>
  );

  const addComp = newMode && onSubmit && (
    <AddCriterion
      filterFields={filterFields}
      onAdd={(filterParam) => {
        clause.filters.push(filterParam);
        onSubmit(clause);
        setNewMode(false);
      }}
      onCancel={() => setNewMode(false)}
    />
  );

  return (
    <IndentedDiv level={level}>
      <EditableItemStack
        heading={editModeOn ? clauseInput : clauseDisplay}
        addTooltip="Add Rule/Subclause"
        onAddClick={onSubmit ? () => setNewMode(true) : undefined}
        deleteTooltip="Delete Conjunction"
        onDeleteClick={onDelete ? () => onDelete(clause.uuid) : undefined}
        noChildrenLabel="Click + above to add rules."
      >
        {clause.filters.map((param, idx) => (
          <CriteriaItem
            key={param.uuid}
            item={param}
            prefix={idx > 0 ? clause.conjunction.toUpperCase() : undefined}
            level={level + 1}
            filterFields={filterFields}
            onSubmit={onSubmit}
            onDelete={onDelete}
          />
        ))}
        {addComp}
        {!addComp && clause.filters.length === 0 && (
          <IndentedDiv level={level + 1}>
            Click + above to add rules/subclauses to this clause.
          </IndentedDiv>
        )}
      </EditableItemStack>
    </IndentedDiv>
  );
}

interface CriteriaItemProps
  extends BasicInputProps<SearchFilterParam | SearchFilterConjunctParam>,
    EditableItemProps {
  filterFields: SearchFilterFields;
  item: SearchFilterParam | SearchFilterConjunctParam;
}

function CriteriaItem({
  item,
  filterFields,
  onSubmit,
  onCancel,
  onDelete,
  editing,
  prefix,
  level,
}: CriteriaItemProps) {
  return "filters" in item ? (
    <ClauseItem
      clause={item}
      filterFields={filterFields}
      onSubmit={onSubmit}
      onCancel={onCancel}
      onDelete={onDelete}
      editing={editing}
      prefix={prefix}
      level={level}
    />
  ) : (
    <RuleItem
      rule={item}
      filterFields={filterFields}
      onSubmit={onSubmit}
      onCancel={onCancel}
      onDelete={onDelete}
      editing={editing}
      prefix={prefix}
      level={level}
    />
  );
}

interface AddCriterionProps {
  filterFields: SearchFilterFields;
  onAdd?: (filterParam: SearchFilterParam | SearchFilterConjunctParam) => void;
  onCancel?: () => void;
}

function AddCriterion({ filterFields, onAdd, onCancel }: AddCriterionProps) {
  const [addMode, setAddMode] = useState<"rule" | "clause">("rule");

  const ruleComp = (
    <RuleItem
      filterFields={filterFields}
      onCancel={onCancel}
      onSubmit={onAdd}
    />
  );

  const clauseInputComp = <ClauseInput onCancel={onCancel} onSubmit={onAdd} />;

  return (
    <Stack direction="row">
      <Select
        labelInline
        label="New "
        options={["rule", "clause"]}
        value={addMode}
        onChange={(selected) => setAddMode(selected as "rule" | "clause")}
      />
      {addMode === "rule" && ruleComp}
      {addMode === "clause" && clauseInputComp}
    </Stack>
  );
}

interface ResolutionRulesConfigBlockProps {
  rule: ImgResRule<ParamFilterLike>;
  filterFields: SearchFilterFields;
  saveRule?: (rule: ImgResRule<ParamFilterLike>) => void;
}

function ResolutionRulesConfigBlock(props: ResolutionRulesConfigBlockProps) {
  const [newMode, setNewMode] = useState(false);

  const { rule, filterFields, saveRule } = props;
  let subhead = rule.res ? `${rule.res} x ${rule.res}` : "Max";

  const addComp = newMode && (
    <AddCriterion
      filterFields={filterFields}
      onAdd={(filterParam) => {
        if (saveRule) {
          rule.criteria.push(filterParam);
          saveRule(rule);
        }
        setNewMode(false);
      }}
      onCancel={() => setNewMode(false)}
    />
  );

  return (
    <Card innerCard>
      <EditableItemStack
        heading={`Display images at ${subhead} resolution if:`}
        noChildrenLabel="No rules configured yet."
        addTooltip="Add Rule/Clause"
        onAddClick={saveRule ? () => setNewMode(true) : undefined}
        deleteTooltip="Delete Resolution Level"
      >
        {rule.criteria.map((r, idx) => (
          <CriteriaItem
            key={r.uuid}
            item={r}
            filterFields={filterFields}
            prefix={idx > 0 ? "OR" : undefined}
            onSubmit={
              saveRule
                ? (param) => {
                    let newCriteria = updateSearchFilterParamTree(
                      param,
                      rule.criteria,
                    );
                    rule.criteria = newCriteria;
                    saveRule(rule);
                  }
                : undefined
            }
            onDelete={
              saveRule
                ? (uuid) => {
                    let newRules = updateSearchFilterParamTree(
                      null,
                      rule.criteria,
                      uuid,
                    );
                    rule.criteria = newRules;
                    saveRule(rule);
                  }
                : undefined
            }
          />
        ))}
        {addComp}
      </EditableItemStack>
    </Card>
  );
}

interface CollectionsImageResRuleDisplayProps {
  rules: Record<string, ImgResRule<ParamFilterLike>>;
  filterFields: SearchFilterFields;
  saveRule?: (uuid: string, rule: ImgResRule<ParamFilterLike>) => void;
}

function CollectionsImageResRuleDisplay({
  rules,
  filterFields,
  saveRule,
}: CollectionsImageResRuleDisplayProps) {
  return (
    <Stack>
      {Object.entries(rules)
        .sort(
          (entryA, entryB) =>
            (entryB[1].res || Infinity) - (entryA[1].res || Infinity),
        )
        .map(([uuid, rule]) => (
          <ResolutionRulesConfigBlock
            key={uuid}
            rule={rule}
            filterFields={filterFields}
            saveRule={saveRule ? (rule) => saveRule(uuid, rule) : undefined}
          />
        ))}
    </Stack>
  );
}

interface CollectionsImageResRuleEditorProps {
  rules?: Record<string, ImgResRule<ParamFilterLike>>;
  filterFields: SearchFilterFields;
  onSave?: (rules: Record<string, ImgResRule<ParamFilterLike>>) => void;
}

function CollectionsImageResRuleEditor({
  rules,
  filterFields,
  onSave,
}: CollectionsImageResRuleEditorProps) {
  const [resRules, setResRules] = useState<
    Record<string, ImgResRule<ParamFilterLike>>
  >(rules || {});

  const [newResLevel, setNewResLevel] = useState<number | undefined>();
  const [newResError, setNewResError] = useState<string | undefined>();

  const addReslevel = (res?: number) => {
    if (
      Object.values(resRules)
        .map((rule) => rule.res)
        .includes(res)
    ) {
      let msgA = res ? ` ${res}` : "";
      let msgB = res ? "" : " max";
      let msg = `Can't add duplicate rules for${msgB} resolution${msgA}.`;
      setNewResError(msg);
    } else {
      let newId = uuid4();
      setResRules((prev) => ({ ...prev, [newId]: { res: res, criteria: [] } }));
      setNewResLevel(undefined);
    }
  };

  return (
    <Stack>
      {onSave && (
        <Stack direction="row" justify="space-between">
          <Stack direction="row">
            <TextField
              type="number"
              label="Resolution"
              labelHidden
              autoComplete="off"
              onChange={(value) => {
                setNewResLevel(Number(value));
                setNewResError(undefined);
              }}
              value={newResLevel?.toString()}
              error={newResError}
              placeholder="Enter resolution (empty)"
            />
            <Button onClick={() => addReslevel(newResLevel)}>
              Add Resolution Level
            </Button>
          </Stack>
          <Button
            variant="primary"
            tone="success"
            onClick={async () => await onSave(resRules)}
          >
            Commit Changes
          </Button>
        </Stack>
      )}
      <Divider />
      <CollectionsImageResRuleDisplay
        rules={resRules}
        filterFields={filterFields}
        saveRule={(uuid, rule) =>
          setResRules((prev) => ({ ...prev, [uuid]: rule }))
        }
      />
    </Stack>
  );
}

export default function CollectionsImageResConfigPage(props: DefaultPageProps) {
  const { data: rules } = useCollectionsImgResConfig(
    props.currentUserData.accessToken,
    filterFields,
  );

  let navigate = useNavigate();

  const mutation = useUpdateCollectionsImgResRuleset();

  const saveChanges = async (
    rules: Record<string, ImgResRule<ParamFilterLike>>,
  ) => {
    await mutation.mutate({
      accessToken: props.currentUserData.accessToken,
      newRuleset: {
        resRules: Object.values(rules).map((rule) => ({
          res: rule.res,
          criteria: parseSearchParams(rule.criteria),
        })),
      },
    });
  };

  const rulesDisplay = rules && (
    <CollectionsImageResRuleEditor
      rules={rules}
      filterFields={filterFields}
      onSave={saveChanges}
    />
  );

  const spinner = !rules && (
    <Stack direction="row" justify="center">
      <Spinner />
    </Stack>
  );

  return (
    <BreadcrumbPage>
      <Card>
        <Stack>
          <Heading>Collections Item Image Resolution Settings</Heading>
          <PText>
            Use this area to configure the rules used to determine what level of
            resolution a given collections item's associated image media may be
            displayed at.
          </PText>
          <PText>
            Rules are checked from highest to lowest resolution, so if an item
            matches the criteria for a high resolution, it will not be checked
            to see if it also matches lower resolution rules. So, you want to
            create rules that positively match the items you want to match, and
            if you want to exclude certain collections, you will need to specify
            that within the same rule using NOT clauses.
          </PText>
          <Stack direction="row">
            <Button onClick={() => navigate("./history")}>
              Go To Version History
            </Button>
          </Stack>
          {rulesDisplay || spinner}
        </Stack>
      </Card>
    </BreadcrumbPage>
  );
}

export function CollectionsImageResConfigHistoryPage(props: DefaultPageProps) {
  const [vIdx, setVIdx] = useState(0);

  const { data: versionHistory } = useCollectionsImgResConfigHistory(
    props.currentUserData.accessToken,
    filterFields,
  );

  const versionComps =
    versionHistory &&
    versionHistory.map((version) => (
      <CollectionsImageResRuleDisplay
        key={version.id}
        rules={version.resRules}
        filterFields={filterFields}
      />
    ));

  const versionDisplay = versionComps && versionHistory && (
    <Stack>
      <Select
        label="Select version"
        labelHidden
        options={versionHistory.map((ruleset, idx) => ({
          label: `Version ${ruleset.version}`,
          value: idx.toString(),
        }))}
        value={vIdx.toString()}
        onChange={(value) => setVIdx(Number(value))}
      />
    </Stack>
  );

  const spinner = !versionHistory && (
    <Stack direction="row" justify="center">
      <Spinner />
    </Stack>
  );

  return (
    <BreadcrumbPage breadcrumbAction="back" breadcrumbs="both">
      <Card>
        <Stack>
          {versionDisplay || spinner}
          {versionComps && versionComps[vIdx]}
        </Stack>
      </Card>
    </BreadcrumbPage>
  );
}
