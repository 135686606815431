import { useMutation, useQuery, useQueryClient } from "react-query";
import { RQUERY_STALE_TIME } from "../../env";
import { deleteRecord, updateRecord } from "../api/genericEndpoints";
import { axiosInstance, baseAxiosConfig } from "../api/shared";
import { Comment, CommentInput } from "../schemas/comment";
import {
  PagedResults,
  parseSearchParamsToFilters,
  Query,
  Sort,
} from "../schemas/core";
import {
  SearchFilterConjunctParam,
  SearchFilterParam,
} from "../search/searchutils";

const CMT_KEY = "comment";

export function useComment(accessToken: string, uuid?: string) {
  const getCmt = async (
    accessToken: string,
    uuid: string,
  ): Promise<Comment> => {
    const resp = await axiosInstance.get(
      `/comments/${uuid}`,
      baseAxiosConfig(accessToken),
    );
    return resp.data;
  };
  return useQuery(
    [CMT_KEY, uuid],
    async () => (accessToken && uuid ? await getCmt(accessToken, uuid) : null),
    { staleTime: RQUERY_STALE_TIME },
  );
}

export function useCommentsByRecord(accessToken: string, recordId?: string) {
  const getCmtsByRecord = async (
    accessToken: string,
    recordId: string,
  ): Promise<Comment[]> => {
    const resp = await axiosInstance.get(
      `/comments/by-record-id/${recordId}`,
      baseAxiosConfig(accessToken),
    );
    return resp.data;
  };
  return useQuery(
    [CMT_KEY, recordId],
    async () =>
      recordId ? await getCmtsByRecord(accessToken, recordId) : null,
    { staleTime: RQUERY_STALE_TIME },
  );
}

export function useQueryComments(
  accessToken: string,
  pageNum: number,
  pageSize: number,
  sorts?: Sort[],
  filters?: (SearchFilterParam | SearchFilterConjunctParam)[],
) {
  const query: Query = {
    sorts: sorts || [],
    filters: parseSearchParamsToFilters(filters) || [],
  };
  const queryCmts = async (): Promise<PagedResults<Comment>> => {
    const resp = await axiosInstance.post("/comments/query/", query, {
      ...baseAxiosConfig(accessToken),
      params: { pageNum, pageSize },
    });
    return resp.data;
  };
  return useQuery(
    [CMT_KEY, "query", pageNum, pageSize, sorts, filters],
    async () => await queryCmts(),
    { staleTime: RQUERY_STALE_TIME, keepPreviousData: true },
  );
}

export function useUpdateComment() {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      accessToken,
      inputData,
    }: {
      accessToken: string;
      inputData: CommentInput;
    }) => {
      return updateRecord(accessToken, inputData, "comments");
    },
    {
      onSuccess: () => {
        return queryClient.invalidateQueries([CMT_KEY]);
      },
    },
  );
}

export function useDeleteComment() {
  const queryClient = useQueryClient();

  return useMutation(
    ({ accessToken, uuid }: { accessToken: string; uuid: string }) => {
      return deleteRecord(accessToken, uuid, "comments");
    },
    {
      onSuccess: () => {
        return queryClient.invalidateQueries([CMT_KEY]);
      },
    },
  );
}
