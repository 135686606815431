import { useMutation, useQuery, useQueryClient } from "react-query";
import { RQUERY_STALE_TIME } from "../../env";
import {
  getEmailAutomation,
  getEmailAutomations,
  updateEmailAutomation,
} from "../api/endpoints";
import { EmailRecipientInput } from "../schemas/core";

export function useEmailAutomations(accessToken: string) {
  return useQuery(
    ["emailAutomation", accessToken],
    async () => await getEmailAutomations(accessToken),
    { staleTime: RQUERY_STALE_TIME }
  );
}

export function useEmailAutomation(accessToken: string, uuid: string) {
  return useQuery(
    ["emailAutomation", accessToken, uuid],
    async () => await getEmailAutomation(accessToken, uuid),
    { staleTime: RQUERY_STALE_TIME }
  );
}

export function useUpdateEmailAutomation() {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      accessToken,
      inputData,
    }: {
      accessToken: string;
      inputData: EmailRecipientInput;
    }) => {
      return updateEmailAutomation(accessToken, inputData);
    },
    {
      onSuccess: () => {
        return queryClient.invalidateQueries(["emailAutomation"]);
      },
    }
  );
}
