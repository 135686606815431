import {
  Button,
  ResourceItem,
  ResourceList,
  Tabs,
  Tooltip,
} from "@shopify/polaris";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useLocation } from "react-router-dom";
import { RQUERY_STALE_TIME } from "../env";
import Card from "../shared/Card";
import Stack from "../shared/Stack";
import { Heading, PText, Subheading } from "../shared/TextComponents";
import BreadcrumbPage from "./BreadcrumbPage";
import {
  BaseRMAEndpoint,
  getBulkRMAUpdateEntries,
  revertBulkRMAUpdate,
} from "./api/genericEndpoints";
import { BulkUpdateEntry, CurrentUserData } from "./schemas/core";
import Prompt from "./utils/Prompt";

interface BulkEditDashboardProps {
  currentUserData: CurrentUserData;
  title: string;
  baseEndpoint: BaseRMAEndpoint;
  searchUrl: string;
}

export function BulkEditDashboard(props: BulkEditDashboardProps) {
  const [revertPromptActive, setRevertPromptActive] = useState(false);
  const [revertConfirmActive, setRevertConfirmActive] = useState(false);
  const [revertEntry, setRevertEntry] = useState<BulkUpdateEntry>();

  const search = useLocation().search;
  const tab = new URLSearchParams(search).get("tab");

  const [selectedTab, setSelectedTab] = useState(tab === "history" ? 1 : 0);
  const tabs = [
    { id: "options", content: "Bulk Options", panelID: "bulk-options" },
    { id: "history", content: "Previous Updates", panelID: "history" },
  ];

  const queryClient = useQueryClient();
  const bulkUpdateKey = "bulkUpdateEntries";

  const { data: bulkUpdateEntries, isLoading: entriesLoading } = useQuery(
    [bulkUpdateKey, props.baseEndpoint],
    async () =>
      getBulkRMAUpdateEntries(
        props.currentUserData.accessToken,
        props.baseEndpoint
      ),
    { staleTime: RQUERY_STALE_TIME }
  );

  const revertMutation = useMutation(
    async (id: string) => {
      return revertBulkRMAUpdate(
        props.currentUserData.accessToken,
        id,
        props.baseEndpoint
      );
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([
          bulkUpdateKey,
          props.baseEndpoint,
        ]);
        setRevertEntry(undefined);
        setRevertPromptActive(false);
        setRevertConfirmActive(true);
      },
    }
  );

  const revertPrompt = revertEntry && (
    <Prompt
      title="Confirm reversion"
      active={revertPromptActive}
      onClose={() => setRevertPromptActive(false)}
      primaryAction={{
        content: "Proceed",
        onAction: async () => {
          await revertMutation.mutate(revertEntry.id);
        },
      }}
    >
      <Stack spacing={1}>
        <PText>
          Really delete bulk update {`${revertEntry.id}`}? This cannot be
          undone.
        </PText>
        <Subheading>Reversion details:</Subheading>
        <PText>
          <strong>Changes:</strong> {revertEntry.changes.length}
        </PText>
        <PText>
          <strong>Date:</strong> {revertEntry.createDt.toLocaleString()}
        </PText>
        <PText>
          <strong>Note:</strong> {revertEntry.note}
        </PText>
        <PText>
          <strong>User:</strong> {revertEntry.originatingUser.name}
        </PText>
      </Stack>
    </Prompt>
  );

  const revertConfirmPrompt = (
    <Prompt
      title="Reversion successful"
      active={revertConfirmActive}
      onClose={() => setRevertConfirmActive(false)}
      primaryAction={{
        content: "OK",
        onAction: () => setRevertConfirmActive(false),
      }}
    >
      <PText>Bulk update successfully reverted.</PText>
    </Prompt>
  );

  const options = (
    <Stack>
      <Card innerCard>
        <Stack spacing={1}>
          <PText weight="bold">Edit Records in Bulk</PText>
          <PText>
            Suitable for edits of up to 100 records. Start by building a search
            query.
          </PText>
          <Stack direction="row" justify="flex-end">
            <Button variant="primary" tone="success" url={props.searchUrl}>
              Proceed
            </Button>
          </Stack>
        </Stack>
      </Card>
      <Card innerCard>
        <Stack spacing={1}>
          <PText weight="bold">Upload File for Bulk Updating</PText>
          <PText>Suitable for edits of more than 100 records.</PText>
          <Stack direction="row" justify="flex-end">
            <Button variant="primary" tone="success" url="">
              Proceed
            </Button>
          </Stack>
        </Stack>
      </Card>
    </Stack>
  );

  const renderEntry = (entry: BulkUpdateEntry) => {
    let entryText =
      `${entry.changes.length} changes` +
      ` \u25CF ` +
      `${entry.originatingUser.name}` +
      ` \u25CF ` +
      `${entry.createDt.toLocaleString()}`;
    if (entry.revertDt) {
      entryText += ` \u25CF Reverted on: ${entry.revertDt?.toLocaleString()}`;
    }

    return (
      <ResourceItem
        id={entry.id}
        onClick={() => {}}
        //   shortcutActions={[{ content: "Revert", onAction: () => {} }]}
      >
        <Stack spacing={0}>
          <PText>{entryText}</PText>
          {entry.note && <PText>{entry.note}</PText>}
          <Stack direction="row" justify="flex-end">
            <Tooltip
              content={
                entry.revertDt !== null
                  ? "This bulk update has already been reverted"
                  : "Revert this bulk update"
              }
            >
              <Button
                disabled={entry.revertDt !== null}
                onClick={() => {
                  setRevertEntry(entry);
                  setRevertPromptActive(true);
                }}
              >
                Revert
              </Button>
            </Tooltip>
          </Stack>
        </Stack>
      </ResourceItem>
    );
  };

  const history = bulkUpdateEntries && (
    <Stack>
      <Stack direction="row" justify="flex-end">
        <Button
          disabled={entriesLoading}
          onClick={() =>
            queryClient.invalidateQueries([bulkUpdateKey, props.baseEndpoint])
          }
        >
          Refresh
        </Button>
      </Stack>
      <ResourceList
        resourceName={{ singular: "entry", plural: "entries" }}
        items={bulkUpdateEntries.sort(
          (entryA, entryB) =>
            entryB.createDt.getTime() - entryA.createDt.getTime()
        )}
        totalItemsCount={bulkUpdateEntries?.length}
        renderItem={renderEntry}
      />
    </Stack>
  );

  const tabComponents = [options, history];

  return (
    <BreadcrumbPage>
      {revertConfirmPrompt}
      {revertPrompt}
      <Card>
        <Heading>{props.title}</Heading>
        <Tabs
          tabs={tabs}
          selected={selectedTab}
          onSelect={(tabIdx) => setSelectedTab(tabIdx)}
        >
          {tabComponents[selectedTab]}
        </Tabs>
      </Card>
    </BreadcrumbPage>
  );
}
