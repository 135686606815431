import { Button, Checkbox, Divider, Tabs } from "@shopify/polaris";
import { useState } from "react";
import Stack from "../../shared/Stack";
import { Subheading } from "../../shared/TextComponents";
import {
  Contexts,
  ContextualPrefs,
  CurrentUserData,
  PermissionId,
  UserPrefs,
} from "../schemas/core";
import { removeNulls } from "../utils/shared";
import { hasPermissions } from "./authutils";

interface BaseToggleProps {
  pref: boolean;
  setPrefs: () => void;
}

interface ToggleProps extends BaseToggleProps {
  label: string;
}

const Toggle = ({ pref, setPrefs, label }: ToggleProps) => (
  <Checkbox onChange={() => setPrefs()} checked={pref} label={label} />
);

interface PrefToggleProps extends BaseToggleProps {
  recordName: string;
}

const AdvSearchToggle = ({ pref, setPrefs, recordName }: PrefToggleProps) => (
  <Toggle
    pref={pref}
    setPrefs={setPrefs}
    label={`Use advanced search settings drawer for ${recordName}s.`}
  />
);

const EditToggle = ({ pref, setPrefs, recordName }: PrefToggleProps) => (
  <Toggle
    pref={pref}
    setPrefs={setPrefs}
    label={`Default to edit mode when viewing a ${recordName} from search results.`}
  />
);

const NavToggle = ({ pref, setPrefs, recordName }: PrefToggleProps) => (
  <Toggle
    pref={pref}
    setPrefs={setPrefs}
    label={`Default to nav mode when viewing a ${recordName} from search results`}
  />
);

interface UserPrefsEditorProps {
  currentUserData: CurrentUserData;
  onSave: (prefs: UserPrefs) => void;
}

export default function UserPrefsEditor(props: UserPrefsEditorProps) {
  const [selectedTab, setSelectedTab] = useState(0);
  const [prefs, setPrefs] = useState<UserPrefs>(props.currentUserData.prefs);

  /* Polaris v10 does not currently allow night/dark mode. As this is currently 
  the only general pref, it's all unused at the moment.*/

  //   const setGeneralPref = (k: PropsOfType<UserPrefs, boolean>) => {
  //     setPrefs((previousPrefs) => ({ ...previousPrefs, [k]: !previousPrefs[k] }));
  //   };

  //   const generalPrefs = user && (
  //     <Stack>
  //       <Subheading>General Preferences</Subheading>
  //       <Toggle
  //         pref={prefs.useNightMode}
  //         setPrefs={() => setGeneralPref("useNightMode")}
  //         genMsg={(currentPref: boolean) =>
  //           [
  //             `${currentPref ? "Deactivate" : "Activate"} to turn `,
  //             `${currentPref ? "off" : "on"} night mode.`,
  //           ].join("")
  //         }
  //       />
  //     </Stack>
  //   );

  const setPrefsByContextAndKey = (
    c: keyof Contexts,
    k: keyof ContextualPrefs
  ) => {
    setPrefs((previousPrefs) => ({
      ...previousPrefs,
      contextualPrefs: {
        ...previousPrefs.contextualPrefs,
        [c]: {
          ...previousPrefs.contextualPrefs[c],
          [k]: !previousPrefs.contextualPrefs[c][k],
        },
      },
    }));
  };

  const setContextualPrefByKey = (k: keyof ContextualPrefs, value: boolean) => {
    setPrefs((previousPrefs) => ({
      ...previousPrefs,
      contextualPrefs: Object.entries(previousPrefs.contextualPrefs).reduce(
        (prev, [ck, context]) => ({
          ...prev,
          [ck]: { ...context, [k]: value },
        }),
        {}
      ) as Contexts,
    }));
  };

  const checkIfAllContextualPrefByKey = (k: keyof ContextualPrefs) => {
    return Object.entries(prefs.contextualPrefs).every(
      ([_, context]) => context[k]
    );
  };

  const tabs: {
    // Required by polaris:
    id: string;
    content: string;
    panelID: keyof Contexts;
    // Supplemental stuff we use to auto-generate toggles appropriately:
    recordName: string;
    viewEditModePerm: PermissionId;
  }[] = [
    {
      id: "birth-records",
      content: "Birth Records",
      panelID: "birthRecords",
      recordName: "birth record",
      viewEditModePerm: "add_edit_publish_remove_birth_records",
    },
    {
      id: "death-records",
      content: "Death Records",
      panelID: "deathRecords",
      recordName: "death record",
      viewEditModePerm: "add_edit_publish_remove_death_records",
    },
    {
      id: "gold-star",
      content: "Gold Star Roll",
      panelID: "goldStar",
      recordName: "gold star roll record",
      viewEditModePerm: "add_edit_remove_gold_star_roll_records",
    },
    {
      id: "vets-graves",
      content: "Veterans Graves",
      panelID: "vetsGraves",
      recordName: "veterans grave record",
      viewEditModePerm: "add_edit_remove_vets_grave_records",
    },
    {
      id: "census",
      content: "Census",
      panelID: "census",
      recordName: "census record",
      viewEditModePerm: "add_edit_remove_census_records",
    },
    // {
    //   id: "collections",
    //   content: "Collections",
    //   panelID: "collections",
    //   recordName: "collections item",
    //   viewEditModePerm: "view_public_collections_items",
    // },
  ];

  const editPerms = tabs.reduce((previous, tab) => {
    return {
      ...previous,
      [tab.panelID]: hasPermissions(
        props.currentUserData,
        tab.viewEditModePerm
      ),
    };
  }, {}) as { [P in keyof Contexts]: boolean };

  const allRecordPrefs = removeNulls([
    <AdvSearchToggle
      pref={checkIfAllContextualPrefByKey("useAdvSearchToggle")}
      setPrefs={() =>
        setContextualPrefByKey(
          "useAdvSearchToggle",
          !checkIfAllContextualPrefByKey("useAdvSearchToggle")
        )
      }
      recordName="all record"
    />,
    Object.entries(editPerms)
      .map(([_, perm]) => perm)
      .includes(true) ? (
      <EditToggle
        pref={checkIfAllContextualPrefByKey("useEditMode")}
        setPrefs={() =>
          setContextualPrefByKey(
            "useEditMode",
            !checkIfAllContextualPrefByKey("useEditMode")
          )
        }
        recordName="record"
      />
    ) : null,
    <NavToggle
      pref={checkIfAllContextualPrefByKey("useNavMode")}
      setPrefs={() =>
        setContextualPrefByKey(
          "useNavMode",
          !checkIfAllContextualPrefByKey("useNavMode")
        )
      }
      recordName="record"
    />,
  ]);

  const contextualTabComps = tabs.map((tab) => (
    <>
      {removeNulls([
        <AdvSearchToggle
          pref={prefs.contextualPrefs[tab.panelID]["useAdvSearchToggle"]}
          setPrefs={() =>
            setPrefsByContextAndKey(tab.panelID, "useAdvSearchToggle")
          }
          recordName={tab.recordName}
        />,
        hasPermissions(props.currentUserData, tab.viewEditModePerm) ? (
          <EditToggle
            pref={prefs.contextualPrefs[tab.panelID]["useEditMode"]}
            setPrefs={() => setPrefsByContextAndKey(tab.panelID, "useEditMode")}
            recordName={tab.recordName}
          />
        ) : null,
        <NavToggle
          pref={prefs.contextualPrefs[tab.panelID]["useNavMode"]}
          setPrefs={() => setPrefsByContextAndKey(tab.panelID, "useNavMode")}
          recordName={tab.recordName}
        />,
      ])}
    </>
  ));

  const tabComponents = [<>{allRecordPrefs}</>, ...contextualTabComps];

  return (
    <Stack>
      {/* 
        General prefs is currently disabled because the only pref was night 
        mode and Polaris v10 does not allow night mode. 
      */}
      {/* <Card.Section>{generalPrefs}</Card.Section> */}
      <Divider />
      <Subheading>Other Preferences</Subheading>
      <Tabs
        tabs={[
          {
            id: "all",
            content: "All",
            panelID: "all-prefs",
          },
          ...tabs,
        ]}
        selected={selectedTab}
        onSelect={(tabIdx) => setSelectedTab(tabIdx)}
      >
        <>{tabComponents[selectedTab]}</>
      </Tabs>
      {prefs !== props.currentUserData.prefs ? (
        <Stack direction="row" justify="flex-end">
          <Button onClick={() => setPrefs(props.currentUserData.prefs)}>
            Discard Changes
          </Button>
          <Button
            variant="primary"
            tone="success"
            onClick={() => props.onSave(prefs)}
          >
            Save
          </Button>
        </Stack>
      ) : null}
    </Stack>
  );
}
