import { useMutation, useQuery, useQueryClient } from "react-query";
import { RQUERY_STALE_TIME } from "../../env";
import {
  deleteRecord,
  getRMAEvents,
  getRecord,
  performBulkRMAUpdate,
  updateRecord,
} from "../api/genericEndpoints";
import {
  DeathRecord,
  DeathRecordInput,
  deathRecordEventSchemaList,
  deathRecordSchema,
} from "../schemas/deathRecord";

export function useDeathRecord(accessToken: string, uuid?: string) {
  return useQuery(
    ["deathRecord", accessToken, uuid],
    async () =>
      accessToken && uuid
        ? await getRecord<DeathRecord>(
            accessToken,
            uuid,
            "death-records",
            deathRecordSchema,
          )
        : null,
    { staleTime: RQUERY_STALE_TIME },
  );
}

export function useUpdateDeathRecord() {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      accessToken,
      inputData,
    }: {
      accessToken: string;
      inputData: DeathRecordInput;
    }) => {
      return updateRecord(accessToken, inputData, "death-records");
    },
    {
      onSuccess: () => {
        return queryClient.invalidateQueries(["deathRecord"]);
      },
    },
  );
}

export function useDeleteDeathRecord() {
  return useMutation(
    ({ accessToken, uuid }: { accessToken: string; uuid: string }) => {
      return deleteRecord(accessToken, uuid, "death-records");
    },
  );
}

export function useDeathRecordEvents(accessToken: string, uuid?: string) {
  return useQuery(
    ["deathRecord", "events", uuid],
    async () =>
      uuid
        ? await getRMAEvents<DeathRecord>(
            accessToken,
            uuid,
            deathRecordEventSchemaList,
            "death-records",
          )
        : null,
    {
      staleTime: RQUERY_STALE_TIME,
    },
  );
}

export function useBulkUpdateDeathRecords() {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      accessToken,
      note,
      records,
    }: {
      accessToken: string;
      note?: string;
      records: DeathRecord[];
    }) => {
      return performBulkRMAUpdate(accessToken, "death-records", records, note);
    },
    {
      onSuccess: () => {
        return queryClient.invalidateQueries(["deathRecord"]);
      },
    },
  );
}
