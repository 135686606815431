import { Button, Popover } from "@shopify/polaris";
import { useState } from "react";
import Stack from "../../shared/Stack";
import {
  FilterBooleanTermInput,
  FilterDatePartTermInput,
  FilterFieldSelect,
  FilterOperatorSelect,
  FilterSelectTermInput,
  FilterTermInput,
  processDynamicFilterTermInputs,
} from "./FilterComponents";
import * as searchutils from "./searchutils";

interface FilterParamPopoverProps extends searchutils.searchParamPopoverProps {
  filterFields: searchutils.SearchFilterFields;
  onSubmit: (f: searchutils.SearchFilterParam) => void;
}

export default function FilterParamPopover(props: FilterParamPopoverProps) {
  const [selectedField, setSelectedField] = useState<string>("");
  const [operator, setOperator] =
    useState<searchutils.FilterParamOperator>("=");
  const [term, setTerm] = useState<string>();
  const [startDay, setStartDay] = useState<number>();
  const [startMonth, setStartMonth] = useState<number>();
  const [startYear, setStartYear] = useState<number>();
  const [fieldType, setFieldType] =
    useState<searchutils.FilterFieldType | null>(null);

  const close = () => {
    setSelectedField("");
    setOperator("=");
    setTerm(undefined);
    setStartDay(undefined);
    setStartMonth(undefined);
    setStartYear(undefined);
    setFieldType(null);
    props.onClose();
  };

  const submit = () => {
    const result = processDynamicFilterTermInputs(
      operator,
      selectedField,
      props.filterFields,
      term,
      startDay,
      startMonth,
      startYear,
    );
    if (result) {
      props.onSubmit(result);
    }
    close();
  };

  const onFieldSelect = (selected: string) => {
    setSelectedField(selected);
    setFieldType(props.filterFields[selected].type);
    setTerm(undefined);
    let defaultOp = props.filterFields[selected].defaultOperator;
    setOperator(defaultOp || "=");
  };

  const selector = (
    <FilterFieldSelect
      selectedField={selectedField}
      onFieldSelect={onFieldSelect}
      filterFields={props.filterFields}
    />
  );

  const operatorInput = selectedField && (
    <FilterOperatorSelect
      operator={operator}
      setOperator={setOperator}
      selectedField={selectedField}
      filterFields={props.filterFields}
      setTerm={setTerm}
    />
  );

  // General term input used for numbers and strings:
  const termInput = <FilterTermInput term={term} setTerm={setTerm} />;

  // Specialized term inputs:
  const selectInput = selectedField &&
    props.filterFields[selectedField].validTerms && (
      <FilterSelectTermInput
        label="select: "
        labelInline
        term={term}
        validTerms={props.filterFields[selectedField].validTerms}
        setTerm={setTerm}
      />
    );

  const booleanInput = selectedField && (
    <FilterBooleanTermInput term={term} setTerm={setTerm} />
  );

  const opts = fieldType && (
    <Stack>
      {["number", "date", "string"].includes(fieldType) &&
        !props.filterFields[selectedField].validTerms &&
        operatorInput}
      {["number", "string"].includes(fieldType) &&
        !props.filterFields[selectedField].validTerms &&
        !["isnull", "notnull"].includes(operator) &&
        termInput}
      {selectInput}
      {fieldType === "date" && (
        <FilterDatePartTermInput
          setDay={setStartDay}
          setMonth={setStartMonth}
          setYear={setStartYear}
          day={startDay}
          month={startMonth}
          year={startYear}
        />
      )}
      {fieldType === "boolean" && booleanInput}
    </Stack>
  );

  return (
    <>
      <Popover
        active={props.active}
        activator={props.activator}
        onClose={close}
        preferredAlignment="left"
        fullHeight
      >
        <Popover.Section>
          <Stack>
            {selector}
            {opts}
          </Stack>
        </Popover.Section>
        <Popover.Section>
          <Button onClick={submit} disabled={term || startYear ? false : true}>
            Add
          </Button>
        </Popover.Section>
      </Popover>
    </>
  );
}
