import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { FieldError, useForm } from "react-hook-form";
import {
  AccumulativeTextField,
  ControlledSelectFormField,
  BaseFormCompProps,
  FormSection,
  FormFields,
} from "../Form";
import {
  useCensusPage,
  useCensusRecord,
  useDeleteCensusRecord,
  useUpdateCensusRecord,
} from "../hooks/censusHooks";
import { useCensusPageImage } from "../hooks/mediaHooks";
import { CensusPage } from "../schemas/censusPage";
import { censusLabels } from "../schemas/census";
import { CensusRecordInput } from "../schemas/census";
import { censusInputSchema } from "../schemas/census";
import { CensusRecord } from "../schemas/census";
import { CensusCounties } from "../schemas/census";
import { Ward } from "../schemas/census";
import { Gender } from "../schemas/census";
import { Heading, Subheading } from "../../shared/TextComponents";
import { DefaultPageProps } from "../utils/shared";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Page, Layout, Spinner, Button } from "@shopify/polaris";
import Skeleton from "../Skeleton";
import BreadcrumbPage from "../BreadcrumbPage";
import { SearchQueryProps } from "../search/searchutils";
import { searchRecords } from "../api/genericEndpoints";
import {
  getCensusPageDisplayName,
  renderCensusPageHit,
} from "./CensusPageSearch";
import MiniSearch from "../search/MiniSearch";
import ZoomableImage from "../ZoomableImage";
import Card from "../../shared/Card";
import Stack from "../../shared/Stack";

interface CensusFormProps extends BaseFormCompProps<CensusRecord> {
  pageId?: string;
  certificateId?: string;
}

export default function CensusForm(props: CensusFormProps) {
  const [pageId, setPageId] = useState<string | null | undefined>(props.pageId);

  const censusRecord = props.record;

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    getValues,
    register,
    setValue,
  } = useForm<CensusRecordInput>({
    resolver: yupResolver(censusInputSchema),
    defaultValues: {
      ...censusInputSchema.cast(censusRecord),
      certificateId: props.certificateId,
    },
  });

  const { data: censusPage } = useCensusPage(
    props.currentUserData.accessToken,
    pageId
  );

  const { data: image, isLoading: imageLoading } = useCensusPageImage(
    props.currentUserData.accessToken,
    censusPage?.SHA256Hash
  );

  useEffect(() => {
    if (!censusRecord) {
      return;
    }
    setPageId(censusRecord.pageId);
    reset(censusInputSchema.cast(censusRecord));
  }, [setPageId, censusRecord, reset]);

  const pageSearch = !pageId && (
    <MiniSearch
      currentUserData={props.currentUserData}
      label="Attach to Page"
      searchQuery={async (props: SearchQueryProps) =>
        searchRecords<CensusPage>(props, "census/pages")
      }
      hitOnClick={(record) => setPageId(record.id)}
      renderHit={{
        render: renderCensusPageHit,
        accessibilityLabel: (record) =>
          `View details for census page ${getCensusPageDisplayName(record)}`,
      }}
    />
  );

  const imageSpinner = imageLoading && !image && <Spinner size="large" />;

  const pageDisplay = !imageLoading && image && pageId && (
    <Stack align="flex-start">
      <Heading>Census Page</Heading>
      <ZoomableImage src={image} alt="Page to attach this census record to." />
      {/* Suppress remove from page button if pageId was passed, as that flow is
      used by the Add Record to Page functionality in CensusPageDetail. */}
      {!props.pageId && (
        <Button
          tone="critical"
          variant="primary"
          onClick={() => setPageId(undefined)}
        >
          Remove from Page
        </Button>
      )}
    </Stack>
  );

  const onSubmit = (inputData: CensusRecordInput) => {
    inputData.pageId = pageId;
  };

  return (
    <FormSection
      currentUserData={props.currentUserData}
      handleSubmit={handleSubmit}
      inputSchema={censusInputSchema}
      initialValues={censusRecord}
      mutateHook={useUpdateCensusRecord}
      deleteHook={useDeleteCensusRecord}
      onSubmit={onSubmit}
      onMutateSuccess={props.onSubmitSuccess}
      onDeleteSuccess={props.onDeleteSuccess}
    >
      {pageId ? imageSpinner || pageDisplay : pageSearch}
      <FormFields
        control={control}
        spec={{
          certificateId: {
            type: "text",
            disabled: !!props.certificateId,
          },
          prefix: { type: "text" },
          firstName: { type: "text" },
          middleName: { type: "text" },
          familyName: { type: "text" },
          suffix: { type: "text" },
          age: { type: "text" },
          ethnicityOther: { type: "text" },
          birthPlace: { type: "text" },
          fatherBirthPlace: { type: "text" },
          motherBirthPlace: { type: "text" },
          censusDateStart: { type: "date" },
          censusDateEnd: { type: "date" },
          locality: { type: "date" },
          ycord: { type: "number" },
          lineInImage: {
            type: "number",
            sideEffect: (value) => {
              if (props.certificateId) {
                setValue("certificateId", `${props.certificateId}${value}`, {
                  shouldTouch: true,
                });
              }
            },
          },
        }}
        labels={censusLabels}
        errors={{ certificateId: errors.certificateId }}
        reset={reset}
        getValues={getValues}
      />
      <ControlledSelectFormField
        control={control}
        options={[...CensusCounties]}
        defaultValue="Unknown"
        fieldID="county"
        label={censusLabels.county}
        error={errors.county}
      />
      <ControlledSelectFormField
        control={control}
        options={[...Ward]}
        defaultValue="Unknown"
        fieldID="ward"
        label={censusLabels.ward}
        error={errors.ward}
      />
      <ControlledSelectFormField
        control={control}
        options={[...Gender]}
        defaultValue="blank"
        fieldID="gender"
        label={censusLabels.gender}
        error={errors.gender}
      />
      <Subheading>Alternate Names</Subheading>
      <AccumulativeTextField
        fieldName={"alternateNames"}
        control={control}
        register={register}
        errors={errors.alternateNames as FieldError}
      />
    </FormSection>
  );
}

export function CensusAddPage(props: DefaultPageProps) {
  const navigate = useNavigate();

  return (
    <Page>
      <Layout sectioned>
        <Card>
          <CensusForm
            currentUserData={props.currentUserData}
            onSubmitSuccess={(id) => navigate(`../${id}`)}
          />
        </Card>
      </Layout>
    </Page>
  );
}

export function CensusEditPage(props: DefaultPageProps) {
  const search = useLocation().search;
  const editMode = new URLSearchParams(search).get("editMode");

  const { uuid } = useParams();
  const navigate = useNavigate();

  const { data: censusRecord } = useCensusRecord(
    props.currentUserData.accessToken,
    uuid
  );

  const skeleton = !censusRecord && <Skeleton lines={20} />;

  const form = censusRecord && (
    <CensusForm
      currentUserData={props.currentUserData}
      onSubmitSuccess={() => (editMode === "true" ? null : navigate("../"))}
      onDeleteSuccess={() => navigate("../../")}
      record={censusRecord}
    />
  );

  return (
    <BreadcrumbPage
      breadcrumbs="both"
      breadcrumbAction={() =>
        navigate(`${editMode === "true" ? "../../" : "../"}`)
      }
    >
      <Card>{form || skeleton}</Card>
    </BreadcrumbPage>
  );
}
