import * as catalogueItem from "../schemas/catalogueItem";
import * as schemas from "../schemas/core";
import * as findAid from "../schemas/findAid";
import {
  FilterLike,
  SearchFieldBoosts,
  searchFieldBoostsSchema,
  SearchQuery,
  SearchQueryProps,
  SearchSortOrder,
} from "../search/searchutils";
import { formatDurationNeatly } from "../utils/shared";
import { addRMAMedia } from "./genericEndpoints";
import {
  APIError,
  AuthenticationError,
  AuthorizationError,
  ServiceError,
  axiosInstance,
  baseAxiosConfig,
  handleAPIErrors,
} from "./shared";

export const signInWithFirebaseIdToken = async (
  firebaseIdToken: string,
): Promise<schemas.CurrentUserData> => {
  const resp = await axiosInstance.post(
    "/auth/sign-in-with-firebase-id-token",
    {
      firebaseIdToken,
    },
  );
  const userData = await schemas.currentUserDataSchema.validate(resp.data);
  axiosInstance.defaults.headers.common["Authorization"] =
    `Bearer ${userData.accessToken}`;
  return userData;
};

export const getUser = async (
  accessToken: string,
  uuid: string,
): Promise<schemas.User> => {
  const resp = await axiosInstance.get(
    `/auth/users/${uuid}`,
    baseAxiosConfig(accessToken),
  );
  const record = await schemas.userSchema.validate(resp.data);
  return record;
};

export const getUserActivity = async (
  accessToken: string,
  uuid: string,
): Promise<schemas.Event<schemas.EventStateField[]>[]> => {
  const resp = await axiosInstance.get(`/auth/users/${uuid}/activity`, {
    ...baseAxiosConfig(accessToken),
  });
  const result = await schemas.naiveEventSchemaList.validate(resp.data);
  return result;
};

export const updateUser = async (
  accessToken: string,
  userInput: schemas.UserInput,
): Promise<string | undefined> => {
  try {
    const resp = await axiosInstance.post(
      userInput.id ? `/auth/users/${userInput.id}` : "/auth/users/add",
      userInput,
      baseAxiosConfig(accessToken),
    );
    return resp.data;
    // return validateString(resp.data);
  } catch (e) {
    console.error(e);
    throw Error;
    // handleAPIErrors(e);
  }
};

export const directAddUser = async (
  accessToken: string,
  userInput: schemas.UserInput,
) => {};

export const updateUserPrefs = async (
  accessToken: string,
  prefs: schemas.UserPrefs,
): Promise<schemas.UserPrefs> => {
  try {
    const resp = await axiosInstance.put(
      `/auth/users/prefs`,
      prefs,
      baseAxiosConfig(accessToken),
    );
    return schemas.userPrefsSchema.validate(resp.data);
  } catch (e) {
    console.error(e);
    throw Error;
  }
};

export const createAPICredsForUser = async (
  accessToken: string,
  uuid: string,
): Promise<string> => {
  const resp = await axiosInstance.put(
    `/auth/create-api-creds`,
    { uuid },
    baseAxiosConfig(accessToken),
  );
  return resp.data;
};

export const requestAPICredsForUser = async (
  accessToken: string,
): Promise<string> => {
  const resp = await axiosInstance.put(
    `/auth/request-api-creds`,
    baseAxiosConfig(accessToken),
  );
  return resp.data;
};

export const requestEmailVerification = async (
  accessToken: string,
): Promise<boolean> => {
  const resp = await axiosInstance.get(
    "/auth/verify-email",
    baseAxiosConfig(accessToken),
  );
  return resp.status === 200;
};

export const requestPasswordReset = async (email: string): Promise<boolean> => {
  const resp = await axiosInstance.put("/auth/reset-password", {
    email_address: email,
  });
  return resp.status === 200;
};

export const deleteUser = async (
  accessToken: string,
  uuid: string,
): Promise<boolean> => {
  const resp = await axiosInstance.delete(
    `/auth/users/${uuid}`,
    baseAxiosConfig(accessToken),
  );
  return resp.status === 204 ? true : false;
};

export const getUsers = async (
  accessToken: string,
  basicFilter?: string,
  hasRole?: string,
): Promise<schemas.UserList> => {
  //   try {
  const resp = await axiosInstance.get("/auth/users", {
    ...baseAxiosConfig(accessToken),
    params: { basicFilter, hasRole },
  });
  const records = await schemas.userSchemaList.validate(resp.data);
  return records;
  //   } catch (e) {
  //     return handleAPIErrors(e);
  //   }
};

export const getRole = async (
  accessToken: string,
  uuid: string,
): Promise<schemas.Role> => {
  const resp = await axiosInstance.get(
    `/auth/roles/${uuid}`,
    baseAxiosConfig(accessToken),
  );
  const record = await schemas.roleSchema.validate(resp.data);
  return record;
};

export const updateRole = async (
  accessToken: string,
  roleInput: schemas.RoleInput,
): Promise<string | undefined> => {
  try {
    const resp = await axiosInstance.post(
      roleInput.id ? `/auth/roles/${roleInput.id}/edit` : "/auth/roles",
      roleInput,
      baseAxiosConfig(accessToken),
    );
    return resp.data;
  } catch (e) {
    console.error(e);
    throw Error;
  }
};

export const getRoles = async (
  accessToken: string,
): Promise<schemas.RoleList> => {
  const resp = await axiosInstance.get(
    "/auth/roles",
    baseAxiosConfig(accessToken),
  );
  const records = await schemas.roleSchemaList.validate(resp.data);
  return records;
};

export const getRolesByUser = async (
  accessToken: string,
  user_uuid?: string,
): Promise<Record<string, schemas.RoleList>> => {
  const resp = await axiosInstance.get("/auth/roles/by-user", {
    ...baseAxiosConfig(accessToken),
    params: { user_id: user_uuid },
  });
  const records = await schemas.validateRecordOfSchemas<schemas.RoleList>(
    resp.data,
    schemas.roleSchemaList,
  );
  return records;
};

export const setPermissions = async (
  accessToken: string,
  roleUUID: string,
  permissionIDs: string[],
): Promise<void | AuthorizationError | AuthenticationError | ServiceError> => {
  try {
    await axiosInstance.post(
      `/auth/roles/${roleUUID}/permissions`,
      { permissionIDs },
      baseAxiosConfig(accessToken),
    );
  } catch (e) {
    return handleAPIErrors(e);
  }
};

export const setSubRoles = async (
  accessToken: string,
  roleUUID: string,
  subRoleUUIDs: string[],
): Promise<void | AuthorizationError | AuthenticationError | ServiceError> => {
  try {
    await axiosInstance.post(
      `/auth/roles/${roleUUID}/sub-roles`,
      { subRoleUUIDs },
      baseAxiosConfig(accessToken),
    );
  } catch (e) {
    return handleAPIErrors(e);
  }
};

export const getPermissions = async (
  accessToken: string,
): Promise<schemas.Permission[]> => {
  const resp = await axiosInstance.get(
    "/auth/permissions",
    baseAxiosConfig(accessToken),
  );
  const records = await schemas.permissionSchemaList.validate(resp.data);
  return records;
};

export const getPermissionsByRole = async (
  accessToken: string,
): Promise<Record<string, schemas.Permission[]>> => {
  const resp = await axiosInstance.get(
    "/auth/permissions/by-role",
    baseAxiosConfig(accessToken),
  );
  const records = await schemas.validateRecordOfSchemas<schemas.PermissionList>(
    resp.data,
    schemas.permissionSchemaList,
  );
  return records;
};

export const setRoles = async (
  accessToken: string,
  userUUID: string,
  roleUUIDs: string[],
): Promise<void | AuthorizationError | AuthenticationError | ServiceError> => {
  try {
    await axiosInstance.post(
      `/auth/users/${userUUID}/roles`,
      { roleUUIDs },
      baseAxiosConfig(accessToken),
    );
  } catch (e) {
    return handleAPIErrors(e);
  }
};

export const getEmailAutomation = async (
  accessToken: string,
  uuid: string,
): Promise<schemas.EmailRecipient> => {
  const resp = await axiosInstance.get(
    `/email-autos/${uuid}`,
    baseAxiosConfig(accessToken),
  );
  return resp.data;
};

export const getEmailAutomations = async (
  accessToken: string,
): Promise<schemas.EmailRecipient[]> => {
  const resp = await axiosInstance.get(
    "/email-autos/",
    baseAxiosConfig(accessToken),
  );
  return resp.data;
};

export const updateEmailAutomation = async (
  accessToken: string,
  inputData: schemas.EmailRecipientInput,
): Promise<string> => {
  const resp = await axiosInstance.post(
    `/email-autos/${inputData.purpose}`,
    inputData.recipients ? inputData.recipients : [],
    baseAxiosConfig(accessToken),
  );
  return resp.data;
};

export const getSearchFieldBoosts = async (
  dataset: string,
): Promise<SearchFieldBoosts> => {
  const resp = await axiosInstance.get(`/search-field-boosts/${dataset}`);
  const result = await searchFieldBoostsSchema.validate(resp.data);
  return result;
};

export const updateSearchFieldBoosts = async (
  accessToken: string,
  boosts: SearchFieldBoosts,
): Promise<SearchFieldBoosts> => {
  const resp = await axiosInstance.post(
    "/search-field-boosts/",
    boosts,
    baseAxiosConfig(accessToken),
  );
  const result = await searchFieldBoostsSchema.validate(resp.data);
  return result;
};

export const searchBirthRecordsFreeformDateGroups = ["Birth Date"] as const;

export const addBirthRecordImage = async (
  accessToken: string,
  imageFile: File,
): Promise<string | APIError> => {
  const result = await addRMAMedia(accessToken, imageFile, "birth-records");
  return result;
};

export const searchDeathRecordsFreeformDateGroups = ["Death Date"] as const;

export const addDeathRecordImage = async (
  accessToken: string,
  imageFile: File,
): Promise<string | APIError> => {
  const result = await addRMAMedia(accessToken, imageFile, "death-records");
  return result;
};

export const addGoldStarPDF = async (
  accessToken: string,
  pdfFile: File,
): Promise<string | APIError> => {
  const result = await addRMAMedia(accessToken, pdfFile, "gold-star");
  return result;
};

export const searchGoldStarFreeformDateGroups = ["Death Date"] as const;

export const addVetsGraveImage = async (
  accessToken: string,
  imageFile: File,
): Promise<string | APIError> => {
  const result = await addRMAMedia(accessToken, imageFile, "vets-graves");
  return result;
};

export const searchVetsGraveFreeformDateGroups = ["Death Date"] as const;

export const addCensusImage = async (
  accessToken: string,
  imageFile: File,
): Promise<string | APIError> => {
  const result = await addRMAMedia(accessToken, imageFile, "census/pages");
  return result;
};

export const getCollectionsMedia = async (
  accessToken: string,
  hash: string,
  setProgressState?: (
    progress: number,
    elapsed: string,
    timeRemaining: string,
  ) => void,
): Promise<schemas.MediaObject> => {
  const t0 = new Date();
  let result = new Blob();
  let hasNext = true;
  let chunk = 1;
  let loaded = 0;
  let total = 0;
  let mimeType: string | undefined = undefined;
  while (hasNext) {
    const resp = await axiosInstance.get(`collections/media/${hash}`, {
      ...baseAxiosConfig(accessToken),
      params: { chunk },
      responseType: "blob",
    });
    // Axios transforms all headers to lowercase:
    mimeType = resp.headers["content-type"];
    let range: string = resp.headers["content-range"];
    let parts = range.match(/bytes [0-9]*-([0-9]*)\/([0-9]*)/);
    result = new Blob([result, resp.data], { type: mimeType });
    if (!parts) {
      throw Error("Response header Content-Range has unexpected format.");
    }
    let end = parseInt(parts[1]);
    total = parseInt(parts[2]);
    hasNext = end < total;

    loaded += parseInt(resp.headers["content-length"]);

    if (setProgressState) {
      let elapsed = Date.now() - t0.getTime();
      let progress = Math.floor((loaded / total) * 100);
      let estRemainder = (elapsed * total) / loaded - elapsed;
      setProgressState(
        progress,
        formatDurationNeatly(elapsed),
        formatDurationNeatly(estRemainder),
      );
    }

    chunk += 1;
  }
  if (!mimeType) {
    throw Error("Response header missing Content-Type.");
  }
  return { content: result, mimeType: mimeType };
};

export const deleteCollectionsItem = async (
  accessToken: string,
  id: string,
): Promise<boolean> => {
  const resp = await axiosInstance.delete(
    `/collections/${id}`,
    baseAxiosConfig(accessToken),
  );
  return resp.status === 204 ? true : false;
};

export const getCollectionsSearchConfig = async (
  accessToken: string,
): Promise<catalogueItem.CollectionsSearchConfig> => {
  const resp = await axiosInstance.get(
    "/collections/search-config/",
    baseAxiosConfig(accessToken),
  );
  const result = await catalogueItem.collectionsSearchConfig.validate(
    resp.data,
  );
  return result;
};

export const updateCollectionsSearchConfig = async (
  accessToken: string,
  inputData: catalogueItem.CollectionsSearchConfig,
) => {
  await axiosInstance.put(
    "/collections/search-config/",
    inputData,
    baseAxiosConfig(accessToken),
  );
};

export const getCollectionsImgResRuleset = async (
  accessToken: string,
): Promise<schemas.ImgResRuleset<FilterLike>> => {
  const resp = await axiosInstance.get(
    "/collections/img-res-config/",
    baseAxiosConfig(accessToken),
  );
  // This response data is not validated with yup because the resRules property
  // is an arbitrarily deep nested tree of filters which yup has dificulty with.
  return resp.data as schemas.ImgResRuleset<FilterLike>;
};

export const getCollectionsImgResRulesetHistory = async (
  accessToken: string,
  sort: SearchSortOrder = "desc",
): Promise<schemas.ImgResRuleset<FilterLike>[]> => {
  const resp = await axiosInstance.get("/collections/img-res-config/history", {
    ...baseAxiosConfig(accessToken),
    params: { sort },
  });
  return resp.data as schemas.ImgResRuleset<FilterLike>[];
};

export const updateCollectionsImgResRuleset = async (
  accessToken: string,
  newRuleset: schemas.ImgResRulesetInput,
) => {
  const resp = await axiosInstance.put(
    "/collections/img-res-config/",
    newRuleset,
    baseAxiosConfig(accessToken),
  );
  return resp.data as schemas.ImgResRuleset<FilterLike>;
};

export const getFindingAid = async (
  accessToken: string,
  uuid: string,
): Promise<findAid.FindingAid> => {
  const resp = await axiosInstance.get(
    `/finding-aids/${uuid}`,
    baseAxiosConfig(accessToken),
  );
  const findaid = await findAid.findAidSchema.validate(resp.data);
  return findaid;
};

export const updateFindingAid = async (
  accessToken: string,
  input: findAid.FindingAidInput,
): Promise<string | undefined> => {
  try {
    const resp = await axiosInstance.post(
      input.id ? `/finding-aids/${input.id}/edit` : "finding-aids/add",
      input,
      baseAxiosConfig(accessToken),
    );
    return resp.data;
  } catch (e) {
    console.error(e);
    throw Error;
  }
};

export const searchFindingAids = async ({
  accessToken,
  query,
  sorts,
  filters,
  pageNum,
  pageSize,
}: SearchQueryProps): Promise<schemas.SearchResult<findAid.FindingAid>> => {
  const searchQuery: SearchQuery = {
    query: query,
    sorts: sorts || [],
    filters: filters || [],
  };
  const resp = await axiosInstance.post("/finding-aids/", searchQuery, {
    ...baseAxiosConfig(accessToken),
    params: { pageNum, pageSize },
  });
  const result = await schemas.searchResultSchema.validate(resp.data);
  return result;
};

export const uploadFindingAid = async (
  accessToken: string,
  file: File,
): Promise<findAid.FindingAidInput | APIError> => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    const resp = await axiosInstance.post(
      "/finding-aids/upload",
      formData,
      baseAxiosConfig(accessToken),
    );
    const findaid = await findAid.findAidInputSchema.validate(resp.data);
    return findaid;
  } catch (e) {
    return handleAPIErrors(e);
  }
};

export const uploadFindingAidMedia = async (
  accessToken: string,
  mimeType: string,
  file: File,
): Promise<string | APIError> => {
  try {
    const formData = new FormData();
    formData.append("media", file);
    const resp = await axiosInstance.post(
      `/finding-aids/media?mimeType=${mimeType}`,
      formData,
      baseAxiosConfig(accessToken),
    );
    return resp.data;
  } catch (e) {
    return handleAPIErrors(e);
  }
};
