import { Button } from "@shopify/polaris";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import Stack from "../shared/Stack";

interface ZoomableImageProps {
  src: Blob;
  alt: string;
  fileName?: string | null;
}

export default function ZoomableImage(props: ZoomableImageProps) {
  const url = URL.createObjectURL(props.src);

  // If we ever need to cover an arbitrary variety of image mimetypes it would be
  // worth it to consider adding a mimetype-parsing package as a dependency. For
  // right now the mimetypes we'll need to display are pretty well known so this
  // should be fine and we can avoid adding a dependency.
  const mimeTypeMap: Record<string, string> = {
    "image/jpeg": ".jpg",
    "image/tiff": ".tiff",
    "image/png": ".png",
  };
  let ext = mimeTypeMap[props.src.type];
  let filename: string | null = null;
  // Ensure the filename's extension (if present) is overriden by the Blob's
  // mimetype:
  if (props.fileName) {
    let parts = props.fileName.split(".");
    if (parts.length > 1) {
      parts.pop();
    }
    if (ext) {
      filename = `${parts.join(".")}${ext}`;
    } else {
      console.error(
        `Unexpected mimetype ${props.src.type}. Download file could not be created`,
      );
    }
  }

  return (
    <Stack>
      <TransformWrapper>
        <TransformComponent>
          <img width="100%" src={url} alt={props.alt} />
        </TransformComponent>
      </TransformWrapper>
      <Stack direction="row" justify="flex-end" spacing={4}>
        <Button variant="plain" onClick={() => window.open(url)}>
          Open in New Tab
        </Button>
        {filename ? (
          <Button variant="plain" url={url} download={filename}>
            Download
          </Button>
        ) : null}
      </Stack>
    </Stack>
  );
}
