import { useQuery } from "react-query";

import * as api from "../api/genericEndpoints";
import { RQUERY_STALE_TIME } from "../../env";

function useRMAMedia(
  accessToken: string,
  base: api.BaseEndpoint,
  hash?: string | null
) {
  return useQuery(
    ["media", accessToken, hash],
    async () =>
      accessToken && hash ? await api.getMedia(accessToken, hash, base) : null,
    { staleTime: RQUERY_STALE_TIME }
  );
}

export const useBirthRecordImage = (
  accessToken: string,
  hash?: string | null
) => useRMAMedia(accessToken, "birth-records", hash);

export const useDeathRecordImage = (
  accessToken: string,
  hash?: string | null
) => useRMAMedia(accessToken, "death-records", hash);

export const useVetsGraveImage = (accessToken: string, hash?: string | null) =>
  useRMAMedia(accessToken, "vets-graves", hash);

export const useCensusImage = (accessToken: string, pageUuid?: string | null) =>
  useRMAMedia(accessToken, "census", pageUuid);

export const useCensusPageImage = (accessToken: string, hash?: string | null) =>
  useRMAMedia(accessToken, "census/pages", hash);

export const useGoldStarPDF = (accessToken: string, hash?: string | null) =>
  useRMAMedia(accessToken, "gold-star", hash);
