import { Divider, Spinner } from "@shopify/polaris";
import { useNavigate, useParams } from "react-router-dom";
import * as env from "../../env";
import Card from "../../shared/Card";
import Stack from "../../shared/Stack";
import { Heading, Subheading } from "../../shared/TextComponents";
import { hasPermissions } from "../auth/authutils";
import BreadcrumbPage from "../BreadcrumbPage";
import {
  BaseDetailCompProps,
  DetailFields,
  DetailSection,
  ListFieldStack,
} from "../Detail";
import { useVetsGraveImage } from "../hooks/mediaHooks";
import { useVetsGrave, useVetsGraveEvents } from "../hooks/vetsGraveHooks";
import EventHistory from "../journal/EventHistory";
import { vetsGraveLabels, VetsGraveRecord } from "../schemas/vetsGrave";
import Skeleton from "../Skeleton";
import { DefaultPageProps } from "../utils/shared";
import ZoomableImage from "../ZoomableImage";
import CommentSection from "./CommentSection";

export function VetsGraveDetail(props: BaseDetailCompProps<VetsGraveRecord>) {
  const vetsGraveRecord = props.record;

  const { data: imageFront, isLoading: frontLoading } = useVetsGraveImage(
    props.currentUserData.accessToken,
    vetsGraveRecord?.imageFrontSHA256,
  );

  const skeleton = !vetsGraveRecord && <Skeleton lines={14} />;

  const frontSpinner = frontLoading && !imageFront && <Spinner size="large" />;

  const front = !frontLoading && imageFront && (
    <>
      <Heading>Front</Heading>
      <ZoomableImage
        src={imageFront}
        alt="Front of veterans grave form."
        fileName={vetsGraveRecord?.certificateId?.toString()}
      />
    </>
  );

  const showEditBtn = hasPermissions(
    props.currentUserData,
    "add_edit_remove_vets_grave_records",
  );

  const heading = vetsGraveRecord && (
    <Stack spacing={1}>
      <Heading>
        {vetsGraveRecord.firstName} {vetsGraveRecord.middleName}{" "}
        {vetsGraveRecord.familyName}
      </Heading>
      <Subheading>{vetsGraveRecord.id}</Subheading>
    </Stack>
  );

  const detail = vetsGraveRecord && (
    <Stack>
      {frontSpinner || front}
      <Divider />
      <DetailFields
        record={vetsGraveRecord}
        spec={{
          certificateId: null,
          prefix: null,
          firstName: null,
          middleName: null,
          familyName: null,
          suffix: null,
          deathDateStart: { dateType: "date" },
          deathDateEnd: { dateType: "date" },
          burialLocation: null,
        }}
        labels={vetsGraveLabels}
      >
        <ListFieldStack
          label={vetsGraveLabels["militaryServices"]}
          elements={vetsGraveRecord.militaryServices || []}
        />
        <ListFieldStack
          label={vetsGraveLabels["alternateNames"]}
          elements={vetsGraveRecord.alternateNames || []}
        />
      </DetailFields>
    </Stack>
  );

  return (
    <DetailSection
      onEditBtnClick={showEditBtn ? props.onEditBtnClick : undefined}
    >
      {heading}
      <EventHistory
        currentUserData={props.currentUserData}
        labels={vetsGraveLabels}
        spec={{
          deathDateStart: { dateType: "date" },
          deathDateEnd: { dateType: "date" },
        }}
        viewJournalPerm="view_vets_graves_record_journal"
        eventHistory={props.eventHistory}
      >
        <>{skeleton || detail}</>
      </EventHistory>
    </DetailSection>
  );
}

export function VetsGraveDetailPage(props: DefaultPageProps) {
  const { uuid } = useParams();
  const navigate = useNavigate();

  const { data: vetsGraveRecord } = useVetsGrave(
    props.currentUserData.accessToken,
    uuid,
  );
  const { data: vetsGraveEvents } = useVetsGraveEvents(
    props.currentUserData.accessToken,
    // This sneaks a conditional call into a react hook, which is necessary since
    // a user that can see a record might not be able to see the journal
    // and they'll receive a 403 when trying to call this function.
    // TODO: This maybe suggests something wrong with this permission structure?
    hasPermissions(props.currentUserData, "view_vets_graves_record_journal")
      ? uuid
      : undefined,
  );
  const canSeeComments =
    hasPermissions(props.currentUserData, "view_public_comments") ||
    hasPermissions(props.currentUserData, "view_unpublished_comments");

  return (
    <BreadcrumbPage breadcrumbAction="up" breadcrumbs="both">
      <Stack>
        <Card>
          <VetsGraveDetail
            currentUserData={props.currentUserData}
            record={vetsGraveRecord}
            onEditBtnClick={() => navigate("./edit")}
            eventHistory={vetsGraveEvents}
          />
        </Card>
        {uuid && env.FEATURE_COMMENTS && canSeeComments && (
          <CommentSection
            currentUserData={props.currentUserData}
            recordId={uuid}
            dataset="vetsgrave"
            defaults={{
              userName: "People Records Admin",
              email: "peoplesearch@mnhs.org",
            }}
          />
        )}
      </Stack>
    </BreadcrumbPage>
  );
}
