import { Card as PolarisCard } from "@shopify/polaris";
import React from "react";

interface CardProps {
  innerCard?: boolean;
  children?: React.ReactNode;
}

export default function Card({ innerCard = false, children }: CardProps) {
  const padding = innerCard ? "500" : "1000";
  return <PolarisCard padding={padding}>{children}</PolarisCard>;
}
