import { ReactElement } from "react";
import { v4 as uuid4 } from "uuid";
import * as yup from "yup";
import { removeNulls } from "../utils/shared";
import { genFilterLabel } from "./FilterComponents";

// Search sorting utils:
export type SearchSortOrder = "asc" | "desc";

export type SearchSortParams<T extends object> = {
  [P in keyof T]?: SearchSort;
};

export type SearchSortFields<T extends object> = {
  [P in keyof T]?: { label: string };
};

/**
 * Convenience method for turning a SearchSortParams into an array of its
 * key-value pairs separated by a : for passing as axios params to the API.
 * @param params
 * @returns An array of strings constructed from the properties of the
 * SearchSortParams object.
 */
export const parseSearchSortParams = (params?: SearchSortParams<any>) =>
  params ? Object.entries(params).map(([k, v]) => `${k}:${v}`) : [];

// Search Filtering utils:
// Filter operators allowed by the API:
export const FilterOperators = [
  "=",
  ">",
  ">=",
  "<",
  "<=",
  "*",
  "!=",
  "$",
] as const;

export type SearchFilterOp = (typeof FilterOperators)[number];

// Filter operators used by the client for display:
export const FilterParamOperators = [
  "=",
  ">",
  ">=",
  "<",
  "<=",
  "!=",
  // String operators:
  "^", // Starts with
  "$", // Ends with
  "*", // Contains
  "~", // Soundslike
  // Nullability operators:
  "isnull",
  "notnull",
] as const;

export type FilterParamOperator = (typeof FilterParamOperators)[number];

export type SearchFilter = {
  op: SearchFilterOp;
  field: string;
  term: string;
};

export const FilterConjuncts = ["and", "or", "not"] as const;

export type FilterConjunct = (typeof FilterConjuncts)[number];

export type SearchFilterConjunct = {
  conjunction: FilterConjunct;
  filters: (SearchFilter | SearchFilterConjunct)[];
};

export type SearchFilterParam = {
  uuid: string;
  op: FilterParamOperator;
  field: string;
  term: string;
  label: string;
};

export type SearchFilterConjunctParam = {
  uuid: string;
  conjunction: FilterConjunct;
  filters: (SearchFilterParam | SearchFilterConjunctParam)[];
};

export type ParamFilterLike = SearchFilterParam | SearchFilterConjunctParam;

export type FilterLike = SearchFilter | SearchFilterConjunct;

export type AnyFilterLike =
  | (SearchFilterParam | SearchFilterConjunctParam)
  | (SearchFilter | SearchFilterConjunct);

export type SearchSort = {
  field: string;
  direction: SearchSortOrder;
};

export type SearchQuery = {
  query: string;
  sorts: SearchSort[];
  filters: (SearchFilter | SearchFilterConjunct)[];
};

export type FilterFieldType = "date" | "number" | "string" | "boolean";

/**
 * Defines the specifications of each available filter field to be displayed to
 * the user through the FilterParamPopover component.
 */
export type SearchFilterFields = {
  [key: string]: {
    label: string;
    type: FilterFieldType;
    validTerms?: any[];
    defaultOperator?: FilterParamOperator;
    overrideArrayLabeling?: boolean;
    allowNullOps?: boolean;
  };
};

export const numericFilterOperatorLabels = {
  "=": "equals",
  ">": "greater than",
  ">=": "greater than or equal to",
  "<": "less than",
  "<=": "less than or equal to",
  "!=": "does not equal",
};

const baseFilterParamOpLabels = {
  "=": "is",
  ">": ">",
  ">=": ">=",
  "<": "<",
  "<=": "<=",
  "!=": "is not",
  "^": "starts with",
  $: "ends with",
  "*": "contains",
  "~": "soundslike",
  isnull: "is",
  notnull: "is not",
};

export const nullableFilterOperatorOptions = [
  { label: "is null", value: "isnull" },
  { label: "is not null", value: "notnull" },
];

export const numericFilterOperatorOptions = [
  { label: numericFilterOperatorLabels["="], value: "=" },
  { label: numericFilterOperatorLabels[">"], value: ">" },
  { label: numericFilterOperatorLabels[">="], value: ">=" },
  { label: numericFilterOperatorLabels["<"], value: "<" },
  { label: numericFilterOperatorLabels["<="], value: "<=" },
  { label: numericFilterOperatorLabels["!="], value: "!=" },
];

export const dateFilterOperatorLabels = {
  "=": "is exactly",
  ">": "after",
  ">=": "on or after",
  "<": "before",
  "<=": "on or before",
  "!=": "is not",
};

export const dateFilterOperatorOptions = [
  { label: dateFilterOperatorLabels["="], value: "=" },
  { label: dateFilterOperatorLabels[">"], value: ">" },
  { label: dateFilterOperatorLabels[">="], value: ">=" },
  { label: dateFilterOperatorLabels["<"], value: "<" },
  { label: dateFilterOperatorLabels["<="], value: "<=" },
  { label: dateFilterOperatorLabels["!="], value: "!=" },
];

export const stringFilterOperatorLabels = {
  "=": "is exactly",
  "^": "starts with",
  "*": "contains",
  $: "ends with",
  "~": "sounds like",
};

export const stringFilterOperatorOptions = [
  { label: stringFilterOperatorLabels["="], value: "=" },
  { label: stringFilterOperatorLabels["^"], value: "^" },
  { label: stringFilterOperatorLabels["*"], value: "*" },
  { label: stringFilterOperatorLabels["$"], value: "$" },
  { label: stringFilterOperatorLabels["~"], value: "~" },
];

export const booleanFilterOperatorLabels = {
  "=": "is",
  "!=": "is not",
};

export const booleanFilterOperatorOptions = [
  { label: booleanFilterOperatorLabels["="], value: "=" },
  { label: booleanFilterOperatorLabels["!="], value: "!=" },
];

export const operatorOptionsMap = {
  string: stringFilterOperatorOptions,
  number: numericFilterOperatorOptions,
  date: dateFilterOperatorOptions,
  boolean: booleanFilterOperatorOptions,
};

/**
 * Retrieves the appropriate operator options for a Select component based on
 * the selectedField's configuration in the passed filterFields. If, for some
 * reason, the selectedField's type value is not found in the operatorOptionsMap,
 * it will fall back to the "number" options.
 * @param selectedField
 * @param filterFields The SearchFilterFields config to pull from.
 * @returns An array of option objects suitable for passing to a Polaris Select
 * component.
 */
export function getOperatorOptionsForFieldAndType(
  selectedField: string,
  filterFields: SearchFilterFields,
): { label: string; value: string }[] {
  let selectedFieldConfig = filterFields[selectedField] || {
    type: "number",
    label: "Fallback",
  };
  let result = [...operatorOptionsMap[selectedFieldConfig.type]];
  if (selectedFieldConfig.allowNullOps) {
    result = [...result, ...nullableFilterOperatorOptions];
  }
  return result;
}

export function getOperatorLabelByType(
  op: FilterParamOperator,
  fieldType: FilterFieldType | null,
) {
  if (fieldType) {
    const operatorLabelsMap = {
      date: { ...baseFilterParamOpLabels, ...dateFilterOperatorLabels },
      boolean: { ...baseFilterParamOpLabels, ...booleanFilterOperatorLabels },
      number: { ...baseFilterParamOpLabels, ...numericFilterOperatorLabels },
      string: { ...baseFilterParamOpLabels, ...stringFilterOperatorLabels },
    };
    return operatorLabelsMap[fieldType][op];
  }
}

export function parseDatePartsToFilterDate(
  operator: FilterParamOperator,
  year: number,
  month?: number,
  day?: number,
) {
  const padNumericString = (value?: number, fallback?: string) => {
    var result = value ? value.toString() : fallback || "01";
    return result.length < 2 ? "0" + result : result;
  };

  /**
   * Depending on the operator, use the start (1) or end (0) of the month/year
   * for null day/month values.
   * @param t The day or month as represented by a number.
   * @returns The passed number, or 0/1 if it was null.
   */
  const handleNullDayMonth = (t?: number) => {
    var result: number;
    if (!t) {
      result = ["<", "<="].includes(operator) ? 0 : 1;
    } else {
      result = t;
    }
    return result;
  };

  var m = handleNullDayMonth(month);
  var d = handleNullDayMonth(day);
  // Date's interpretation of a month value depends on whether day value was
  // 0 or not, because if it was 0 then it can't interpret the month as
  // 0-based:
  if (d === 0) {
    m = m > 0 ? m : 12;
  } else {
    m = m > 0 ? m - 1 : 11;
  }
  var constDate = new Date(year, m, d);
  return [
    year.toString(),
    // getMonth is always 0-based regardless of whether day was 0 on input:
    padNumericString(constDate.getMonth() + 1),
    padNumericString(constDate.getDate()),
  ].join("-");
}

/**
 * Convenience method for converting a datestring in the format YYYY-MM-DD into
 * its component parts.
 * @param datestr String in the format YYYY-MM-DD
 * @returns An array of 3 numbers, YYYY, MM, DD, or an array of 3 undefineds if
 * the passed string is not in the proper format or is undefined.
 */
export function parseDateStrToParts(datestr: string | undefined) {
  const fallback = [undefined, undefined, undefined];
  if (!datestr) {
    return fallback;
  }
  let parts = datestr.match(/(\d{4})-(\d{2})-(\d{2})/);
  if (parts) {
    return [Number(parts[1]), Number(parts[2]), Number(parts[3])];
  } else {
    return fallback;
  }
}

export function parseSearchFilter(
  filter: SearchFilter,
  filterFields: SearchFilterFields,
): SearchFilterParam;
export function parseSearchFilter(
  filter: SearchFilterConjunct,
  filterFields: SearchFilterFields,
): SearchFilterConjunctParam;
export function parseSearchFilter(
  filter: SearchFilter | SearchFilterConjunct,
  filterFields: SearchFilterFields,
): SearchFilterParam | SearchFilterConjunctParam;
/**
 * Converts a SearchFilter or SearchFilterConjunct from the API into a
 * SearchFilterParam or SearchFilterConjunctParam (as appropriate).
 * @param filter The SearchFilter or SearchFilterConjunct from the API to convert.
 * @param filterFields The SearchFilterFields to use in the conversion.
 * @returns The resulting SearchFilterParam or SearchFilterConjunctParam.
 */
export function parseSearchFilter(
  filter: SearchFilter | SearchFilterConjunct,
  filterFields: SearchFilterFields,
): SearchFilterParam | SearchFilterConjunctParam {
  if ("filters" in filter) {
    return {
      uuid: uuid4(),
      conjunction: filter.conjunction,
      filters: filter.filters.map((f) => parseSearchFilter(f, filterFields)),
    };
  } else {
    const label = genFilterLabel(
      filter.op,
      filter.field,
      filterFields,
      filter.term,
    );
    return {
      uuid: uuid4(),
      field: filter.field,
      op: filter.op,
      term: filter.term,
      label: label,
    };
  }
}

/**
 * Converts a SearchFilterParam into a SearchFilter for sending to the API.
 * @param filterParam The SearchFilterParam to convert.
 * @returns The API-ready SearchFilter.
 */
export const parseSearchFilterParam = (filterParam: SearchFilterParam) => {
  let start = "";
  let end = "";
  const check = FilterOperators.find((op) => op === filterParam.op);
  let op: SearchFilterOp = check ? check : "=";

  if (["$", "*"].includes(filterParam.op)) {
    if (!filterParam.term.match(/^\.\*.*$/)) {
      start = `.*`;
    }
    op = "*";
  }
  if (["^", "*"].includes(filterParam.op)) {
    if (!filterParam.term.match(/.*\.\*$/)) {
      end = `.*`;
    }
    op = "*";
  }
  if (filterParam.op === "~") {
    op = "$";
  }
  return {
    op: op,
    field: filterParam.field,
    term: `${start}${filterParam.term}${end}`,
  };
};

/**
 * Convenience method for decoding an array of SearchFilterParams and/or
 * SearchFilterConjunctParams into an array of SearchFilters and/or
 * SearchFilterConjuncts for passing to the API.
 * @param filterParams
 * @returns An array of SearchFilters and/or SearchFilterConjuncts.
 */
export const parseSearchParams = (
  filterParams?: (SearchFilterParam | SearchFilterConjunctParam)[],
): (SearchFilter | SearchFilterConjunct)[] => {
  return filterParams
    ? filterParams.map((f) => {
        if ("conjunction" in f) {
          return { ...f, filters: parseSearchParams(f.filters) };
        } else {
          return parseSearchFilterParam(f);
        }
      })
    : [];
};

// Search Freeform date handling utils:
export type FreeformDateParams = {
  day: string | null;
  month: string | null;
  year: string | null;
};

export const emptyFreeformDates = {
  startDay: undefined,
  endDay: undefined,
  startMonth: undefined,
  endMonth: undefined,
  startYear: undefined,
  endYear: undefined,
};

export type FreeformDates = {
  startDay: number | undefined;
  endDay: number | undefined;
  startMonth: number | undefined;
  endMonth: number | undefined;
  startYear: number | undefined;
  endYear: number | undefined;
};

export type FreeformDateGroupSpec = {
  groupName: string;
  startDayName: string;
  endDayName: string;
  startMonthName: string;
  endMonthName: string;
  startYearName: string;
  endYearName: string;
  startDateName: string;
  endDateName: string;
};

export type FreeformDateParamsByGroup = {
  [group: string]: FreeformDateParams;
};

export type FreeformDatesByGroup = {
  [group: string]: FreeformDates;
};

export function assembleFreeformDateParams(ranges: FreeformDates) {
  let result: FreeformDateParams = {
    day: null,
    month: null,
    year: null,
  };

  const createDatepartString = (
    start: number | undefined,
    end: number | undefined,
  ) => {
    let result = start?.toString();
    if (end) {
      result = result ? `${result}:` : "";
      result += end.toString();
    }
    return result;
  };

  let year = createDatepartString(ranges.startYear, ranges.endYear);
  let month = createDatepartString(ranges.startMonth, ranges.endMonth);
  let day = createDatepartString(ranges.startDay, ranges.endDay);

  result.year = year ? year : null;
  result.month = month ? month : null;
  result.day = day ? day : null;

  return result;
}

export function assembleFreeformDateFilters(
  ranges: FreeformDates,
  spec: FreeformDateGroupSpec,
): SearchFilterConjunct | null {
  const parseDatePartRange = (
    start: number | Date | undefined,
    end: number | Date | undefined,
    startName: string,
    endName: string,
  ): SearchFilter[][] => {
    if (end && !start) {
      start = end;
    } else if (start && !end) {
      end = start;
    }

    if (start && end) {
      return [
        [
          { op: ">=", field: startName, term: start.toString() },
          { op: "<=", field: startName, term: end.toString() },
        ],
        [
          { op: ">=", field: endName, term: start.toString() },
          { op: "<=", field: endName, term: end.toString() },
        ],
      ];
    }
    return [[], []];
  };

  let startFilters: SearchFilter[] = [];
  let endFilters: SearchFilter[] = [];

  if (
    (ranges.startDay && ranges.startMonth && ranges.startYear) ||
    (ranges.endDay && ranges.endMonth && ranges.endYear)
  ) {
    if (ranges.startDay && ranges.startMonth && ranges.startYear) {
      const startOp = ">=";
      const startDateTerm = parseDatePartsToFilterDate(
        startOp,
        ranges.startYear,
        ranges.startMonth,
        ranges.startDay,
      );
      startFilters.push({
        op: startOp,
        field: spec.startDateName,
        term: startDateTerm,
      });
      endFilters.push({
        op: startOp,
        field: spec.endDateName,
        term: startDateTerm,
      });
    }
    if (ranges.endDay && ranges.endMonth && ranges.endYear) {
      const endOp = "<=";
      const endDateTerm = parseDatePartsToFilterDate(
        endOp,
        ranges.endYear,
        ranges.endMonth,
        ranges.endDay,
      );
      startFilters.push({
        op: endOp,
        field: spec.startDateName,
        term: endDateTerm,
      });
      endFilters.push({
        op: endOp,
        field: spec.endDateName,
        term: endDateTerm,
      });
    }
  } else {
    const [startDayFilters, endDayFilters] = parseDatePartRange(
      ranges.startDay,
      ranges.endDay,
      spec.startDayName,
      spec.endDayName,
    );
    const [startMonthFilters, endMonthFilters] = parseDatePartRange(
      ranges.startMonth,
      ranges.endMonth,
      spec.startMonthName,
      spec.endMonthName,
    );
    const [startYearFilters, endYearFilters] = parseDatePartRange(
      ranges.startYear,
      ranges.endYear,
      spec.startYearName,
      spec.endYearName,
    );

    startFilters = [
      ...startDayFilters,
      ...startMonthFilters,
      ...startYearFilters,
    ];
    endFilters = [...endDayFilters, ...endMonthFilters, ...endYearFilters];
  }

  return startFilters.length > 0 || endFilters.length > 0
    ? {
        conjunction: "or",
        filters: removeNulls([
          startFilters.length > 0
            ? { conjunction: "and", filters: startFilters }
            : null,
          endFilters.length > 0
            ? { conjunction: "and", filters: endFilters }
            : null,
        ]),
      }
    : null;
}

/**
 * Searches the passed paramTree for a SearchFilterParam or SearchFilterConjunctParam
 * with a uuid matching the passed uuid and replaces it with the passed newParam.
 * Does not change the passed paramTree in place, but rather returns a new array
 * with the matching element changed. If no element matches the passed uuid then
 * the paramTree will be returned unchanged.
 * @param newParam The param to replace the matching param with.
 * @param paramTree The array of params to search.
 * @param uuid A uuid to remove from the tree. Must be passed along with null
 * to perform a delete.
 * @returns The updated copy of the passed paramTree.
 */
export function updateSearchFilterParamTree(
  newParam: SearchFilterParam | SearchFilterConjunctParam | null,
  paramTree: (SearchFilterParam | SearchFilterConjunctParam)[],
  uuid?: string,
) {
  let result: (SearchFilterParam | SearchFilterConjunctParam)[] = [];
  uuid = newParam ? newParam.uuid : uuid;
  if (!uuid) {
    throw Error("Must pass uuid if newParam is null.");
  }
  for (let i = 0; i < paramTree.length; i++) {
    let param: SearchFilterParam | SearchFilterConjunctParam | null = {
      ...paramTree[i],
    };
    if (param.uuid === uuid) {
      param = newParam;
    } else if ("filters" in param) {
      let subResult = updateSearchFilterParamTree(
        newParam,
        param.filters,
        uuid,
      );
      param = { ...param, filters: subResult };
    }
    if (param !== null) {
      result.push(param);
    }
  }
  return result;
}

export interface searchParamPopoverProps {
  activator: ReactElement;
  active: boolean;
  onClose: () => void;
}

/**
 * All api search endpoints are expected to take these arguments.
 */
export interface SearchQueryProps {
  accessToken: string;
  query: string;
  sorts?: SearchSort[];
  filters?: (SearchFilter | SearchFilterConjunct)[];
  pageNum: number;
  pageSize: number;
}

export type SearchFieldBoostsFields = Record<string, number>;

export type SearchFieldBoosts = {
  dataset: string;
  fields: SearchFieldBoostsFields;
};

export const searchFieldBoostsFieldsSchema = yup.lazy((obj) => {
  return yup
    .object()
    .shape(
      Object.keys(obj).reduce(
        (prev, key) => ({ ...prev, [key]: yup.number().min(0).required() }),
        {},
      ),
    );
});

export const searchFieldBoostsSchema = yup.object({
  dataset: yup.string().required(),
  fields: searchFieldBoostsFieldsSchema,
});
