import { Divider, Spinner } from "@shopify/polaris";
import { useNavigate, useParams } from "react-router-dom";
import * as env from "../../env";
import Card from "../../shared/Card";
import Stack from "../../shared/Stack";
import { Heading, Subheading } from "../../shared/TextComponents";
import { hasPermissions } from "../auth/authutils";
import BreadcrumbPage from "../BreadcrumbPage";
import {
  BaseDetailCompProps,
  DetailFields,
  DetailSection,
  ListFieldStack,
} from "../Detail";
import { useGoldStar, useGoldStarEvents } from "../hooks/goldStarHooks";
import { useGoldStarPDF } from "../hooks/mediaHooks";
import EventHistory from "../journal/EventHistory";
import { goldStarLabels, GoldStarRecord } from "../schemas/goldStar";
import Skeleton from "../Skeleton";
import { DefaultPageProps } from "../utils/shared";
import ZoomablePDF from "../ZoomablePDF";
import CommentSection from "./CommentSection";

export default function GoldStarRecordDetail(
  props: BaseDetailCompProps<GoldStarRecord>,
) {
  const goldStarRecord = props.record;

  const { data: pdfContent, isLoading: pdfLoading } = useGoldStarPDF(
    props.currentUserData.accessToken,
    goldStarRecord?.pdfSHA256,
  );

  const pdfSpinner = pdfLoading && !pdfContent && <Spinner size="large" />;

  const pdf = !pdfLoading && pdfContent && (
    <Stack>
      <Subheading>Associated PDF</Subheading>
      <ZoomablePDF
        src={pdfContent}
        fileName={goldStarRecord?.certificateId?.toString()}
      />
    </Stack>
  );

  const skeleton = !goldStarRecord && <Skeleton lines={15} />;

  const showEditBtn = hasPermissions(
    props.currentUserData,
    "add_edit_remove_gold_star_roll_records",
  );

  const heading = goldStarRecord && (
    <Stack spacing={1}>
      <Heading>
        {goldStarRecord.firstName} {goldStarRecord.middleName}{" "}
        {goldStarRecord.familyName}
      </Heading>
      <Subheading>{goldStarRecord.id}</Subheading>
    </Stack>
  );

  const detail = goldStarRecord && (
    <Stack>
      {pdfSpinner || pdf}
      <Divider />
      <DetailFields
        record={goldStarRecord}
        spec={{
          certificateId: null,
          prefix: null,
          firstName: null,
          middleName: null,
          familyName: null,
          suffix: null,
          relatedName: null,
          deathDateStart: { dateType: "date" },
          deathDateEnd: { dateType: "date" },
          birthPlace: null,
          birthDate: null,
          residencePriorToService: null,
          notes: null,
        }}
        labels={goldStarLabels}
      >
        <ListFieldStack
          label={goldStarLabels["alternateNames"]}
          elements={goldStarRecord.alternateNames || []}
        />
      </DetailFields>
    </Stack>
  );

  return (
    <DetailSection
      onEditBtnClick={showEditBtn ? props.onEditBtnClick : undefined}
    >
      {heading}
      <EventHistory
        currentUserData={props.currentUserData}
        labels={goldStarLabels}
        spec={{
          deathDateStart: { dateType: "date" },
          deathDateEnd: { dateType: "date" },
        }}
        viewJournalPerm="view_gold_star_roll_record_journal"
        eventHistory={props.eventHistory}
      >
        <>{skeleton || detail}</>
      </EventHistory>
    </DetailSection>
  );
}

export function GoldStarDetailPage(props: DefaultPageProps) {
  const { uuid } = useParams();
  const navigate = useNavigate();

  const { data: goldStarRecord } = useGoldStar(
    props.currentUserData.accessToken,
    uuid,
  );
  const { data: goldStarEvents } = useGoldStarEvents(
    props.currentUserData.accessToken,
    // This sneaks a conditional call into a react hook, which is necessary since
    // a user that can see a record might not be able to see the journal
    // and they'll receive a 403 when trying to call this function.
    // TODO: This maybe suggests something wrong with this permission structure?
    hasPermissions(props.currentUserData, "view_gold_star_roll_record_journal")
      ? uuid
      : undefined,
  );

  const canSeeComments =
    hasPermissions(props.currentUserData, "view_public_comments") ||
    hasPermissions(props.currentUserData, "view_unpublished_comments");

  return (
    <BreadcrumbPage breadcrumbAction="up" breadcrumbs="both">
      <Stack>
        <Card>
          <GoldStarRecordDetail
            currentUserData={props.currentUserData}
            record={goldStarRecord}
            onEditBtnClick={() => navigate("./edit")}
            eventHistory={goldStarEvents}
          />
        </Card>
        {uuid && env.FEATURE_COMMENTS && canSeeComments && (
          <CommentSection
            currentUserData={props.currentUserData}
            recordId={uuid}
            dataset="goldstar"
            defaults={{
              userName: "People Records Admin",
              email: "peoplesearch@mnhs.org",
            }}
          />
        )}
      </Stack>
    </BreadcrumbPage>
  );
}
