import { Select, ChoiceList, Popover, Button } from "@shopify/polaris";
import { useState } from "react";

import * as searchutils from "./searchutils";
import Stack from "../../shared/Stack";

interface sortParamPopoverProps<T extends object>
  extends searchutils.searchParamPopoverProps {
  sortFields: searchutils.SearchSortFields<T>;
  onSubmit: (field: keyof T, order: searchutils.SearchSortOrder) => void;
}

export default function SortParamPopover<T extends object>(
  props: sortParamPopoverProps<T>
) {
  const [selectedField, setSelectedField] = useState<string>("");
  const [sortOrder, setSortOrder] =
    useState<searchutils.SearchSortOrder>("desc");

  const close = () => {
    setSelectedField("");
    setSortOrder("desc");
    props.onClose();
  };

  const submit = () => {
    props.onSubmit(selectedField as keyof T, sortOrder);
    close();
  };

  const selector = (
    <Select
      label="Available sort fields"
      placeholder="select field"
      value={selectedField}
      options={Object.entries(props.sortFields).map(([k, v]) => {
        // Object.entries().map causes strict typing to lose track of the typing
        // on the object passed to it, so we have to cast v here:
        return { key: k, label: (v as { label: string }).label, value: k };
      })}
      onChange={(selected) => setSelectedField(selected)}
    />
  );

  const choices = (
    <ChoiceList
      title="Order"
      choices={[
        { label: "Descending", value: "desc" },
        { label: "Ascending", value: "asc" },
      ]}
      selected={[sortOrder]}
      onChange={(selected) =>
        setSortOrder(selected[0] as searchutils.SearchSortOrder)
      }
    />
  );

  return (
    <>
      <Popover
        active={props.active}
        activator={props.activator}
        onClose={close}
        preferredAlignment="left"
        fullHeight
      >
        <Popover.Section>
          <Stack>
            {selector}
            {choices}
          </Stack>
        </Popover.Section>
        <Popover.Section>
          <Button onClick={submit}>Add</Button>
        </Popover.Section>
      </Popover>
    </>
  );
}
