// import { useNavigate } from "react-router-dom";
import { Button, Spinner, Thumbnail, Tooltip } from "@shopify/polaris";
import { StatusActiveIcon, XCircleIcon } from "@shopify/polaris-icons";
import { ReactNode } from "react";
import mnhs_icon from "../../assets/mnhs_icon.png";
import Stack from "../../shared/Stack";
import { PText } from "../../shared/TextComponents";
import { CmtAccessLevel, Comment, CommentInput } from "../schemas/comment";
import { renderValueAsString } from "../utils/shared";

interface CommentDetailProps {
  comment: Comment;
  onEditBtnClick?: () => void;
  onSubmitEdit?: (input: CommentInput) => void;
  editIsLoading?: boolean;
  additionalActions?: ReactNode;
}

export default function CommentDetail({
  comment,
  onEditBtnClick,
  onSubmitEdit,
  editIsLoading,
  additionalActions,
}: CommentDetailProps) {
  //   const navigate = useNavigate();

  const isPublic = comment.accessLevel === "public";
  const statusText = isPublic ? "public" : "hidden";

  const updateStatus = (status: CmtAccessLevel) => {
    if (onSubmitEdit) {
      const cmtInput: CommentInput = { ...comment, accessLevel: status };
      onSubmitEdit(cmtInput);
    }
  };

  const clickToMsg = onSubmitEdit
    ? ` Click to ${isPublic ? "hide" : "publish"}.`
    : "";

  // If comments get more than the two accessLevels will need to rework
  // this button entirely.
  const pubStatusBtn = (
    <Tooltip content={`Currently ${statusText}.${clickToMsg}`}>
      {editIsLoading ? (
        <Spinner size="small" />
      ) : (
        <Button
          variant="plain"
          icon={isPublic ? StatusActiveIcon : XCircleIcon}
          tone={isPublic ? "success" : undefined}
          onClick={() =>
            updateStatus(
              comment.accessLevel === "public" ? "unpublished" : "public",
            )
          }
        />
      )}
    </Tooltip>
  );

  return (
    <Stack>
      <Stack direction="row" justify="space-between">
        <Stack direction="row" align="center">
          <PText>{`${comment.userName} (${comment.email}) ${`\u25CF`} ${renderValueAsString(comment.commentDt)}`}</PText>
          {comment.isStaffComment && (
            <Tooltip content="Indicates this is a staff comment">
              <Thumbnail size="extraSmall" source={mnhs_icon} alt="" />
            </Tooltip>
          )}
        </Stack>
        {pubStatusBtn}
      </Stack>
      <PText>{comment.content}</PText>
      <Stack direction="row" justify="flex-end">
        {onSubmitEdit && (
          <Button disabled={editIsLoading} onClick={onEditBtnClick}>
            Edit Comment
          </Button>
        )}
        {additionalActions}
      </Stack>
    </Stack>
  );
}
