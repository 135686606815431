import { yupResolver } from "@hookform/resolvers/yup";
import { ChoiceList, Layout, Page } from "@shopify/polaris";
import { useEffect, useState } from "react";
import { Controller, FieldError, useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import * as api from "../api/endpoints";
import BreadcrumbPage from "../BreadcrumbPage";
import {
  AccumulativeTextField,
  BaseFormCompProps,
  ControlledCountyFormField,
  FormSection,
  FormFields,
} from "../Form";
import {
  useDeathRecord,
  useDeleteDeathRecord,
  useUpdateDeathRecord,
} from "../hooks/deathRecordHooks";
import { useDeathRecordImage } from "../hooks/mediaHooks";
import { CurrentUserData } from "../schemas/core";
import { deathRecordLabels } from "../schemas/deathRecord";
import { DeathRecordInput } from "../schemas/deathRecord";
import { deathRecordInputSchema } from "../schemas/deathRecord";
import { DeathRecord } from "../schemas/deathRecord";
import Skeleton from "../Skeleton";
import ImageDropSuite from "../utils/ImageDropSuite";
import { Subheading } from "../../shared/TextComponents";
import Card from "../../shared/Card";

export default function DeathRecordForm(props: BaseFormCompProps<DeathRecord>) {
  const [imageLoading, setImageLoading] = useState(false);
  const [front, setFront] = useState<string | null>();

  const deathRecord = props.record;

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    getValues,
    register,
  } = useForm<DeathRecordInput>({
    resolver: yupResolver(deathRecordInputSchema),
    // defaultValues: deathRecordInputSchema.cast(deathRecord),
  });

  useEffect(() => {
    if (!deathRecord) {
      return;
    }
    setFront(deathRecord.imageFrontSHA256);
    reset(deathRecordInputSchema.cast(deathRecord));
  }, [setFront, deathRecord, reset]);

  const { data: imageFront } = useDeathRecordImage(
    props.currentUserData.accessToken,
    front
  );

  const onSubmit = (inputData: DeathRecordInput) => {
    inputData.imageFrontSHA256 = front || undefined;
  };

  return (
    <FormSection
      currentUserData={props.currentUserData}
      handleSubmit={handleSubmit}
      inputSchema={deathRecordInputSchema}
      initialValues={deathRecord}
      mutateHook={useUpdateDeathRecord}
      deleteHook={useDeleteDeathRecord}
      onSubmit={onSubmit}
      onMutateSuccess={props.onSubmitSuccess}
      onDeleteSuccess={props.onDeleteSuccess}
    >
      <ImageDropSuite
        currentUserData={props.currentUserData}
        addImageEndpoint={api.addDeathRecordImage}
        front={{
          src: imageFront,
          hash: front,
          setHash: setFront,
          loading: imageLoading,
          setLoading: setImageLoading,
        }}
      />
      <FormFields
        control={control}
        spec={{
          certificateId: { type: "text", required: true },
          birthDate: { type: "text" },
          birthPlace: { type: "text" },
          firstName: { type: "text" },
          middleName: { type: "text" },
          familyName: { type: "text", required: true },
          suffix: { type: "text" },
          mothersMaidenName: { type: "text" },
          deathDateStart: { type: "date", required: true },
          deathDateEnd: { type: "date" },
        }}
        labels={deathRecordLabels}
        errors={{ certificateId: errors.certificateId }}
        reset={reset}
        getValues={getValues}
      />
      <ControlledCountyFormField
        control={control}
        error={errors.county}
        label={deathRecordLabels["county"]}
        required={true}
      />
      <Subheading>Alternate Names</Subheading>
      <AccumulativeTextField
        fieldName={"alternateNames"}
        control={control}
        register={register}
        errors={errors.alternateNames as FieldError}
      />
      <Controller
        name="confidentiality"
        control={control}
        defaultValue="confidential"
        render={({ field }) => (
          <ChoiceList
            title={"Confidentiality"}
            choices={[
              { label: "Confidential", value: "confidential" },
              { label: "Public", value: "public" },
              { label: "Unpublished", value: "unpublished" },
            ]}
            selected={[field.value]}
            onChange={(selected) => field.onChange(selected[0])}
          />
        )}
      />
    </FormSection>
  );
}

interface DeathRecordFormPageProps {
  currentUserData: CurrentUserData;
}

export function DeathRecordAddPage(props: DeathRecordFormPageProps) {
  const navigate = useNavigate();

  return (
    <Page>
      <Layout sectioned>
        <Layout.Section>
          <Card>
            <DeathRecordForm
              currentUserData={props.currentUserData}
              onSubmitSuccess={(id) => navigate(`../${id}`)}
            />
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
}

export function DeathRecordEditPage(props: DeathRecordFormPageProps) {
  const search = useLocation().search;
  const editMode = new URLSearchParams(search).get("editMode");

  const { uuid } = useParams();
  const navigate = useNavigate();

  const { data: deathRecord } = useDeathRecord(
    props.currentUserData.accessToken,
    uuid
  );

  const skeleton = !deathRecord && <Skeleton lines={16} />;

  const form = deathRecord && (
    <DeathRecordForm
      currentUserData={props.currentUserData}
      onSubmitSuccess={() => (editMode === "true" ? null : navigate("../"))}
      onDeleteSuccess={() => navigate("../../")}
      record={deathRecord}
    />
  );

  return (
    <BreadcrumbPage
      breadcrumbs="both"
      breadcrumbAction={() =>
        navigate(`${editMode === "true" ? "../../" : "../"}`)
      }
    >
      <Card>{form || skeleton}</Card>
    </BreadcrumbPage>
  );
}
