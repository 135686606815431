import { useMutation, useQuery, useQueryClient } from "react-query";
import { RQUERY_STALE_TIME } from "../../env";
import {
  deleteRecord,
  getRMAEvents,
  getRecord,
  performBulkRMAUpdate,
  updateRecord,
} from "../api/genericEndpoints";
import { BirthRecordInput } from "../schemas/birthRecord";
import { birthRecordEventSchemaList } from "../schemas/birthRecord";
import { BirthRecord } from "../schemas/birthRecord";
import { birthRecordSchema } from "../schemas/birthRecord";
import { axiosInstance, baseAxiosConfig } from "../api/shared";

export function useBirthRecord(accessToken: string, uuid?: string) {
  return useQuery(
    ["birthRecord", accessToken, uuid],
    async () =>
      accessToken && uuid
        ? await getRecord<BirthRecord>(
            accessToken,
            uuid,
            "birth-records",
            birthRecordSchema,
          )
        : null,
    { staleTime: RQUERY_STALE_TIME },
  );
}

export function useUpdateBirthRecord() {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      accessToken,
      inputData,
    }: {
      accessToken: string;
      inputData: BirthRecordInput;
    }) => {
      return updateRecord(accessToken, inputData, "birth-records");
    },
    {
      onSuccess: () => {
        return queryClient.invalidateQueries(["birthRecord"]);
      },
    },
  );
}

export function useDeleteBirthRecord() {
  return useMutation(
    ({ accessToken, uuid }: { accessToken: string; uuid: string }) => {
      return deleteRecord(accessToken, uuid, "birth-records");
    },
  );
}

export function useBirthRecordEvents(accessToken: string, uuid?: string) {
  return useQuery(
    ["birthRecord", "events", uuid],
    async () =>
      uuid
        ? await getRMAEvents<BirthRecord>(
            accessToken,
            uuid,
            birthRecordEventSchemaList,
            "birth-records",
          )
        : null,
    { staleTime: RQUERY_STALE_TIME },
  );
}

export function useBulkUpdateBirthRecords() {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      accessToken,
      note,
      records,
    }: {
      accessToken: string;
      note?: string;
      records: BirthRecord[];
    }) => {
      return performBulkRMAUpdate(accessToken, "birth-records", records, note);
    },
    {
      onSuccess: () => {
        return queryClient.invalidateQueries(["birthRecord"]);
      },
    },
  );
}

export function useExecAnnualRelease() {
  const queryClient = useQueryClient();

  const exec = async (accessToken: string, year: number) => {
    await axiosInstance.put(
      "/birth-records/bulk/annual-release",
      {},
      { ...baseAxiosConfig(accessToken), params: { year } },
    );
  };

  return useMutation({
    mutationFn: ({
      accessToken,
      year,
    }: {
      accessToken: string;
      year: number;
    }) => {
      return exec(accessToken, year);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["birthRecord"] });
    },
  });
}
