import { useMutation, useQuery, useQueryClient } from "react-query";

import { RQUERY_STALE_TIME } from "../../env";
import * as api from "../api/genericEndpoints";
import { vetsGraveEventSchemaList } from "../schemas/vetsGrave";
import { VetsGraveInput } from "../schemas/vetsGrave";
import { VetsGraveRecord } from "../schemas/vetsGrave";
import { vetsGraveSchema } from "../schemas/vetsGrave";

export function useVetsGrave(accessToken: string, uuid?: string) {
  return useQuery(
    ["vetsGrave", accessToken, uuid],
    async () =>
      accessToken && uuid
        ? await api.getRecord<VetsGraveRecord>(
            accessToken,
            uuid,
            "vets-graves",
            vetsGraveSchema,
          )
        : null,
    { staleTime: RQUERY_STALE_TIME },
  );
}

export function useUpdateVetsGrave() {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      accessToken,
      inputData,
    }: {
      accessToken: string;
      inputData: VetsGraveInput;
    }) => {
      return api.updateRecord(accessToken, inputData, "vets-graves");
    },
    {
      onSuccess: () => {
        return queryClient.invalidateQueries(["vetsGrave"]);
      },
    },
  );
}

export function useDeleteVetsGrave() {
  return useMutation(
    ({ accessToken, uuid }: { accessToken: string; uuid: string }) => {
      return api.deleteRecord(accessToken, uuid, "vets-graves");
    },
  );
}

export function useVetsGraveEvents(accessToken: string, uuid?: string) {
  return useQuery(
    ["vetsGrave", "events", uuid],
    async () =>
      uuid
        ? await api.getRMAEvents<VetsGraveRecord>(
            accessToken,
            uuid,
            vetsGraveEventSchemaList,
            "vets-graves",
          )
        : null,
    { staleTime: RQUERY_STALE_TIME },
  );
}
