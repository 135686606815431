import { Button, Divider } from "@shopify/polaris";
import { useState } from "react";
import Card from "../../shared/Card";
import Stack from "../../shared/Stack";
import { Heading, Subheading } from "../../shared/TextComponents";
import { hasPermissions } from "../auth/authutils";
import { useCommentsByRecord, useUpdateComment } from "../hooks/commentHooks";
import { Comment, CommentInput } from "../schemas/comment";
import { CurrentUserData, Dataset } from "../schemas/core";
import CommentDetail from "./CommentDetail";
import { CommentFormModal } from "./CommentForm";
import { IndentedDiv } from "../utils/IndentedDiv";

interface CommentThreadProps {
  comments: Comment[];
  onEditBtnClick?: (cmt: Comment) => void;
  onSubmitEdit?: (input: CommentInput) => void;
  editIsLoading?: boolean;
  onReply?: (replyToCmt: Comment) => void;
  indent?: number;
}
export function CommentThread({
  comments,
  onEditBtnClick,
  onSubmitEdit,
  editIsLoading,
  onReply,
  indent = 0,
}: CommentThreadProps) {
  return (
    <IndentedDiv level={indent}>
      <Stack>
        {comments.map((cmt) => (
          <Stack>
            <CommentDetail
              key={cmt.id}
              comment={cmt}
              onEditBtnClick={
                onEditBtnClick ? () => onEditBtnClick(cmt) : undefined
              }
              onSubmitEdit={onSubmitEdit}
              editIsLoading={editIsLoading}
              additionalActions={
                onReply ? (
                  <Button
                    onClick={() => {
                      onReply(cmt);
                    }}
                  >
                    Reply
                  </Button>
                ) : null
              }
            />
            <IndentedDiv level={indent + 2}>
              <CommentThread
                comments={cmt.replies}
                onEditBtnClick={onEditBtnClick}
                onSubmitEdit={onSubmitEdit}
                editIsLoading={editIsLoading}
                onReply={onReply}
              />
            </IndentedDiv>
          </Stack>
        ))}
      </Stack>
    </IndentedDiv>
  );
}

interface CommentSectionProps {
  recordId: string;
  currentUserData: CurrentUserData;
  dataset: Dataset;
  defaults?: { userName: string; email: string };
}

export default function CommentSection({
  recordId,
  currentUserData,
  dataset,
  defaults,
}: CommentSectionProps) {
  const [modalActive, setModalActive] = useState(false);
  const [activeCmt, setActiveCmt] = useState<Comment | undefined>();
  const [replyToCmt, setReplyToCmt] = useState<Comment | undefined>();

  const mutation = useUpdateComment();

  const closeCommentFormModal = () => {
    setActiveCmt(undefined);
    setReplyToCmt(undefined);
  };

  const { data: comments } = useCommentsByRecord(
    currentUserData.accessToken,
    recordId,
  );

  const form = (
    <CommentFormModal
      promptTitle={activeCmt ? "Edit Comment" : "Add Comment"}
      currentUserData={currentUserData}
      modalActive={modalActive}
      setModalActive={setModalActive}
      onClose={closeCommentFormModal}
      keepFormVisible
      record={activeCmt}
      replyToCmt={replyToCmt}
      defaults={{
        userName: defaults?.userName || "",
        email: defaults?.email || "",
        dataset: dataset,
        assignedTo: recordId,
        certification: "staff",
        accessLevel: "public",
        content: "",
        replyTo: replyToCmt?.id,
      }}
    />
  );

  return (
    <Card>
      <Stack>
        <Heading>Comments</Heading>
        {comments && comments.length > 0 ? (
          <CommentThread
            comments={comments}
            editIsLoading={mutation.isLoading}
            onEditBtnClick={(cmt) => {
              setActiveCmt(cmt);
              setModalActive(true);
            }}
            onSubmitEdit={
              hasPermissions(currentUserData, "edit_comments")
                ? (input) =>
                    mutation.mutate({
                      accessToken: currentUserData.accessToken,
                      inputData: input,
                    })
                : undefined
            }
            onReply={
              hasPermissions(currentUserData, "add_comments")
                ? (cmt) => {
                    setReplyToCmt(cmt);
                    setModalActive(true);
                  }
                : undefined
            }
          />
        ) : (
          "No comments found for this record."
        )}
        <Divider />
        {hasPermissions(currentUserData, "add_comments") && (
          <>
            <Subheading>Add Comment</Subheading>
            {form}
          </>
        )}
      </Stack>
    </Card>
  );
}
