import {
  Button,
  DescriptionList,
  Divider,
  Spinner,
  Tabs,
} from "@shopify/polaris";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuid } from "uuid";
import * as env from "../../env";
import Card from "../../shared/Card";
import Stack from "../../shared/Stack";
import { Heading, PText, Subheading } from "../../shared/TextComponents";
import BreadcrumbPage from "../BreadcrumbPage";
import {
  BaseDetailCompProps,
  DetailFields,
  DetailSection,
  ListFieldStack,
  ListObjectFieldStack,
} from "../Detail";
import Skeleton from "../Skeleton";
import ZoomableImage from "../ZoomableImage";
import ZoomablePDF from "../ZoomablePDF";
import { hasPermissions } from "../auth/authutils";
import {
  useCatalogueItem,
  useCatalogueItemMedias,
  useDeleteCatalogueItem,
} from "../hooks/catalogueItemHooks";
import {
  CatalogueItem,
  CollectionsMultimedia,
  catalogueItemLabels,
} from "../schemas/catalogueItem";
import { StringElement } from "../schemas/core";
import { DeletePrompt, DeleteSuccessPrompt } from "../utils/DeleteComponents";
import { DefaultPageProps, removeNulls } from "../utils/shared";
import CommentSection from "./CommentSection";

export function CatalogueItemDetail(props: BaseDetailCompProps<CatalogueItem>) {
  const [selectedTab, setSelectedTab] = useState(0);
  const [deletePromptActive, setDeletePromptActive] = useState(false);
  const [deleteConfirmActive, setDeleteConfirmActive] = useState(false);

  const navigate = useNavigate();

  const deleteMutation = useDeleteCatalogueItem();

  const catalogueItem = props.record;

  const imageMultimedia = useCatalogueItemMedias(
    props.currentUserData.accessToken,
    catalogueItem?.images || [],
  );
  const docsMultimedia = useCatalogueItemMedias(
    props.currentUserData.accessToken,
    catalogueItem?.docs || [],
  );

  const toggleDeletePrompt = () => setDeletePromptActive((current) => !current);

  const skeleton = !catalogueItem && <Skeleton lines={11} />;

  const heading = catalogueItem && (
    <Stack spacing={1}>
      <Heading>{catalogueItem.title}</Heading>
      <Subheading>{catalogueItem.alternateId}</Subheading>
    </Stack>
  );

  const deleteButton = hasPermissions(
    props.currentUserData,
    "edit_remove_collections_items",
  ) && (
    <Button tone="critical" onClick={toggleDeletePrompt}>
      Delete
    </Button>
  );

  const deletePrompt = catalogueItem && (
    <DeletePrompt
      id={catalogueItem.id}
      active={deletePromptActive}
      togglePrompt={toggleDeletePrompt}
      onDelete={(id: string) => {
        deleteMutation.mutate(
          { accessToken: props.currentUserData.accessToken, id },
          {
            onSuccess: (result) => {
              setDeleteConfirmActive(true);
              toggleDeletePrompt();
            },
          },
        );
      }}
    />
  );

  const deleteSuccessPrompt = catalogueItem && (
    <DeleteSuccessPrompt
      id={catalogueItem.id}
      active={deleteConfirmActive}
      onAck={() => navigate("../")}
    />
  );

  const renderListFieldStack = (label: string, elements: StringElement[]) => {
    return (
      <>
        {elements.length > 0 && (
          <Stack>
            <Divider />
            <ListFieldStack
              label={label}
              elements={elements.map((element) => ({
                value: element.value.replaceAll(">>", ", "),
              }))}
              noDivider
            />
          </Stack>
        )}
      </>
    );
  };

  const renderListObjectDescListStack = <T extends object>(
    label: string,
    elements: T[],
    getTerms: (element: T) => { term: string; description: string },
  ) => {
    return (
      <>
        {elements.length > 0 && (
          <Stack>
            <Divider />
            <ListObjectFieldStack
              label={label}
              elements={elements}
              noDivider
              renderMethod={(element) => (
                <DescriptionList items={[getTerms(element)]} />
              )}
            />
          </Stack>
        )}
      </>
    );
  };

  const renderMultimediaMetadata = (multimedia: CollectionsMultimedia) => {
    const filename = ["audio/mpeg", "video/mp4"].includes(
      multimedia.mimetype,
    ) ? (
      <Button
        variant="plain"
        onClick={() =>
          navigate(
            `../../media/${multimedia.hash}?id=${multimedia.id}&filename=${multimedia.filename}`,
          )
        }
      >
        {multimedia.filename}
      </Button>
    ) : (
      multimedia.filename
    );

    return (
      <div key={multimedia.id}>
        <PText>
          <strong>{multimedia.title}</strong>
        </PText>
        <PText>
          <strong>ID:</strong> {multimedia.id}
        </PText>
        <PText>
          <strong>Filename:</strong> {filename}
        </PText>
        <PText>
          <strong>
            {multimedia.forSale ? "For Sale via" : "Not for sale"}
          </strong>{" "}
          {multimedia.forSale}
        </PText>
      </div>
    );
  };

  const renderMultimediaLinks = (multimedias: CollectionsMultimedia[]) => {
    return (
      <Stack>{multimedias.map((mm) => renderMultimediaMetadata(mm))}</Stack>
    );
  };

  const images = imageMultimedia.length > 0 && (
    <Stack>
      <Stack>
        {imageMultimedia.map((queryResult) => {
          const { data, isLoading } = queryResult;
          if (data) {
            return (
              <div key={data.multimedia.id}>
                <ZoomableImage
                  src={data.content.content}
                  alt={data.multimedia.title}
                  fileName={data.multimedia.filename}
                />
                {renderMultimediaMetadata(data.multimedia)}
              </div>
            );
          } else if (isLoading) {
            return <Spinner key={uuid()} />;
          } else {
            return <PText>Failed to load</PText>;
          }
        })}
      </Stack>
    </Stack>
  );

  const docs = docsMultimedia.length > 0 && (
    <Stack>
      <Stack>
        {docsMultimedia.map((queryResult) => {
          const { data, isLoading } = queryResult;
          if (data) {
            return (
              <div key={data.multimedia.id}>
                <ZoomablePDF
                  src={data.content.content}
                  fileName={data.multimedia.filename}
                />
                {renderMultimediaMetadata(data.multimedia)}
              </div>
            );
          } else if (isLoading) {
            return <Spinner key={uuid()} />;
          } else {
            return <PText>Failed to load</PText>;
          }
        })}
      </Stack>
    </Stack>
  );
  const audios =
    catalogueItem && catalogueItem.audios.length > 0
      ? renderMultimediaLinks(catalogueItem.audios)
      : null;

  const videos =
    catalogueItem && catalogueItem.videos.length > 0
      ? renderMultimediaLinks(catalogueItem.videos)
      : null;

  const tabComponents = removeNulls([
    imageMultimedia.length > 0 ? images : null,
    docsMultimedia.length > 0 ? docs : null,
    audios,
    videos,
  ]);

  const tabs = removeNulls([
    imageMultimedia.length > 0
      ? { id: "images", content: "Images", panelID: "images" }
      : null,
    docsMultimedia.length > 0
      ? { id: "docs", content: "Documents", panelID: "docs" }
      : null,
    audios ? { id: "audios", content: "Audios", panelID: "audios" } : null,
    videos ? { id: "videos", content: "Videos", panelID: "videos" } : null,
  ]);

  const detail = catalogueItem && (
    <>
      <DetailFields
        record={catalogueItem}
        labels={catalogueItemLabels}
        spec={{
          itemType: null,
          holdingType: null,
          historicContext: null,
          credit: null,
          creationDateStart: { dateType: "date" },
          creationDateEnd: { dateType: "date" },
          contentDateStart: { dateType: "date" },
          contentDateEnd: { dateType: "date" },
        }}
      >
        {renderListFieldStack(
          catalogueItemLabels["alternateTitles"],
          catalogueItem.alternateTitles,
        )}
        {renderListFieldStack(
          catalogueItemLabels["descriptions"],
          catalogueItem.descriptions,
        )}
        {renderListObjectDescListStack(
          catalogueItemLabels["notes"],
          catalogueItem.notes,
          (note) => ({ term: note.label, description: note.value }),
        )}
        {renderListFieldStack(
          catalogueItemLabels["materials"],
          catalogueItem.materials,
        )}
        {renderListFieldStack(
          catalogueItemLabels["materialColors"],
          catalogueItem.materialColors,
        )}
        {renderListFieldStack(
          catalogueItemLabels["formats"],
          catalogueItem.formats,
        )}
        {renderListFieldStack(
          catalogueItemLabels["proveniences"],
          catalogueItem.proveniences,
        )}
        {renderListFieldStack(
          catalogueItemLabels["classifications"],
          catalogueItem.classifications,
        )}
        {renderListFieldStack(
          catalogueItemLabels["measurements"],
          catalogueItem.measurements,
        )}
        {renderListFieldStack(
          catalogueItemLabels["nomenclatures"],
          catalogueItem.nomenclatures,
        )}
        {renderListFieldStack(
          catalogueItemLabels["quantities"],
          catalogueItem.quantities,
        )}
        {renderListObjectDescListStack(
          catalogueItemLabels["dateDisplays"],
          catalogueItem.dateDisplays,
          (dateDisp) => ({
            term: dateDisp.dateType,
            description: dateDisp.display,
          }),
        )}
        {renderListObjectDescListStack(
          catalogueItemLabels["identifiers"],
          catalogueItem.identifiers,
          (idfr) => ({ term: idfr.label, description: idfr.value }),
        )}
        {renderListObjectDescListStack(
          catalogueItemLabels["associations"],
          catalogueItem.associations,
          (assoc) => ({ term: assoc.nature, description: assoc.value }),
        )}
        {renderListFieldStack(
          catalogueItemLabels["subjects"],
          catalogueItem.subjects,
        )}
        {renderListFieldStack(
          catalogueItemLabels["places"],
          catalogueItem.places,
        )}
        {renderListObjectDescListStack(
          catalogueItemLabels["parties"],
          catalogueItem.parties,
          (party) => ({
            term: party.nature,
            description: `${party.value.summaryData} (${party.value.id})`,
          }),
        )}
        {catalogueItem.locations.length > 0 && (
          <Stack>
            <Divider />
            <ListObjectFieldStack
              label={catalogueItemLabels["locations"]}
              elements={catalogueItem.locations}
              noDivider
              renderMethod={(loc, idx) => (
                <div
                  style={{
                    background:
                      (idx + 1) % 2 === 0 ? "WhiteSmoke" : "transparent",
                    padding: 10,
                    borderRadius: 10,
                  }}
                >
                  <DescriptionList
                    items={[
                      { term: "Location ID", description: loc.value.id },
                      { term: "Name", description: loc.value.siteName },
                      {
                        term: "Coordinates",
                        description: loc.value.coordinates
                          .map((coord) => `(${coord.lat}, ${coord.lon})`)
                          .join(", "),
                      },
                    ]}
                  />
                </div>
              )}
            />
          </Stack>
        )}
        {renderListFieldStack(
          catalogueItemLabels["restrictions"],
          catalogueItem.restrictions,
        )}
      </DetailFields>
      <Stack>
        <Divider />
        <Heading>Media</Heading>
        {tabs.length > 0 ? (
          <Tabs
            tabs={tabs}
            selected={selectedTab}
            onSelect={(tabIdx) => setSelectedTab(tabIdx)}
          >
            <>{tabComponents[selectedTab]}</>
          </Tabs>
        ) : (
          <PText>No associated media</PText>
        )}
      </Stack>
      <Stack>
        <Divider />
        <Heading>Related Records</Heading>
        {catalogueItem.parent && (
          <Stack>
            <Subheading>Parent</Subheading>
            <Button
              variant="plain"
              onClick={() => navigate(`../${catalogueItem.parent?.id}`)}
            >
              {catalogueItem.parent.summaryData}
            </Button>
          </Stack>
        )}
        {catalogueItem.children.length > 0 && (
          <ListObjectFieldStack
            label="Children"
            elements={catalogueItem.children}
            noDivider
            renderMethod={(c) => (
              <Button variant="plain" onClick={() => navigate(`../${c.id}`)}>
                {c.summaryData}
              </Button>
            )}
          />
        )}
        {!catalogueItem.parent && catalogueItem.children.length === 0 && (
          <PText>No related records.</PText>
        )}
      </Stack>
    </>
  );

  return (
    <DetailSection>
      {deletePrompt}
      {deleteSuccessPrompt}
      {heading}
      <>{skeleton || detail}</>
      <Stack direction="row">
        {deleteMutation.isLoading && <Spinner size="small" />}
        {deleteButton || null}
      </Stack>
    </DetailSection>
  );
}

export function CatalogueItemDetailPage(props: DefaultPageProps) {
  const { uuid } = useParams();

  const { data: catalogueItem } = useCatalogueItem(
    props.currentUserData.accessToken,
    uuid,
  );

  const canSeeComments =
    hasPermissions(props.currentUserData, "view_public_comments") ||
    hasPermissions(props.currentUserData, "view_unpublished_comments");

  return (
    <BreadcrumbPage breadcrumbAction="up" breadcrumbs="both">
      <Stack>
        <Card>
          <CatalogueItemDetail
            currentUserData={props.currentUserData}
            record={catalogueItem}
            onEditBtnClick={() => {}}
          />
        </Card>
        {uuid && env.FEATURE_COMMENTS && canSeeComments && (
          <CommentSection
            currentUserData={props.currentUserData}
            recordId={uuid}
            dataset="catalogue-item"
          />
        )}
      </Stack>
    </BreadcrumbPage>
  );
}
