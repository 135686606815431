import {
  Button,
  Divider,
  Pagination,
  ResourceItem,
  ResourceList,
  Spinner,
} from "@shopify/polaris";
import { ReactNode, useState } from "react";
import Card from "../../shared/Card";
import Stack from "../../shared/Stack";
import { PText } from "../../shared/TextComponents";
import { hasPermissions } from "../auth/authutils";
import BreadcrumbPage from "../BreadcrumbPage";
import { useQueryComments, useUpdateComment } from "../hooks/commentHooks";
import {
  Comment,
  commentFilters,
  commentLabels,
  commentSorts,
} from "../schemas/comment";
import { DatasetNavMap } from "../schemas/core";
import {
  AddSearchParamBtn,
  ContentSensitiveClearBtn,
} from "../search/components";
import FilterParamPopover from "../search/FilterParamPopover";
import SearchParamChit, { FilterParamChit } from "../search/SearchParamChit";
import {
  SearchFilterConjunctParam,
  SearchFilterParam,
  SearchSortParams,
} from "../search/searchutils";
import SortParamPopover from "../search/SortParamPopover";
import * as utils from "../utils/shared";
import { DefaultPageProps } from "../utils/shared";
import CommentDetail from "./CommentDetail";
import { CommentFormModal } from "./CommentForm";

export function CommentFeedPage(props: DefaultPageProps) {
  const [page, setPage] = useState(1);
  const [modalActive, setModalActive] = useState(false);
  const [selectedCmt, setSelectedCmt] = useState<Comment | undefined>();
  const [sortPopoverActive, setSortPopoverActive] = useState(false);
  const [sorts, setSorts] = useState<SearchSortParams<Comment>>({
    commentDt: { field: "commentDt", direction: "desc" },
  });
  const [filterPopoverActive, setFilterPopoverActive] = useState(false);
  const [filters, setFilters] = useState<
    (SearchFilterParam | SearchFilterConjunctParam)[]
  >([]);

  const {
    data: recentComments,
    isLoading,
    isRefetching,
  } = useQueryComments(
    props.currentUserData.accessToken,
    page,
    50,
    Object.values(sorts),
    filters,
  );

  const mutation = useUpdateComment();

  const prevPage = () => setPage((old) => Math.max(old - 1, 1));

  const nextPage = () =>
    setPage((old) => (recentComments?.hasNext ? old + 1 : old));

  const createGoToRecordUrl = (cmt: Comment) => {
    // TEMP: Link directly to production because all the comments are in staging
    // but not all the records they link to. This is the most expedient way for
    // Collections to test data validity.
    let urlRoot = "https://vital-records.mnhs.io/rma/";
    // let urlRoot = "/rma/"
    return urlRoot + `${DatasetNavMap[cmt.dataset]}/${cmt.assignedTo}`;
  };

  // Sorts
  const toggleSortPopover = () => {
    setSortPopoverActive((prev) => !prev);
  };

  const addSortBtn = <AddSearchParamBtn onClick={toggleSortPopover} />;

  const sortPopover = (
    <SortParamPopover
      activator={addSortBtn}
      active={sortPopoverActive}
      onClose={toggleSortPopover}
      sortFields={commentSorts}
      onSubmit={(key, order) =>
        setSorts((prev) => ({
          ...prev,
          [key]: { field: key, direction: order },
        }))
      }
    />
  );

  const sortDisplay = (
    <Stack direction="row" align="center">
      <PText weight="bold">Sorting</PText>
      {Object.entries(sorts).map(([key, sortOrder]) => (
        <SearchParamChit
          key={key}
          displayText={`${commentLabels[key as keyof Comment]}`}
          onRemove={() => {
            const result = utils.pop(key, sorts);
            setSorts(result[1]);
          }}
          onClick={() => {
            const newOrder =
              sorts[key as keyof Comment]?.direction === "asc" ? "desc" : "asc";
            setSorts((previousSorts) => ({
              ...previousSorts,
              [key]: { field: key, direction: newOrder },
            }));
          }}
          disclosure={sortOrder.direction === "asc" ? "up" : "down"}
        />
      ))}
      {sortPopover}
      <ContentSensitiveClearBtn content={sorts} onClick={() => setSorts({})} />
    </Stack>
  );

  // Filters
  const toggleFilterPopover = () => {
    setFilterPopoverActive((prev) => !prev);
  };

  const addFilterBtn = <AddSearchParamBtn onClick={toggleFilterPopover} />;

  const filterPopover = (
    <FilterParamPopover
      activator={addFilterBtn}
      active={filterPopoverActive}
      onClose={toggleFilterPopover}
      onSubmit={(f) => {
        setFilters((prev) => [...prev, f]);
      }}
      filterFields={commentFilters}
    />
  );

  const filterDisplay = (
    <Stack direction="row" align="center">
      <PText weight="bold">Filtering</PText>
      {filters.map((f, idx) => (
        <FilterParamChit
          key={f.uuid}
          onChange={(f) => {}}
          filterFields={commentFilters}
          filter={f}
          onRemove={() => setFilters(utils.removeItem(filters, idx))}
        />
      ))}
      {filterPopover}
      <ContentSensitiveClearBtn
        content={filters}
        onClick={() => setFilters([])}
      />
    </Stack>
  );

  // Comments display
  const renderItem = (item: Comment): ReactNode => (
    <ResourceItem
      id={item.id}
      accessibilityLabel={`Detail for comment by user ${item.userName} `}
      // ResourceItem onClick doesn't play nice with the CommentDetail buttons:
      onClick={() => {}}
    >
      <CommentDetail
        comment={item}
        editIsLoading={mutation.isLoading}
        onEditBtnClick={() => {
          setSelectedCmt(item);
          setModalActive(true);
        }}
        onSubmitEdit={
          hasPermissions(props.currentUserData, "edit_comments")
            ? (input) =>
                mutation.mutate({
                  accessToken: props.currentUserData.accessToken,
                  inputData: input,
                })
            : undefined
        }
        additionalActions={
          <Button
            onClick={() => window.open(createGoToRecordUrl(item))}
            // onClick={() => navigate(createGoToRecordUrl(item))}
          >
            Go To Record
          </Button>
        }
      />
    </ResourceItem>
  );

  const pagination = !isLoading && (
    <Stack direction="row" justify="center">
      <Pagination
        hasPrevious={recentComments?.hasPrevious}
        hasNext={recentComments?.hasNext}
        label={`Page ${recentComments?.pageNum || "?"} / 
            ${
              recentComments
                ? Math.ceil(recentComments.totalCount / recentComments.pageSize)
                : "??"
            }`}
        onNext={nextPage}
        onPrevious={prevPage}
      />
      {isRefetching ? <Spinner size="small" /> : <PText> </PText>}
    </Stack>
  );

  const editCommentModal = selectedCmt && (
    <CommentFormModal
      promptTitle="Edit Comment"
      currentUserData={props.currentUserData}
      record={selectedCmt}
      modalActive={modalActive}
      setModalActive={setModalActive}
      hideAccessLevel
    />
  );

  const feed = recentComments && (
    <ResourceList
      resourceName={{ singular: "comment", plural: "comments" }}
      items={recentComments.results}
      renderItem={renderItem}
    />
  );

  const spinner = isLoading && (
    <Stack direction="row" justify="center">
      <Spinner />
    </Stack>
  );

  return (
    <BreadcrumbPage>
      <Card>
        <Stack>
          {editCommentModal}
          <Divider />
          {sortDisplay}
          <Divider />
          {filterDisplay}
          <Divider />
          {pagination}
          {spinner}
          {feed}
          {pagination}
        </Stack>
      </Card>
    </BreadcrumbPage>
  );
}
