import { useMutation, useQuery, useQueryClient } from "react-query";

import { RQUERY_STALE_TIME } from "../../env";
import {
  deleteRecord,
  getRMAEvents,
  getRecord,
  updateRecord,
} from "../api/genericEndpoints";
import { goldStarEventSchemaList } from "../schemas/goldStar";
import { GoldStarRecordInput } from "../schemas/goldStar";
import { GoldStarRecord } from "../schemas/goldStar";
import { goldStarSchema } from "../schemas/goldStar";

export function useGoldStar(accessToken: string, uuid?: string) {
  return useQuery(
    ["goldStar", accessToken, uuid],
    async () =>
      accessToken && uuid
        ? await getRecord<GoldStarRecord>(
            accessToken,
            uuid,
            "gold-star",
            goldStarSchema,
          )
        : null,
    { staleTime: RQUERY_STALE_TIME },
  );
}

export function useUpdateGoldStar() {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      accessToken,
      inputData,
    }: {
      accessToken: string;
      inputData: GoldStarRecordInput;
    }) => {
      return updateRecord(accessToken, inputData, "gold-star");
    },
    {
      onSuccess: () => {
        return queryClient.invalidateQueries(["goldStar"]);
      },
    },
  );
}

export function useDeleteGoldStar() {
  return useMutation(
    ({ accessToken, uuid }: { accessToken: string; uuid: string }) => {
      return deleteRecord(accessToken, uuid, "gold-star");
    },
  );
}

export function useGoldStarEvents(accessToken: string, uuid?: string) {
  return useQuery(
    ["goldStar", "events", uuid],
    async () =>
      uuid
        ? await getRMAEvents<GoldStarRecord>(
            accessToken,
            uuid,
            goldStarEventSchemaList,
            "gold-star",
          )
        : null,
    { staleTime: RQUERY_STALE_TIME },
  );
}
