import * as yup from "yup";
import {
  APIError,
  axiosInstance,
  baseAxiosConfig,
  handleAPIErrors,
} from "./shared";

import * as schemas from "../schemas/core";
import { SearchQuery, SearchQueryProps } from "../search/searchutils";

export type BaseRMAMediaEndpoint =
  | "birth-records"
  | "death-records"
  | "gold-star"
  | "vets-graves"
  | "census/pages";

export type BaseRMAEndpoint = BaseRMAMediaEndpoint | "census";

export type BaseEndpoint = BaseRMAEndpoint | "collections";

export type BaseUpdateEndpoint = BaseRMAEndpoint | "comments";

export type BaseDeleteEndpoint = BaseUpdateEndpoint | BaseEndpoint;

export type RMAMediaType = "pdf" | "image";

export const getRecord = async <T>(
  accessToken: string,
  uuid: string,
  base: BaseEndpoint,
  schema: yup.Schema,
): Promise<T> => {
  //   try {
  const resp = await axiosInstance.get(
    `/${base}/${uuid}`,
    baseAxiosConfig(accessToken),
  );
  const record = await schema.validate(resp.data);
  return record;
  //   } catch (e) {
  //     return handleAPIErrors(e);
  //   }
};

export const searchRecords = async <T>(
  { accessToken, query, sorts, filters, pageNum, pageSize }: SearchQueryProps,
  base: BaseEndpoint,
): Promise<schemas.SearchResult<T>> => {
  const searchQuery: SearchQuery = {
    query: query,
    sorts: sorts || [],
    filters: filters || [],
  };

  const resp = await axiosInstance.post(`/${base}/`, searchQuery, {
    ...baseAxiosConfig(accessToken),
    params: { pageNum, pageSize },
  });
  const result = await schemas.searchResultSchema.validate(resp.data);
  return result;
};

export const getCount = async (
  accessToken: string,
  base: BaseEndpoint,
  query?: string,
): Promise<number> => {
  const resp = await axiosInstance.get(`/${base}/count`, {
    ...baseAxiosConfig(accessToken),
    params: { query },
  });
  return resp.data;
};

export const requestSearchResultsEmail = async (
  query: SearchQuery,
  accessToken: string,
  base: BaseEndpoint,
) => {
  await axiosInstance.post(`/${base}/reports/search-results`, query, {
    ...baseAxiosConfig(accessToken),
  });
};

export const getMedia = async (
  accessToken: string,
  hash: string,
  base: BaseEndpoint,
): Promise<Blob> => {
  const resp = await axiosInstance.get(`${base}/media/${hash}`, {
    ...baseAxiosConfig(accessToken),
    responseType: "blob",
  });
  return resp.data;
};

export const deleteRecord = async (
  accessToken: string,
  uuid: string,
  base: BaseDeleteEndpoint,
): Promise<boolean> => {
  const resp = await axiosInstance.delete(
    `/${base}/${uuid}`,
    baseAxiosConfig(accessToken),
  );
  if (resp.status === 204) {
    return true;
  } else {
    return false;
  }
};

export const updateRecord = async <
  T extends { id?: string | null | undefined },
>(
  accessToken: string,
  inputModel: T,
  base: BaseUpdateEndpoint,
): Promise<string | undefined> => {
  try {
    const resp = await axiosInstance.post(
      inputModel.id ? `/${base}/${inputModel.id}/edit` : `${base}/add`,
      inputModel,
      baseAxiosConfig(accessToken),
    );
    return resp.data;
  } catch (e) {
    console.error(e);
    throw Error;
  }
};

export const addRMAMedia = async (
  accessToken: string,
  mediaFile: File,
  base: BaseRMAEndpoint,
): Promise<string | APIError> => {
  try {
    const formData = new FormData();
    formData.append("media", mediaFile);
    const resp = await axiosInstance.post(
      `/${base}/media`,
      formData,
      baseAxiosConfig(accessToken),
    );
    return resp.data;
  } catch (e) {
    return handleAPIErrors(e);
  }
};

export const getRMAEvents = async <T extends object>(
  accessToken: string,
  uuid: string,
  schema: yup.Schema,
  base: BaseRMAEndpoint,
): Promise<schemas.Event<T>[]> => {
  const resp = await axiosInstance.get(`/${base}/events/${uuid}`, {
    ...baseAxiosConfig(accessToken),
  });
  const result = await schema.validate(resp.data);
  return result;
};

export const getBulkRMAUpdateEntries = async (
  accessToken: string,
  base: BaseRMAEndpoint,
): Promise<schemas.BulkUpdateEntryList> => {
  const resp = await axiosInstance.get(`${base}/bulk/`, {
    ...baseAxiosConfig(accessToken),
  });
  const result = await schemas.bulkUpdateEntryList.validate(resp.data);
  return result;
};

export const performBulkRMAUpdate = async <T>(
  accessToken: string,
  base: BaseRMAEndpoint,
  records: T[],
  note?: string,
) => {
  const bulkInput: schemas.BulkUpdateEntryInput<T> = {
    note,
    records,
  };
  await axiosInstance.post(`/${base}/bulk/`, bulkInput, {
    ...baseAxiosConfig(accessToken),
  });
};

export const revertBulkRMAUpdate = async (
  accessToken: string,
  uuid: string,
  base: BaseRMAEndpoint,
) => {
  await axiosInstance.put(`/${base}/bulk/revert/${uuid}`, {
    ...baseAxiosConfig(accessToken),
  });
};
