import * as yup from "yup";
import { countyNames } from "../reference/counties";
import {
  FreeformDateGroupSpec,
  SearchFilterFields,
  SearchSortFields,
} from "../search/searchutils";
import { Labels, eventSchema, stringElementSchema } from "./core";

export const MilitaryServices = [
  "Unspecified",
  "Indian Wars",
  "War of 1812",
  "Mexican War",
  "Civil War",
  "Spanish-American War",
  "Philippine American War",
  "Boxer Rebellion",
  "Boer War",
  "Mexican Border War",
  "World War I",
  "World War II",
  "Korean War",
  "Vietnam War",
] as const;

export const BurialLocations = [...countyNames, "Fort Snelling"] as const;

export const vetsGraveSchema = yup.object({
  id: yup.string().required(),
  familyName: yup.string().required("Family Name is a required field"),
  burialLocation: yup.string().required(),
  militaryServices: yup.array().required().of(stringElementSchema),
  prefix: yup.string().nullable(),
  firstName: yup.string().nullable(),
  middleName: yup.string().nullable(),
  suffix: yup.string().nullable(),
  deathDateStart: yup.date().nullable(),
  deathDateEnd: yup.date().nullable(),
  alternateNames: yup.array().nullable().of(stringElementSchema),
  imageFrontSHA256: yup.string().nullable(),
  imageBackSHA256: yup.string().nullable(),
  additionalImageSHA256s: yup.array(yup.string()).nullable(),
  certificateId: yup.number().nullable(),
});

export type VetsGraveRecord = yup.InferType<typeof vetsGraveSchema>;

export const vetsGraveInputSchema = vetsGraveSchema.shape({
  id: yup.string().nullable(),
});

export type VetsGraveInput = yup.InferType<typeof vetsGraveInputSchema>;

export const vetsGraveEventSchema = eventSchema.shape({
  state: vetsGraveSchema,
});

export const vetsGraveEventSchemaList = yup
  .array()
  .of(vetsGraveEventSchema)
  .required();

export const vetsGraveLabels: Labels<VetsGraveRecord> = {
  id: "UUID",
  burialLocation: "Burial Location",
  militaryServices: "Military Services",
  prefix: "Prefix",
  firstName: "First Name",
  middleName: "Middle Name",
  familyName: "Family Name",
  suffix: "Suffix",
  deathDateStart: "Exact Death Date or Range Start",
  deathDateEnd: "Death Date Range End",
  imageFrontSHA256: "Image Front SHA256",
  imageBackSHA256: "Image Back SHA256",
  additionalImageSHA256s: "Additional Image SHA256s",
  alternateNames: "Alternate Names",
  certificateId: "Certificate Number",
};

export const vetsGraveSorts: SearchSortFields<VetsGraveRecord> = {
  burialLocation: { label: vetsGraveLabels["burialLocation"] },
  firstName: { label: vetsGraveLabels["firstName"] },
  middleName: { label: vetsGraveLabels["middleName"] },
  familyName: { label: vetsGraveLabels["familyName"] },
  suffix: { label: vetsGraveLabels["suffix"] },
  deathDateStart: { label: "Death Date Start" },
  deathDateEnd: { label: "Death Date End" },
};

export const vetsGraveFilters: SearchFilterFields = {
  firstName: {
    label: vetsGraveLabels["firstName"],
    type: "string",
    defaultOperator: "^",
    allowNullOps: true,
  },
  middleName: {
    label: vetsGraveLabels["middleName"],
    type: "string",
    defaultOperator: "^",
    allowNullOps: true,
  },
  familyName: {
    label: vetsGraveLabels["familyName"],
    type: "string",
    defaultOperator: "^",
  },
  suffix: {
    label: vetsGraveLabels["suffix"],
    type: "string",
  },
  deathDateIsInexact: {
    label: "Death Date is Inexact",
    type: "boolean",
  },
  militaryServices: {
    label: vetsGraveLabels["militaryServices"],
    type: "string",
    validTerms: [...MilitaryServices],
  },
  burialLocation: {
    label: vetsGraveLabels["burialLocation"],
    type: "string",
    validTerms: [...BurialLocations],
  },
  alternateNames: {
    label: vetsGraveLabels["alternateNames"],
    type: "string",
  },
  certificateId: {
    label: vetsGraveLabels["certificateId"],
    type: "number",
  },
  "comments.content": {
    label: "Comment Content",
    type: "string",
  },
  hasComments: {
    label: "Has Comment(s)",
    type: "boolean",
  },
};

export const vetsGraveFreeformDateSpecs: FreeformDateGroupSpec[] = [
  {
    groupName: "Death Date",
    startDayName: "deathStartDay",
    startMonthName: "deathStartMonth",
    startYearName: "deathStartYear",
    endDayName: "deathEndDay",
    endMonthName: "deathEndMonth",
    endYearName: "deathEndYear",
    startDateName: "deathDateStart",
    endDateName: "deathDateEnd",
  },
];
