import { yupResolver } from "@hookform/resolvers/yup";
import {
  ChoiceList,
  Divider,
  Layout,
  Page,
  ResourceItem,
  ResourceList,
} from "@shopify/polaris";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Heading, PText, Subheading } from "../shared/TextComponents";

import { FormSection, FormFields } from "./Form";
import {
  useEmailAutomations,
  useUpdateEmailAutomation,
} from "./hooks/emailAutomationHooks";
import { useRoles } from "./hooks/roleHooks";
import {
  CurrentUserData,
  EmailRecipient,
  EmailRecipientInput,
  emailRecipientInputSchema,
  emailRecipientLabels,
} from "./schemas/core";
import Skeleton from "./Skeleton";
import Card from "../shared/Card";
import Stack from "../shared/Stack";

interface EmailAutomationPageProps {
  currentUserData: CurrentUserData;
}

export function EmailAutomationPage(props: EmailAutomationPageProps) {
  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);

  const { data: emailAutomations } = useEmailAutomations(
    props.currentUserData.accessToken
  );
  const { data: roles } = useRoles(props.currentUserData.accessToken);

  const {
    control,
    handleSubmit,
    getValues,
    reset,
    setValue,
    formState: { errors },
  } = useForm<EmailRecipientInput>({
    resolver: yupResolver(emailRecipientInputSchema),
  });

  const onSubmit = (inputData: EmailRecipientInput) => {
    inputData.recipients = selectedRoles;
  };

  const onItemClick = (item: EmailRecipient) => {
    setValue("purpose", item.purpose);
    console.log(item.recipients.map((r) => r.id));
    setSelectedRoles(item.recipients.map((r) => r.id));
  };

  const onClear = () => {
    reset();
    setSelectedRoles([]);
  };

  const renderItem = (item: EmailRecipient) => {
    return (
      <ResourceItem id={item.id} onClick={() => onItemClick(item)}>
        <PText>
          <strong>Purpose: </strong>
          {item.purpose}
        </PText>
        <PText>
          <strong>Roles: </strong>
          {item.recipients.map((r) => r.name).join(" \u25CF ")}
        </PText>
      </ResourceItem>
    );
  };

  const choicesSkeleton = <Skeleton lines={7} title={false} />;

  const roleChoices = roles && (
    <ChoiceList
      allowMultiple
      title={<Subheading>Associated Roles</Subheading>}
      choices={roles.map((r) => ({ label: r.name, value: r.id }))}
      selected={selectedRoles}
      onChange={(roleIds) => setSelectedRoles(roleIds)}
    />
  );

  const form = (
    <FormSection
      currentUserData={props.currentUserData}
      inputSchema={emailRecipientInputSchema}
      handleSubmit={handleSubmit}
      mutateHook={useUpdateEmailAutomation}
      onSubmit={onSubmit}
      onClearAll={onClear}
      onMutateSuccess={(result) => onClear()}
    >
      <FormFields
        control={control}
        spec={{ purpose: { type: "text", required: true } }}
        labels={emailRecipientLabels}
        // Purpose is the only relevant error, and passing the errors object alone
        // throws an error:
        errors={{ purpose: errors.purpose }}
        reset={reset}
        getValues={getValues}
      />
      {roleChoices || choicesSkeleton}
    </FormSection>
  );

  const details = emailAutomations && (
    <ResourceList
      resourceName={{ singular: "automation", plural: "automations" }}
      items={emailAutomations}
      renderItem={renderItem}
      emptyState={<PText>No automations found</PText>}
    />
  );

  const autosSkeleton = <Skeleton lines={10} title={false} />;

  return (
    <Page>
      <Layout sectioned>
        <Layout.Section>
          <Card>
            <Stack>
              <Heading>Email Automation Management</Heading>
              <Divider />
              <Subheading> Add New Automation</Subheading>
              <Stack>{form}</Stack>
              <Divider />
              <Subheading>Existing Automations</Subheading>
              {details || autosSkeleton}
            </Stack>
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
}
