import * as yup from "yup";
import { Dataset, DATASETS, Labels } from "./core";
import { SearchFilterFields, SearchSortFields } from "../search/searchutils";

export const CMT_ACCESS_LEVELS = ["public", "unpublished"] as const;
export type CmtAccessLevel = (typeof CMT_ACCESS_LEVELS)[number];

export const CMT_CERTIFICATION = ["basic", "staff"] as const;
export type CmtCertification = (typeof CMT_CERTIFICATION)[number];

export const commentInputSchema = yup.object({
  id: yup.string().nullable(),
  content: yup.string().required(),
  userName: yup.string().required(),
  email: yup.string().required(),
  dataset: yup
    .string()
    .oneOf([...DATASETS])
    .required(),
  assignedTo: yup.string().required(),
  commentDt: yup.date().optional(),
  accessLevel: yup
    .string()
    .oneOf([...CMT_ACCESS_LEVELS])
    .required(),
  replyTo: yup.string().optional().nullable(),
  certification: yup
    .string()
    .oneOf([...CMT_CERTIFICATION])
    .required(),
});

export type CommentInput = yup.InferType<typeof commentInputSchema>;

// Comments have no schema because of the infinitely nested replies, which yup
// doesn't handle well.
export type Comment = {
  id: string;
  content: string;
  userName: string;
  email: string;
  dataset: Dataset;
  assignedTo: string;
  commentDt: Date;
  accessLevel: CmtAccessLevel;
  replyTo: string | null;
  replies: Comment[];
  certification: CmtCertification;
  isStaffComment: boolean;
};

export const commentLabels: Labels<Comment> = {
  id: "UUID",
  content: "Content",
  userName: "User Name",
  email: "Email Address",
  dataset: "Assigned Record Type",
  assignedTo: "Assigned to Record UUID",
  commentDt: "Comment Date",
  accessLevel: "Status",
  replyTo: "Reply to Comment UUID",
  replies: "Replies",
  certification: "Certification",
  isStaffComment: "Is Staff Comment",
};

export const commentSorts: SearchSortFields<Comment> = {
  commentDt: { label: commentLabels["commentDt"] },
  userName: { label: commentLabels["userName"] },
  email: { label: commentLabels["email"] },
  accessLevel: { label: commentLabels["accessLevel"] },
  certification: { label: commentLabels["certification"] },
};

export const commentFilters: SearchFilterFields = {
  email: { label: commentLabels["email"], type: "string" },
  userName: { label: commentLabels["userName"], type: "string" },
  commentDt: { label: commentLabels["commentDt"], type: "date" },
  certification: {
    label: commentLabels["certification"],
    type: "string",
    validTerms: [...CMT_CERTIFICATION],
  },
};
