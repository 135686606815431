import { PText } from "../../shared/TextComponents";
import Prompt from "./Prompt";

interface DeletePromptProps<T> {
  id: T;
  active: boolean;
  onDelete: (id: T) => void;
  togglePrompt: () => void;
  loading?: boolean;
}

export function DeletePrompt<T>(props: DeletePromptProps<T>) {
  return (
    <Prompt
      active={props.active}
      onClose={props.togglePrompt}
      title="Confirm deletion"
      primaryAction={{
        content: "Delete",
        destructive: true,
        onAction: () => props.onDelete(props.id),
        loading: props.loading,
      }}
    >
      <PText>
        {`Really delete record ${props.id}? This cannot be undone.`}
      </PText>
    </Prompt>
  );
}

interface deleteSuccessPromptProps {
  id: any;
  active: boolean;
  onAck: () => void;
}

export function DeleteSuccessPrompt(props: deleteSuccessPromptProps) {
  return (
    <Prompt
      active={props.active}
      title="Successful deletion"
      primaryAction={{ content: "OK", onAction: props.onAck }}
      onClose={props.onAck}
    >
      <PText>{`Successfully deleted record ${props.id}`}</PText>
    </Prompt>
  );
}
