import { v4 as uuid4 } from "uuid";
import * as yup from "yup";
import {
  AnyFilterLike,
  FilterLike,
  SearchFilter,
  SearchFilterConjunct,
  SearchFilterConjunctParam,
  SearchFilterFields,
  SearchFilterOp,
  SearchFilterParam,
  parseSearchFilter,
  parseSearchFilterParam,
} from "../search/searchutils";
import { removeNulls } from "../utils/shared";

export const DATASETS = [
  "birthrecord",
  "deathrecord",
  "goldstar",
  "vetsgrave",
  "census",
  "catalogue-item",
  //   "findaid",
] as const;

export type Dataset = (typeof DATASETS)[number];

export const DatasetNavMap: Record<Dataset, string> = {
  birthrecord: "birth-records",
  deathrecord: "death-records",
  goldstar: "gold-star",
  vetsgrave: "vets-graves",
  census: "census",
  "catalogue-item": "collections/catalogue-items",
};

// Used in a couple places to disable changes to the System Admin role which
// would be rejected by the back end:
export const SYSADMIN_ROLE_ID = "01ef003b-6e2b-4f2d-a657-7dfa8f3a4a94";

export type Labels<T> = {
  [P in keyof T]-?: string;
};

/**
 * Yup doesn't have great validation of Record/Map-like objects with mutable
 * keys (i.e. objects like Record<uuid_string, object(s)>), so we need to sort of
 * broadcast validation over that sort of structure ourselves:
 * @param item Any (?) object.
 * @param schema The schema to use to validate each entry in the passed object.
 * @returns A Record object with keys as strings and values as yup validated
 * objects.
 */
export const validateRecordOfSchemas = async <T>(
  item: any,
  schema: yup.Schema,
): Promise<Record<string, T>> => {
  let result: Record<string, T> = {};
  for (const [key, value] of Object.entries(item)) {
    result[key] = await schema.validate(value);
  }
  return result;
};

export const contextualPrefs = yup.object({
  useAdvSearchToggle: yup.boolean().default(false),
  useNavMode: yup.boolean().default(false),
  useEditMode: yup.boolean().default(false),
});

export type ContextualPrefs = yup.InferType<typeof contextualPrefs>;

export const contexts = yup.object({
  birthRecords: contextualPrefs,
  deathRecords: contextualPrefs,
  goldStar: contextualPrefs,
  vetsGraves: contextualPrefs,
  census: contextualPrefs,
  collections: contextualPrefs,
});

export type Contexts = yup.InferType<typeof contexts>;

export const userPrefsSchema = yup
  .object({
    useNightMode: yup.boolean().default(false),
    contextualPrefs: contexts,
  })
  .required();

export type UserPrefs = yup.InferType<typeof userPrefsSchema>;

// Auth schemas:
export const userSchema = yup
  .object({
    id: yup.string().required(),
    email: yup.string().email().required(),
    name: yup.string().required(),
    roleIds: yup.array(yup.string().required()).required().default([]),
    createDt: yup.date().required(),
    lastLoginDt: yup.date().nullable(),
  })
  .required();

export type User = yup.InferType<typeof userSchema>;

export const userInputSchema = userSchema
  .shape({
    id: yup.string().nullable(),
  })
  .omit(["createDt", "lastLoginDt"]);

export type UserInput = yup.InferType<typeof userInputSchema>;

export const userLabels: Labels<User> = {
  id: "UUID",
  email: "Email",
  name: "Name",
  roleIds: "Role IDs",
  createDt: "Creation Date",
  lastLoginDt: "Last Login Date",
};

export const userSchemaList = yup.array().of(userSchema).required();

export type UserList = yup.InferType<typeof userSchemaList>;

export const PERMISSIONS = [
  "manage_tokens",
  "add_edit_remove_roles",
  "add_edit_users",
  "remove_users",
  "manage_email_automation",
  "update_user_profile",
  "view_public_users",
  "manage_authorization",
  "add_search_field_boosts",
  "request_email_reports",
  // Comment perms:
  "view_public_comments",
  "view_unpublished_comments",
  "add_comments",
  "edit_comments",
  "delete_comments",
  "manage_comment_subscriptions",
  "view_comment_emails",
  "certify_comments",
  // Birth record perms:
  "view_public_birth_records",
  "view_unpublished_birth_records",
  "view_confidential_birth_records",
  "add_edit_publish_remove_birth_records",
  "bulk_update_birth_records",
  "view_birth_record_journal",
  "edit_birth_record_search_field_boosts",
  // Death record perms:
  "view_public_death_records",
  "view_unpublished_death_records",
  "view_confidential_death_records",
  "add_edit_publish_remove_death_records",
  "bulk_update_death_records",
  "view_death_record_journal",
  "edit_death_record_search_field_boosts",
  // Gold Star Roll Perms:
  "view_gold_star_roll_records",
  "add_edit_remove_gold_star_roll_records",
  "bulk_update_gold_star_roll_records",
  "view_gold_star_roll_record_journal",
  "edit_gold_star_roll_search_field_boosts",
  // Veteran's Graves Perms:
  "view_vets_grave_records",
  "add_edit_remove_vets_grave_records",
  "bulk_update_vets_grave_records",
  "view_vets_graves_record_journal",
  "edit_vets_graves_record_search_field_boosts",
  // Census Perms:
  "view_public_census_records",
  "add_edit_remove_census_records",
  "bulk_update_census_records",
  "view_census_record_journal",
  "edit_census_record_search_field_boosts",
  // Collections Perms:
  "view_public_collections_items",
  "view_public_collections_media",
  "view_unpublished_collections_media",
  "edit_remove_collections_items",
  "edit_collections_item_search_field_boosts",
  // Finding Aid perms:
  "add_edit_publish_remove_find_aids",
] as const;

export type PermissionId = (typeof PERMISSIONS)[number];

export const permissionSchema = yup
  .object({
    id: yup
      .string()
      .oneOf([...PERMISSIONS])
      .required(),
    name: yup.string().required(),
  })
  .required();

export type Permission = yup.InferType<typeof permissionSchema>;

export const permissionSchemaList = yup.array().of(permissionSchema).required();

export type PermissionList = yup.InferType<typeof permissionSchemaList>;

/**
 * Schema for the currently authenticated user.
 */
export const currentUserDataSchema = yup
  .object({
    user: userSchema.required(),
    accessToken: yup.string().required(),
    permissions: yup.array(permissionSchema).required(),
    authority: yup.array(yup.string()).required(),
    prefs: userPrefsSchema,
    emailVerified: yup.boolean().required(),
  })
  .required();

export type CurrentUserData = yup.InferType<typeof currentUserDataSchema>;

export type MediaObject = {
  content: Blob;
  mimeType: string;
};

export const roleSchema = yup
  .object({
    id: yup.string().required(),
    name: yup.string().required(),
    permissionIds: yup.array(yup.string()).required().default([]),
    subRoleIds: yup.array(yup.string()).required().default([]),
  })
  .required();

export type Role = yup.InferType<typeof roleSchema>;

export const roleInputSchema = roleSchema.shape({
  id: yup.string().nullable(),
  name: yup.string().nullable(),
});

export type RoleInput = yup.InferType<typeof roleInputSchema>;

export const roleLabels: Labels<Role> = {
  id: "UUID",
  name: "Name",
  permissionIds: "Permissions",
  subRoleIds: "Subordinate Roles",
};

export const roleSchemaList = yup.array().of(roleSchema).required();

export type RoleList = yup.InferType<typeof roleSchemaList>;

export type EmailRecipient = {
  id: string;
  purpose: string;
  recipients: (Role | User)[];
};

export const emailRecipientInputSchema = yup.object({
  id: yup.string().nullable(),
  purpose: yup.string().required(),
  recipients: yup.array().of(yup.string()).nullable(),
});

export type EmailRecipientInput = yup.InferType<
  typeof emailRecipientInputSchema
>;

// Difference in annotation for recipients between EmailRecipient and
// EmailRecipientInput means typescript prefers the labels to use the input type
// rather than the base schema.
export const emailRecipientLabels: Labels<EmailRecipientInput> = {
  id: "UUID",
  purpose: "Purpose",
  recipients: "Recipients",
};

// Querying and results schemas and constants:
export const SimpleOperators = ["=", ">", "<", ">=", "<="] as const;
export type SimpleOperator = (typeof SimpleOperators)[number];
export const Conjunctions = ["and", "or", "not"] as const;
export type Conjunction = (typeof Conjunctions)[number];
export const SortDirections = ["asc", "desc"] as const;
export type SortDir = (typeof SortDirections)[number];

export type Sort = {
  field: string;
  direction: SortDir;
};

export type Filter = {
  op: SimpleOperator;
  field: string;
  term: any;
};

export type FilterConjunct = {
  conjunction: Conjunction;
  filters: (Filter | FilterConjunct)[];
};

export type Query = {
  sorts: Sort[];
  filters: Filter[];
};

export const pagedResultsSchema = yup.object({
  results: yup.array().required(),
  totalCount: yup.number().integer().required(),
  pageNum: yup.number().integer().required(),
  pageSize: yup.number().integer().required(),
  pageCount: yup.number().integer().required(),
  hasPrevious: yup.bool().required(),
  hasNext: yup.bool().required(),
});

export type PagedResults<T> = {
  results: T[];
  totalCount: number;
  pageNum: number;
  pageSize: number;
  pageCount: number;
  hasPrevious: boolean;
  hasNext: boolean;
};

// Record schemas:
export const stringElementSchema = yup.object({
  value: yup.string().required(),
});

export type StringElement = yup.InferType<typeof stringElementSchema>;

export const searchResultSchema = yup.object({
  hits: yup.array().required(),
  totalHitCount: yup.number().integer().required(),
  pageNum: yup.number().integer().required(),
  pageSize: yup.number().integer().required(),
  pageCount: yup.number().integer().required(),
  hasPrevious: yup.bool().required(),
  hasNext: yup.bool().required(),
  nextPageToken: yup.string().nullable(),
});

export type SearchResult<T> = {
  hits: T[];
  totalHitCount: number;
  pageNum: number;
  pageSize: number;
  pageCount: number;
  hasPrevious: boolean;
  hasNext: boolean;
  nextPageToken?: string | null;
};

export const EVENTTYPES = ["Created", "Updated", "Deleted"] as const;

export type EventType = (typeof EVENTTYPES)[number];

export const EVENTRECORDTYPES = [
  "BirthRecord",
  "CensusRecord",
  "CensusPage",
  "DeathRecord",
  "GoldStarRoll",
  "VetsGraveRecord",
];

export type EventRecordType = (typeof EVENTRECORDTYPES)[number];

export const eventSchema = yup.object({
  originatorID: yup.string().required(),
  originatorVersion: yup.number().required(),
  originatingUser: userSchema,
  eventType: yup.string().oneOf(EVENTTYPES).required(),
  recordType: yup.string().oneOf(EVENTRECORDTYPES).required(),
  timestamp: yup.date().required(),
});

const eventStateField = yup.object({
  key: yup.string().required(),
  value: yup.mixed().nullable(),
});

export type EventStateField = yup.InferType<typeof eventStateField>;

export const naiveEventSchema = eventSchema.shape({
  state: yup.array().of(eventStateField).required(),
});

export const naiveEventSchemaList = yup.array().of(naiveEventSchema).required();

export type Event<T> = {
  originatorID: string;
  originatorVersion: number;
  originatingUser: User;
  eventType: EventType;
  recordType: EventRecordType;
  timestamp: Date;
  state: T;
};

export const bulkUpdateChange = yup.object({
  id: yup.string().required(),
  version: yup.number().required(),
});

export const BULK_UPDATE_DATASETS = ["birthrecords", "deathrecords"] as const;

export const bulkUpdateEntry = yup.object({
  id: yup.string().required(),
  changes: yup.array().of(bulkUpdateChange).required(),
  dataset: yup.string().oneOf([...BULK_UPDATE_DATASETS]),
  originatingUser: userSchema,
  createDt: yup.date().required(),
  revertDt: yup.date().nullable(),
  note: yup.string().nullable(),
});

export type BulkUpdateEntry = yup.InferType<typeof bulkUpdateEntry>;

export const bulkUpdateEntryList = yup.array().of(bulkUpdateEntry).required();

export type BulkUpdateEntryList = yup.InferType<typeof bulkUpdateEntryList>;

export type BulkUpdateEntryInput<T> = {
  note?: string;
  records: T[];
};

export const CONFIDENTIALITY = [
  "confidential",
  "unpublished",
  "public",
] as const;

export type ImgResRule<T extends AnyFilterLike> = {
  res?: number;
  criteria: T[];
};

export type ImgResRuleset<T extends AnyFilterLike> = {
  id: string;
  version: number;
  source: string;
  resRules: ImgResRule<T>[];
};

export type ImgResRulesetRecord<T extends AnyFilterLike> = ImgResRuleset<T> & {
  resRules: Record<string, ImgResRule<T>>;
};

export type ImgResRulesetInput = {
  resRules: ImgResRule<FilterLike>[];
};

/**
 * Generates a client-friendly Record from an ImgResRules Array, making it easy
 * to use in react state and in mapping into components and such.
 * @param resRules ImgResRules Array returned by the API (with SearchFilters and/or
 * SearchFilterConjuncts in its resRules property).
 * @param filterFields SearchFilterFields configuration to use when parsing the
 * SearchFilters and generating SearchFilterParms.
 * @returns A Record with uuid keys and each ImgResRules object in resRules as their
 * values, with SearchFilterParam and SearchFilterConjunctParam objects.
 */
export function genRecordFromResRulesArray(
  resRules: ImgResRule<SearchFilter | SearchFilterConjunct>[],
  filterFields: SearchFilterFields,
): Record<string, ImgResRule<SearchFilterParam | SearchFilterConjunctParam>> {
  return resRules.reduce(
    (prev, apiRule) => ({
      ...prev,
      [uuid4()]: {
        res: apiRule.res,
        criteria: apiRule.criteria.map((f) =>
          parseSearchFilter(f, filterFields),
        ),
      },
    }),
    {},
  );
}

export function parseSearchFilterParamToFilter(
  param: SearchFilterParam,
): Filter {
  // This translation is hopefully only temporary until I overhaul and refactor
  // the api query system to be standardized regardless of the underlying DB/Search
  // Engine
  const searchFilter = parseSearchFilterParam(param);
  const searchOp = searchFilter.op;
  const opMap: Record<SearchFilterOp, SimpleOperator | undefined> = {
    "=": "=",
    ">": ">",
    ">=": ">=",
    "<": "<",
    "<=": "<=",
    "!=": undefined,
    $: undefined,
    "*": undefined,
  };
  const op: SimpleOperator | undefined = opMap[searchOp];

  if (!op) {
    throw Error(`Invalid operator = ${searchOp}`);
  }
  return {
    op: op,
    field: searchFilter.field,
    term: searchFilter.term,
  };
}

export function parseSearchParamsToFilters(
  params?: (SearchFilterParam | SearchFilterConjunctParam)[],
): Filter[] {
  const cleanParams = params
    ? params.map((f) => {
        if ("conjunction" in f) {
          console.warn("Cannot currently parse SearchFilterConjunctParams");
          return null;
        } else {
          return parseSearchFilterParamToFilter(f);
        }
      })
    : [];
  return removeNulls(cleanParams);
}
