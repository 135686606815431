import { Button, Popover, ButtonGroup } from "@shopify/polaris";
import { XSmallIcon } from "@shopify/polaris-icons";
import { useState } from "react";
import { Subheading } from "../../shared/TextComponents";
import {
  SearchFilterParam,
  SearchFilterConjunctParam,
  SearchFilterFields,
  FilterConjunct,
} from "./searchutils";
import * as utils from "../utils/shared";
import FilterParamPopover from "./FilterParamPopover";
import { v4 as uuid } from "uuid";
import Stack from "../../shared/Stack";

interface SearchParamChitProps {
  displayText: string;
  onRemove: () => void;
  onClick?: () => void;
  disclosure?: "up" | "down";
  disabled?: boolean;
  removeDisabled?: boolean;
}

export default function SearchParamChit({
  displayText,
  onRemove,
  onClick,
  disclosure,
  disabled,
  removeDisabled,
}: SearchParamChitProps) {
  return (
    <Stack direction="row" spacing={1}>
      <Button
        size="slim"
        disclosure={disclosure}
        onClick={onClick}
        disabled={disabled}
      >
        {displayText}
      </Button>
      <Button
        icon={XSmallIcon}
        size="slim"
        onClick={onRemove}
        disabled={removeDisabled}
        tone="critical"
      />
    </Stack>
  );
}

interface FilterParamChitProps {
  filterFields: SearchFilterFields;
  onRemove: () => void;
  filter: SearchFilterParam | SearchFilterConjunctParam;
  disabled?: boolean;
  onChange?: (f: SearchFilterParam | SearchFilterConjunctParam) => void;
}

export function FilterParamChit(props: FilterParamChitProps) {
  const [filter, setFilter] = useState(props.filter);
  const [editOpen, setEditOpen] = useState(false);
  const [addOpen, setAddOpen] = useState(false);

  const onChange = props.onChange
    ? props.onChange
    : (f: SearchFilterParam | SearchFilterConjunctParam) => {};

  const buildFilterText = (
    f: SearchFilterParam | SearchFilterConjunctParam
  ): string => {
    if ("filters" in f) {
      let filterText = buildFilterText(f.filters[0]);
      return `${f.conjunction.toUpperCase()} (${filterText} ...)`;
    } else {
      return f.label;
    }
  };

  const displayText = buildFilterText(filter);
  const subfilterChits =
    "filters" in filter &&
    filter.filters.map((f, idx) => (
      <FilterParamChit
        key={f.uuid}
        filterFields={props.filterFields}
        onRemove={() => removeSubfilter(idx)}
        filter={f}
        disabled={true}
      />
    ));

  const chit = (
    <SearchParamChit
      displayText={displayText}
      onRemove={props.onRemove}
      onClick={() => setEditOpen(true)}
      disabled={props.disabled}
    />
  );

  const removeSubfilter = (idx: number) => {
    if ("filters" in filter) {
      let newfilters = utils.removeItem(filter.filters, idx);
      let updatedFilter = { ...filter, filters: newfilters };
      setFilter(updatedFilter);
      onChange(updatedFilter);
    }
  };

  const updateConjunction = (conj: FilterConjunct) => {
    if ("conjunction" in filter) {
      let updatedFilter = { ...filter, conjunction: conj };
      setFilter(updatedFilter);
      onChange(updatedFilter);
    }
  };

  const conjunctPopoverSection = "conjunction" in filter && (
    <Popover.Section>
      <Stack>
        <ButtonGroup>
          <Button
            pressed={filter.conjunction === "and"}
            size="slim"
            onClick={() => updateConjunction("and")}
          >
            AND
          </Button>
          <Button
            size="slim"
            pressed={filter.conjunction === "or"}
            onClick={() => updateConjunction("or")}
          >
            OR
          </Button>
          <Button
            size="slim"
            pressed={filter.conjunction === "not"}
            onClick={() => updateConjunction("not")}
          >
            NOT
          </Button>
        </ButtonGroup>
        <Subheading>Filters</Subheading>
        {subfilterChits}
      </Stack>
    </Popover.Section>
  );

  const addSubfilter = (f?: SearchFilterParam | SearchFilterConjunctParam) => {
    if (f) {
      let updatedFilter = { ...filter };
      if ("filters" in filter) {
        let newfilters = [...filter.filters, f];
        updatedFilter = { ...filter, filters: newfilters };
      } else {
        updatedFilter = {
          uuid: uuid(),
          conjunction: "or",
          filters: [filter, f],
        };
      }
      setFilter(updatedFilter);
      onChange(updatedFilter);
    }
    setEditOpen(true);
  };

  const editPopover = (
    <Popover
      sectioned
      active={editOpen}
      activator={chit}
      preferredAlignment="left"
      onClose={() => setEditOpen(false)}
      fullHeight
    >
      {conjunctPopoverSection}
      <Popover.Section>
        <Button
          onClick={() => {
            setEditOpen(false);
            setAddOpen(true);
          }}
        >
          Add Subfilter
        </Button>
      </Popover.Section>
    </Popover>
  );

  const addPopover = (
    <FilterParamPopover
      activator={chit}
      active={addOpen}
      filterFields={props.filterFields}
      onSubmit={addSubfilter}
      onClose={() => {
        setAddOpen(false);
        setEditOpen(true);
      }}
    />
  );

  return editOpen ? editPopover : addPopover;
}
